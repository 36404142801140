import moment from "moment";
import { makeAbsoluteLinks } from "../api";

const momentize = (dateTime) => {
  if (dateTime) {
    return moment(dateTime);
  }
  return undefined;
};

const formatBookingDetails = (bookingDetails) => {
  if (!bookingDetails) {
    return undefined;
  }
  const { start, end } = bookingDetails;
  return { ...bookingDetails, start: momentize(start), end: momentize(end) };
};

const formatCoachCall = (linksContext, response) => response.json()
  .then(body => ({
    ...body,
    bookingDetails: formatBookingDetails(body.bookingDetails),
    links: makeAbsoluteLinks(body, linksContext.endpointUrl),
  }));

export const fetchCoachCall = (linksContext, coachCallId) => {
  const coachCallUrl = linksContext.links.coachCall.href.replace("{?id}", coachCallId);
  return fetch(coachCallUrl, { credentials: "include" })
    .then((response) => {
      if (!response.ok) {
        // TODO: Graceful error handling for user
        throw Error(response.statusText);
      }
      return response;
    })
    .then(response => formatCoachCall(linksContext, response));
};

export const bookCoachCall = (linksContext, coachCall, bookingDetails) => fetch(coachCall.links.bookingDetails.href, {
  method: "POST",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({
    bookingDetails: {
      start: bookingDetails.start.format(),
      end: bookingDetails.end.format(),
    },
  }),
}).then(response => formatCoachCall(linksContext, response));
