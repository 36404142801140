import React from 'react';
import styled from 'styled-components';
import numberConverter from 'number-to-words';
import pluralize from 'pluralize';
import { Container, Box } from '../../styled';
import { MatrixResult } from './matrixHelpers';
import FullscreenHero, { FullscreenHeroActivityNav } from '../../FullscreenHero';
import ResultsFooter from './ResultsFooter';

const titleCase = string => string.charAt(0).toUpperCase() + string.slice(1);

const Header = styled.h3`
  &&& {
    margin-bottom: 1.5rem
  }
`;

const SkillPill = styled.div`
  border-radius: 0.333rem;
  min-width: 3.8rem;
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 0px auto;
  padding: 0px 6px;
  display: inline-block;
  white-space: nowrap;
  color: #2D313F;
  text-align: center;
}
`;

const Matrix = styled.table.attrs(_props => ({ className: `has-text-light` }))`
  width: 100%;
  font-size: 0.8rem;
  margin-top: 0.8rem;

  thead {
    border-bottom: 2px solid #979797;
  }

  tr {
    height: 2.5rem;
  }

  td {
    padding-top: 0.8rem;
  }

  th {
    font-weight: normal;
    padding-top: 1rem;
    color: #717071;
  }

  .skill-name {
    width: 0%;
    font-weight: bold;
  }

  .skill-value, .enjoyment-level {
    width: 25%;
    text-align: center;
  }

  .skill-value-header, .enjoyment-level-header {
    text-align: center;
  }
`;

export const MatrixExerciseResults = ({
  exerciseData, restart, closeExercise,
}) => {
  const skillTextMapping = {
    great: "great",
    good: "good",
    "get-by": "get by",
  };

  const enjoymentTextMapping = {
    "like-it": "like",
    "love-it": "love",
    "live-with-it": "live with",
  };

  const skillColorMapping = {
    great: "indicator-green",
    good: "indicator-yellow",
    "get-by": "indicator-red",
  };

  const enjoymentColorMapping = {
    "love-it": "indicator-green",
    "like-it": "indicator-yellow",
    "live-with-it": "indicator-red",
  };

  const MatrixBox = styled(Box)`
    &&& {
      margin-bottom: 1.0rem;
      border: 0.5px solid rgba(0,0,0,0.1);
    }
    &:last-child {
      margin-bottom: 200px;
    }
  `;

  const ResultContainer = styled.div`
    margin-bottom: 3rem;
  `;

  const pillForSkillLevel = skill => (
    <SkillPill className={`has-background-${skillColorMapping[skill]}`}>
      {skillTextMapping[skill]}
    </SkillPill>
  );

  const pillForEnjoymentLevel = enjoyment => (
    <SkillPill className={`has-background-${enjoymentColorMapping[enjoyment]}`}>
      {enjoymentTextMapping[enjoyment]}
    </SkillPill>
  );

  const pluralizedSkills = skills => pluralize("skill", skills.length);

  const ResultBox = ({ skilled, enjoyment }) => {
    const skillSet = new MatrixResult(exerciseData).skillsFitting(skilled, enjoyment);

    return (skillSet.length > 0)
      && (
      <MatrixBox>
        <div className="body-bold has-text-light">
          {titleCase(numberConverter.toWords(skillSet.length))}
          {" "}
          {pluralizedSkills(skillSet)}
          {' '}
          I'm
          {' '}
          <span className={`has-text-${skillColorMapping[skilled]}`}>{skillTextMapping[skilled]}</span>
          {' '}
          at and
          {' '}
          <span className={`has-text-${enjoymentColorMapping[enjoyment]}`}>{enjoymentTextMapping[enjoyment]}</span>
          {' '}
          doing
        </div>
        <Matrix>
          <thead>
            <tr>
              <th className="skill-name-header">Activity</th>
              <th className="skill-value-header">Skill level</th>
              <th className="enjoyment-level-header">Joy factor</th>
            </tr>
          </thead>
          <tbody>
            {skillSet.map(skill => (
              <tr key={skill.questionText}>
                <td className="skill-name">
                  {skill.questionText}
                </td>
                <td className="skill-value">
                  {pillForSkillLevel(skill.value.skilled)}
                </td>
                <td className="enjoyment-level">
                  {pillForEnjoymentLevel(skill.value.enjoy)}
                </td>
              </tr>
            ))}
          </tbody>
        </Matrix>
      </MatrixBox>
      );
  };

  return (

    <FullscreenHero
      showCloseButton
      headerRender={props => (
        <FullscreenHeroActivityNav {...props} backToDashboardAction={closeExercise} lightHeader />
      )}

      footerRender={_props => <ResultsFooter restart={restart} close={closeExercise} />}
    >

      <Container>
        <Header>
            Your Matrix
        </Header>

        <ResultContainer>
          <ResultBox skilled="great" enjoyment="love-it" />
          <ResultBox skilled="great" enjoyment="like-it" />
          <ResultBox skilled="great" enjoyment="live-with-it" />

          <ResultBox skilled="good" enjoyment="love-it" />
          <ResultBox skilled="good" enjoyment="like-it" />
          <ResultBox skilled="good" enjoyment="live-with-it" />

          <ResultBox skilled="get-by" enjoyment="love-it" />
          <ResultBox skilled="get-by" enjoyment="like-it" />
          <ResultBox skilled="get-by" enjoyment="live-with-it" />
        </ResultContainer>

      </Container>

    </FullscreenHero>
  );
};
