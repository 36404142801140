import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, MainAvatarImage, Columns, Column,
} from "./styled";


const CoachCard = ({ coach }) => {
  const { ImageUrl, Name, Biography } = coach;
  return (
    <Card className="coach-card">
      <CardContent>
        <Columns isMobile className="is-vcentered is-2 is-variable">
          <Column className="is-narrow">
            <MainAvatarImage src={ImageUrl} alt={`Avatar for coach ${Name}`} size="64x64" />
          </Column>
          <Column>
            <span className="body-bold is-paddingless">{Name}</span>
          </Column>
        </Columns>
        <p>{Biography}</p>
      </CardContent>
    </Card>
  );
};

CoachCard.propTypes = {
  coach: PropTypes.shape({
    ImageUrl: PropTypes.string,
    Name: PropTypes.string,
    Biography: PropTypes.string,
  }).isRequired,
};


export default CoachCard;
