import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'fetch-hoc';
import {
  ColumnHeroLowButtons, MeetYourCoachHero, PrimaryButton, PrimaryHeading4, Main,
} from './styled';
import CoachCard from './CoachCard';
import { API_URL } from '../env';

const Footer = ({ onMatchingProcessFinished }) => (
  <ColumnHeroLowButtons>
    <PrimaryButton onClick={onMatchingProcessFinished}>Get Started</PrimaryButton>
  </ColumnHeroLowButtons>
);

Footer.propTypes = {
  onMatchingProcessFinished: PropTypes.func.isRequired,
};


export const MeetYourCoachPanel = ({ data, onMatchingProcessFinished }) => (
  <Main>
    <MeetYourCoachHero>
      <PrimaryHeading4>Meet your coach</PrimaryHeading4>
      {data && <CoachCard coach={{ ...data, ImageUrl: data.ImageUrl }} />}
      <Footer onMatchingProcessFinished={onMatchingProcessFinished} />
    </MeetYourCoachHero>
  </Main>
);

MeetYourCoachPanel.propTypes = {
  imageBaseUrl: PropTypes.string,
  onMatchingProcessFinished: PropTypes.func.isRequired,
  data: PropTypes.shape({
    ImageUrl: PropTypes.string.isRequired,
  }),
};
MeetYourCoachPanel.defaultProps = {
  data: undefined,
};

// TODO: Convert to our new fetch hook from fetch-hoc
export default fetch(`${API_URL}/coaches/my-coach`,
  { credentials: "include" })(MeetYourCoachPanel);
