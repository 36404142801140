import React from 'react';


import {
  PlusIcon, Level, LevelItem, LevelLeft, Button,
} from "./styled";

export default ({ onClick, caption, dataTestId }) => (
  <Level isMobile tag="div">
    <LevelLeft>
      <LevelItem>
        <Button data-testid={dataTestId} isOutlined onClick={onClick} noHover className="card-shadow">
          <PlusIcon alt={caption} />
        </Button>
      </LevelItem>
      <LevelItem tag="a" className="has-text-grey-dark" onClick={onClick}>{caption}</LevelItem>
    </LevelLeft>
  </Level>
);
