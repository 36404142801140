import React from 'react';
import timekitSdk from 'timekit-sdk';

const fetchBookingDetails = (id) => {
  const sdk = timekitSdk.newInstance();
  sdk.configure({
    appKey: process.env.REACT_APP_TIMEKIT_APP_KEY,
    headers: { "Timekit-Context": "widget" },
  });
  return sdk.getBooking({ id }).then(response => response.data);
};
const initBookingWidget = (overridingConfig) => {
  const timekitBooking = new window.TimekitBooking();
  const config = {
    app_key: process.env.REACT_APP_TIMEKIT_APP_KEY,
  };
  timekitBooking.init({ ...config, ...overridingConfig });
};

export const TimekitClient = { initBookingWidget, fetchBookingDetails };
export const TimekitClientContext = React.createContext(TimekitClient);
