import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBackground, Card, CardContent, CardHeader, CardFooter, CardFooterItem, CardHeaderTitle,
} from './styled';

const ModalLayout = ({
  isActive, children, footerRender, title, style,
}) => (
  <Modal isActive={isActive}>
    <ModalBackground />
    <Card>
      <CardHeader>
        <CardHeaderTitle tag="span" className="body-bold">{title}</CardHeaderTitle>
      </CardHeader>
      <CardContent className="has-text-body-grey">
        {children}
      </CardContent>

      { footerRender && (
      <CardFooter>
        {React.Children.map(React.Children.only(footerRender).props.children, item => <CardFooterItem tag="div" key={item.key}>{item}</CardFooterItem>)}
      </CardFooter>
      )}
    </Card>
  </Modal>
);
ModalLayout.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
  footerRender: PropTypes.node,
  title: PropTypes.node,
};

ModalLayout.defaultProps = {
  isActive: true,
  children: undefined,
  footerRender: undefined,
  title: undefined,
};

export default ModalLayout;
