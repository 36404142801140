import React from 'react';
import styled from 'styled-components';
import { compact } from 'lodash';
import { Image } from 'bloomer';
import { ResultField } from './fields/ResultField';
import { Container } from '../../styled';
import WheelAdvicePresenter from '../../../contentful/presenters/RatingAdvicePresenter';
import FullscreenHero, { FullscreenHeroActivityNav } from '../../FullscreenHero';
import ResultsFooter from './ResultsFooter';

const SimpleResult = styled.div`
  &&& {
    margin-bottom: 3rem
  }
`;

const ResultFieldContainer = styled.div`
  &&& {
    margin-bottom: 1rem
  }
`;

const SimpleHeader = styled.h3`
  &&& {
    margin-bottom: 1.5rem
    text-align: center;
  }
`;

const LineBetween = styled.div`
  & > * {
    margin-bottom: 1rem
  }
`;

const advice = advices => advices && new WheelAdvicePresenter(advices);
const advicesForQuestion = question => question.fields.questions.fields.questions.find(field => field.sys.contentType.sys.id === "ratingQuestion").fields.advices;

const filterQuestionsByType = (questions, questionType, matchTrue = true) => questions.filter(
  (question) => {
    const matches = question.sys.contentType.sys.id === questionType;
    return matchTrue ? matches : !matches;
  },
);

const WheelQuestionResult = ({ question, exerciseData, exerciseType }) => {
  const subquestions = question.fields.questions.fields.questions;
  const subquestion = filterQuestionsByType(subquestions, 'ratingQuestion')[0];
  if (subquestion) {
    const otherQuestions = filterQuestionsByType(subquestions, 'ratingQuestion', false);

    const fieldQuestion = {
      ...subquestion,
      colorHexCode: question.fields.colorHexCode,
      advice: advice(advicesForQuestion(question))
    };
    const moreParts = compact(otherQuestions.map(otherQuestion => <ResultField
      key={otherQuestion.sys.id} question={otherQuestion} submissionData={exerciseData}/>));
    const more = moreParts.length > 0 && <LineBetween>{moreParts}</LineBetween>;

    return (
      <ResultFieldContainer>
        <ResultField
          question={fieldQuestion}
          submissionData={exerciseData}
          exerciseType={exerciseType}
          display={{ card: true, more }}
        />
      </ResultFieldContainer>
    );
  } else {
    return (
        <SimpleResult key={question.sys.id}>
          <h5 className="has-text-primary">
            {question.fields.title}
          </h5>
          {
            subquestions.map((subquestion, index) => (
              <SimpleResult key={subquestion.sys.id}>
                <label class="label">{subquestion.fields.questionText}</label>
                <div>
                  {}
                  {(exerciseData[subquestion.sys.id] || {}).questionType === 'multipleChoice' ? (exerciseData[subquestion.sys.id] || {}).selectedText : (exerciseData[subquestion.sys.id] || {}).value}
                </div>
              </SimpleResult>
            ))
          }
        </SimpleResult>
    );
  }
};

export const WheelExerciseResults = ({
  exerciseData, restart, closeExercise, exerciseConfig,
}) => {
  const exercisesInOrder = compact(exerciseConfig.fields.questions);
  const exerciseType = exerciseConfig.sys.contentType.sys.id;
  const hasAnswers = exerciseData && Object.keys(exerciseData).length > 1;

  return (
    <FullscreenHero
      showCloseButton
      headerRender={props => (
        <FullscreenHeroActivityNav {...props} backToDashboardAction={closeExercise} lightHeader />
      )}

      footerRender={_props => <ResultsFooter restart={restart} close={closeExercise} />}
    >

      <Container>
        <Image src="images/wheel_placeholder.svg" className="is-16by9" />
        <SimpleHeader>
          Your
          {' '}
          {exerciseConfig.fields.title.replace(/^The /i, "")}
        </SimpleHeader>
        {
          exercisesInOrder.map(question => (
            <WheelQuestionResult key={question.sys.id} question={question} exerciseData={exerciseData} exerciseType={exerciseType} />
          ))
        }
        {
          !hasAnswers && (
            <center>You have not answered any questions.</center>
          )
        }
      </Container>
    </FullscreenHero>
  );
};
