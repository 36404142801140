import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Level, LevelLeft, LevelRight, LevelItem,
} from './styled';


const DirectionalButtonBar = ({ children }) => {
  const childArray = React.Children.toArray(children);
  const forwardButton = childArray[0];
  const backButton = childArray[1];

  return (
    <Container>
      <Level className="is-mobile">
        <LevelLeft>
          <LevelItem>
            {backButton}
          </LevelItem>

        </LevelLeft>
        <LevelRight>
          <LevelItem>
            {forwardButton}
          </LevelItem>

        </LevelRight>
      </Level>
    </Container>
  );
};

DirectionalButtonBar.propTypes = {
  children: PropTypes.node,
};

export default DirectionalButtonBar;
