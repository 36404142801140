import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Activity from './Activity';
import AdhocCoachCallButton from './AdhocCoachCallButton';
import Accordion from './Accordion';
import { Loader, Section } from './styled';
import { createClient } from '../contentful/client';
import { fetchByIds } from '../contentful/activity';

const groupActivitiesByModule = (activityData) =>
  _.groupBy(activityData, (activity) => activity.moduleId);

const LearningModule = styled.div`
  &&& {
    margin-bottom: 3.25rem;
  }
`;

const ActivityList = styled.div`
  &&& {
    padding: 1.75rem 0 3.25em;
  }
`;

// Had to separate this out because fetch-hoc and hooks don't work well together
const BasicActivityBoard = ({
  activityData,
  moduleData,
  showCallBooking,
  profileState,
  currentModules,
  coach,
  viewerRole = 'participant',
}) => {
  const [currentContentfulModules, setCurrentContentfulModules] = useState();

  useEffect(() => {
    const getContentfulModules = async () => {
      const client = createClient();
      const ContentfulModules = await fetchByIds(client, currentModules, {
        include: 1,
      });
      setCurrentContentfulModules(
        _.reduce(
          ContentfulModules,
          (accu, module) => {
            accu[module.sys.id] = module.fields.exercises
              ? module.fields.exercises.map((e) => e.sys.id)
              : [];
            return accu;
          },
          {}
        )
      );
    };
    getContentfulModules();
  }, [currentModules]);
  const activitiesByModule = groupActivitiesByModule(activityData);
  if (currentContentfulModules) {
    const programAndModules = _.reduce(
      moduleData,
      (accu, module) => {
        if (profileState.result.enabledPrograms.includes(module.programId)) {
          if (currentModules.includes(module.id)) {
            accu[module.programId] = {
              programName: module.programName,
              current: true,
              modules: [
                ...(accu[module.programId]
                  ? accu[module.programId].modules
                  : []),
                {
                  id: module.id,
                  title: module.title,
                  calendlyId: module.calendlyId,
                  activities: _.filter(
                    activityData,
                    (a) =>
                      a.moduleId == module.id &&
                      currentContentfulModules[module.id] &&
                      (currentContentfulModules[module.id].includes(
                        a.ExerciseId
                      ) ||
                        a.status === 'Completed' ||
                        a.type === 'CoachCall')
                  ),
                },
              ],
            };
          }
        } else if (
          (activitiesByModule[module.id] || []).some(
            (activity) => activity.status === 'Completed'
          )
        ) {
          accu[module.programId] = {
            programName: module.programName,
            current: false,
            modules: [
              ...(accu[module.programId] ? accu[module.programId].modules : []),
              {
                id: module.id,
                title: module.title,
                calendlyId: module.calendlyId,
                activities: activitiesByModule[module.id],
              },
            ],
          };
        }
        return accu;
      },
      {}
    );

    const programs = Object.keys(programAndModules).map((programId) => ({
      program: {
        id: programAndModules[programId].programId,
        name: programAndModules[programId].programName,
      },
      modules: _.sortBy(programAndModules[programId].modules, module => profileState.result.moduleOrder.indexOf(module.id)).reverse(),
      current: programAndModules[programId].current,
    }));

    return programs.map(({ program, modules, current }) => (
      <Accordion key={program.id} title={program.name} active={current}>
        <ActivityList>
          {modules.map((module) => (
            <LearningModule key={module.id}>
              <h4>{module.title}</h4>
              {_.sortBy(
                module.activities,
                (activity) => activity.sequenceInModule
              ).map((activity) => (
                <Activity
                  title={activity.title}
                  key={activity.id}
                  viewerRole={viewerRole}
                  coach={coach}
                  profile={profileState.result.userProfile}
                  programId={program.id}
                  moduleCalendlyId={module.calendlyId}
                  {...activity}
                />
              ))}
              {showCallBooking &&
                module.id === profileState.result.currentModuleId &&
                !profileState.result.archivedAt &&
                coach && (
                  <Section>
                    <AdhocCoachCallButton
                      coach={coach}
                      profile={profileState.result.userProfile}
                    />
                  </Section>
                )}
            </LearningModule>
          ))}
        </ActivityList>
      </Accordion>
    ));
  }
  return <Loader />;
};

BasicActivityBoard.defaultProps = {
  showCallBooking: true,
};

export default BasicActivityBoard;
