import React from 'react';
import { withRouter } from "react-router-dom";
import { Introduction } from './Introduction';
import FullscreenHero, { FullscreenHeroActivityNav, FullscreenHeroActivityFooterControls } from '../FullscreenHero';
import { PrimaryButton } from '../styled';
import { sendExerciseData } from '../../api';

export const ReadOnlyExercise = ({
  exerciseConfig,
  activityId,
  history,
  exerciseSubmission,
}) => {
  const { complete } = exerciseSubmission;

  const closeExercise = () => {
    new Promise((_resolve, _reject) => { history.push(`/`); })
      .catch((error) => { console.log("ERROR", error); });
  };

  const finish = async () => {
    console.log('waiting');
    await sendExerciseData(activityId, { complete: true });
    console.log('finished');
    closeExercise();
  };

  return (
    <FullscreenHero
      showCloseButton
      headerRender={props => (
        <FullscreenHeroActivityNav {...props} backToDashboardAction={closeExercise} lightHeader />
      )}

      footerRender={_props => (
        <FullscreenHeroActivityFooterControls directional>
          <PrimaryButton onClick={finish}>{ complete ? 'Close' : 'Complete'}</PrimaryButton>
        </FullscreenHeroActivityFooterControls>
      )}
    >
      <form>
        <div data-history="slideintro">
          <Introduction
            title={exerciseConfig.fields.title}
            subtitle={exerciseConfig.fields.subtitle}
            text={exerciseConfig.fields.introduction.split("\n").join("\n\n")}
            completionMinutes={null}
            closeExercise={closeExercise}
            image={exerciseConfig.fields.bannerImage && exerciseConfig.fields.bannerImage.fields.file.url}
            downloads={exerciseConfig.fields.downloads}
          />
        </div>
      </form>
    </FullscreenHero>
  );
};

export default withRouter(ReadOnlyExercise);
