import { makeAbsoluteLinks, objToQueryString } from "../api";

const formatEmailLog = (linksContext, emailLog) => ({
  ...emailLog,
  links: makeAbsoluteLinks(emailLog, linksContext.endpointUrl),
});

export const fetchEmailLogs = (linksContext, params={}) => {
  const queryString = objToQueryString(params);
  return fetch(`${linksContext.links.emailLogs.href}?${queryString}`, { credentials: "include" })
    .then(response => response.json())
    .then(response => ({
      links: makeAbsoluteLinks(response, linksContext.endpointUrl),
      items: response.emailLogs.map(emailLog => formatEmailLog(linksContext, emailLog)),
    }));
};


export const createEmailLog = (linksContext, attributes) => fetch(linksContext.links.emailLogs.href, {
  method: "POST",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({ emailLog: {
      subject: attributes.subject,
      content: attributes.content,
      ParticipantId: attributes.ParticipantId,
      sendAt: attributes.sendAt ? new Date(attributes.sendAt).toISOString() : '',
    }
  }),
}).then(response => response.json()).then(result => formatEmailLog(linksContext, result.emailLog));
