import React from 'react';
import PhoneInput from 'react-phone-input-2';

export const PhoneNumberInput = ({
  id,
  value,
  placeholder,
  classes,
  callback,
}) => (
  <PhoneInput
    id={id}
    placeholder={placeholder}
    inputClass={classes}
    value={value}
    onChange={callback}
  />
);
