import React, { useContext, useEffect, useState } from 'react';
import {
  HashRouter as Router, Route, Switch,
} from "react-router-dom";
import * as Sentry from '@sentry/browser';
import WelcomePanel from './components/WelcomePanel';
import AdminDashboard from './components/adminDashboard';
import CoachDashboard from './components/coachDashboard';
import HomeSwitcher from './components/HomeSwitcher';
import PreLogin from './components/PreLogin';
import CoachCall from './components/CoachCall';
import LinksContext, { Provider as LinksContextProvider } from './LinksContext';
import Toaster from './components/Toaster';
import Loader from './components/Loader';
import NotFound from './components/NotFound';
import Exercise from './components/exercises/Exercise';
import { API_URL } from './env';


const AdminRoutes = () => (
  <Route path="/" component={AdminDashboard} />

);

const CoachRoutes = () => (
  <Route path="/" component={CoachDashboard} />
);

const ParticipantRoutes = () => (
  <>
    <Route path="/" exact render={() => (<HomeSwitcher />)} />
    <Route path="/welcome" exact render={() => (<WelcomePanel />)} />
    <Route path="/exercise/:id" render={() => (<Exercise />)} />
    <Route path="/coachcall/:id?" render={props => (<CoachCall {...props} />)} />
  </>
);

const DefaultRoutes = () => <Route path="/" component={PreLogin} />;

const RenderWithTestError = () => {
  Sentry.captureException(new Error("Test error from the front end"));
  return <div>test error page</div>;
};

const userTypeToRoutes = {
  admin: AdminRoutes,
  participant: ParticipantRoutes,
  coach: CoachRoutes,
};

const AppRoutes = () => {
  const { links, userType } = useContext(LinksContext);
  const UserSpecificRoutes = userTypeToRoutes[userType] || DefaultRoutes;

  if (!links) {
    return <Loader />;
  }
  return (
    <Router>
      <Switch>
        <Route path="/testing/error" component={RenderWithTestError} />
        <UserSpecificRoutes />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

function App() {
  const [appEnvLoaded, setAppEnvLoaded] = useState(window.appEnvironment);

  const [retries, setRetries] = useState(0);
  useEffect(() => {
    if (appEnvLoaded) return;
    if (retries < 3) {
      const timeout = setTimeout(() => {
        if (window.appEnvironment) {
          setAppEnvLoaded(true);
        } else {
          setRetries(retries + 1);
        }
      }, 500);
      return () => { clearTimeout(timeout); };
    }
    throw new Error("App environment has not been loaded");
  }, [retries]);

  if (!appEnvLoaded) {
    return <div />;
  }
  return (
    <LinksContextProvider linksEndpointUrl={API_URL}>
      <Toaster>
        <AppRoutes />
      </Toaster>
    </LinksContextProvider>
  );
}
export default App;
