import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Button, Section, Hero, HeroHeader, HeroBody,
} from 'bloomer'; // eslint-disable-line import/no-duplicates
import * as bloomer from 'bloomer'; // eslint-disable-line import/no-duplicates
import BrainLoader from '../animations/BrainLoader';

import './App.scss';
import CloseIconImage from './close.svg';
import CalendarIconImage from './calendar.svg';
import VideoIconImage from './video.svg';
import PlusIconImage from './plus.svg';
import AttachmentIconImage from './attachment.svg';
import UpArrowIconImage from './arrow-up-solid.svg';
import DownArrowIconImage from './arrow-down-solid.svg';

export const Main = styled.main`
  padding-top: 2.5rem;
`;

export {
  Box, Section, Hero, Level, LevelLeft,
  LevelRight, LevelItem, HeroFooter, HeroHeader, Card, CardContent, CardHeader, CardFooter, CardFooterItem, CardHeaderTitle, Checkbox, Delete, Notification, Button, Field, Label, Control, Input, Select, Columns, Column, Menu, MenuList, TextArea,
} from 'bloomer';

export const Container = styled((props) => {
  const { verticalPadding, isWider, ...rest } = props;
  return (<bloomer.Container {...rest} />);
})`
${props => !props.verticalPadding && `&&& {padding-bottom: 0; padding-top:0}`}
${props => props.isWider && `&&& {max-width:1024px; width: 93.75%}`}
`;

Container.defaultProps = {
  verticalPadding: true,
  isWider: false,
};

const ColourSection = styled(Section).attrs(props => (
  { className: `has-background-${props.colour}` }))`
padding: 2em 0;
`;

ColourSection.defaultProps = {
  colour: "highlight-green",
};

export const ColumnHeroBody = styled.div.attrs({ className: 'hero-body narrower-container' })`
flex-direction:column;
margin: 0 auto;
> h3,>h4,>h5 {
text-align:center
}

> * {
margin: ${props => props.internalPadding / 2}em 0;
}

margin-top: ${props => props.topPadding - (props.internalPadding / 2)}em;
`;

ColumnHeroBody.defaultProps = {
  internalPadding: 2,
  topPadding: 1,
};

export const ColumnHeroLowButtons = styled.div.attrs({ className: "buttons is-centered" })`
  flex:1;
  max-height:10em;
  padding-bottom:1em;

`;

const WaitScreenImage = styled.figure.attrs({ className: "image" })`
  width: 10rem;
  margin:auto;
  height: auto;
`;

export const WaitScreen = ({ children }) => (
  <div>
    <WaitScreenImage>
      <BrainLoader />
    </WaitScreenImage>
    <Container className="narrower-container" style={{ maxWidth: '22.5rem', padding: '1em' }}>
      {children}
    </Container>
  </div>
);


export const MeetYourCoachHero = ({ children }) => (
  <Hero isFullHeight>
    <HeroHeader />
    <ColumnHeroBody>
      {children}
    </ColumnHeroBody>
  </Hero>
);


export const Table = styled(bloomer.Table)
  .attrs(() => ({ className: "has-text-centered is-striped is-fullwidth is-bordered" }))`
border-collapse: collapse;
border-spacing: 0;
`;

export const TableCell = styled.td`
   vertical-align: middle !important;
   text-align: center !important;
`;

export const PrimaryButton = styled(Button).attrs({
  className: "is-primary",
})``;

export const PrimaryTextButton = styled((props) => {
  const { paddingless, noHover, borderless, compact, ...rest } = props;
  return (<Button {...rest} />);
})
  .attrs(props => ({
    className: `is-${props.colour} is-${props.size} is-inverted`,
  }))`
&&&{
  ${props => props.paddingless && "padding:0;"}
  ${props => props.noHover && `background:white !important;`}
  ${props => props.borderless && "border:0;"}
  ${props => props.compact && "height:1.2rem"}
}
`;

PrimaryTextButton.defaultProps = {
  colour: "primary",
  size: "normal",
  paddingless: false,
  noHover: false,
  borderless: false,
};

const UnstyledButton = styled.button.attrs({ className: "button" })`
&& {
border:0;
background-color: transparent;
}
`;

const Icon = ({
  src, alt, className, width, height,
}) => (
  <span className={`icon ${className}`}>
    <img src={src} alt={alt} className="image" width={width} height={height} />
  </span>
);

Icon.defaultProps = {
  className: "",
};

export const CalendarIcon = () => <Icon src={CalendarIconImage} alt="Calendar" width="20" height="21" />;
export const VideoIcon = () => <Icon src={VideoIconImage} alt="Video" width="22" height="22" />;
export const PlusIcon = ({ alt, onClick }) => <Icon src={PlusIconImage} alt={alt} onClick={onClick} width="18" height="18" />;
export const DownloadIcon = ({ alt, onClick }) => <Icon src={AttachmentIconImage} alt={alt} onClick={onClick} width="18" height="18" />;
export const CloseIcon = ({ alt, onClick }) => <Icon src={CloseIconImage} alt={alt} onClick={onClick} width="18" height="18" />;
export const UpArrowIcon = ({ alt, onClick }) => <Icon src={UpArrowIconImage} alt={alt} onClick={onClick} width="18" height="18" />;
export const DownArrowIcon = ({ alt, onClick }) => <Icon src={DownArrowIconImage} alt={alt} onClick={onClick} width="18" height="18" />;

export const CloseButton = props => (
  <UnstyledButton {...props}>
    <Icon src={CloseIconImage} alt="Close button" />
  </UnstyledButton>
);

export const TableHeader = styled.th.attrs({ className: `has-background-peeplecoach-green` })`
   vertical-align: middle !important;
   text-align: center !important;
`;

export const TableActionHeader = styled(TableHeader)`
   width: 5em;
`;

export const TableButton = Button;

export const Content = Container;

export const LabelText = styled.div`
  &&& {
    margin-bottom: 1.2rem;
  }
  &::-webkit-input-placeholder {
    margin-bottom: 0.8rem;
  }
`;

export const SmallTextArea = styled(bloomer.TextArea).attrs({ className: "is-h5-size has-text-body-grey" })`t
&&& {padding:0}`;

export const SmallInput = styled(bloomer.Input).attrs({ className: "is-h5-size" })``;
export const SmallSelect = styled(bloomer.Select).attrs({ className: "is-h5-size" })``;

export const PrimaryHeading4 = styled.h4.attrs({ className: "has-text-primary" })``;

export const MainImageInner = styled.div.attrs({ className: "has-ratio image is-16by9" })`
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat:no-repeat;
  background-origin: padding-box;
  background-size:contain;
  background-clip: padding-box;
`;

export const MainImage = ({ src }) => (
  <Container role="presentation" className="narrower-container">
    <MainImageInner src={src} />
  </Container>
);

MainImage.defaultProps = {

};

const AvatarImageFigure = styled.figure.attrs(({ className }) => ({ className: `image ${className}` }))`
padding: 0.5px;
border-radius: 290486px;

`;


export const MainAvatarImage = ({
  src, size, alt,
}) => {
  if (src) {
    return (
      <AvatarImageFigure className={`is-${size} has-background-lightish-grey`}>
        <img alt={alt} src={src} className="is-rounded" />
      </AvatarImageFigure>
    );
  }
  return (<AvatarImageFigure className={`is-${size}`} />);
};

MainAvatarImage.defaultProps = {
  alt: "Avatar",
  size: "88x88",
};

export const FullscreenBody = styled(props => <HeroBody {...props} />)`
&&& {
  padding:0;
  align-items:stretch !important;
  justify-content: flex-start;
  flex-direction: column;
}

main {
  flex:1;
  padding-top: 1em;
  padding-bottom: 0em;

 .container {
    padding-top: 0;
    padding-bottom:2em
  }
}
`;

export const HeroNav = styled.nav.attrs({ role: "navigation", ariaLabel: "navigation", className: "navbar is-mobile" })`
&&& {
min-height:3.5em

}
`;

export const DashboardMain = styled(Main)`

      padding-bottom: 0;
 .container {
      padding-top: 0;
      padding-bottom:0;
  }
  }
`;


export const FixedHeaderBodyGlobal = createGlobalStyle`
body {
  padding-top: 3.5em !important;
}
`

export const FixedFooterBodyGlobal = createGlobalStyle`
body {
padding-bottom: 3.5em !important;
}
`;

export const FixedHeaderNav = styled.nav.attrs({ role: "navigation", ariaLabel: "navigation", className: "navbar is-mobile is-fixed-top  is-paddingless is-h5-size" })``;

// Fixes image so we can include alt text on the image (bloomer does not support this)
export const Image = ({
  alt, src, isRatio, isSize, rounded,
}) => {
  const ratioClass = isRatio && `is-${isRatio}`;
  const sizeClass = isSize && `is-${isSize}`;
  const shapeClass = rounded && `is-rounded`;
  return (
    <figure className={`${ratioClass || ""} ${sizeClass || ""}  ${shapeClass || ""} image`}>
      {src && <img src={src} alt={alt} />}
    </figure>
  );
};


export const Loader = styled.div.attrs({ className: "loader" })`
margin:0 auto;
`;
// TODO: Merge these colours with the other colours in app.scss
export const DashboardGlobal = createGlobalStyle`
body {
    background-size: cover;
    background-color: #fafafa;
 }
`;

export {
  Modal,
  ModalClose,
  ModalBackground,
  ModalCardBody,
  ModalCardTitle,
  ModalCardHeader,
} from 'bloomer';

export const ModalCardFooter = styled(bloomer.ModalCardFooter)`
&&&{
justify-content: space-between;

}
`;

export const ModalCard = styled(bloomer.ModalCard)`
&&&{
width: 300px;

}
`;

export const ToastContainer = styled.div`
  width:100%;
z-index:99999;
position:fixed;
pointer-events:none;
display:flex;
flex-direction:column;
padding:15px;
bottom:0;left:0;right:0;text-align:center;align-items:center;

`;

export const ToastNotification = styled(bloomer.Notification)`
&&& {
align-items:center;
display:flex;
padding:1.25rem;
span{
flex:1
}
.delete {
position:inherit;
top:0;
right:0;
margin-left:1rem
}

}
`;

export const UpgradeNotification = ({ widerLayout }) => (
  <Container isWider={widerLayout} style={{ padding: 0 }}>
    <bloomer.Notification isColor="primary" className="is-h5-size">
      Platform upgrade notification. On Tuesday 13th of June the platform will be
      upgraded to improve performance and features. We apologise for any inconvenience
      during this time
    </bloomer.Notification>
  </Container>
)
