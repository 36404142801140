import React, { useReducer, useEffect, useContext } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import {
  Container,
} from '../styled';
import DashboardLayout from '../DashboardLayout';
import BasicActivityBoard from "../BasicActivityBoard";


import Loader from "../Loader";
import { fetchModules, requestPasswordChange } from "../../api";
import { fetchMyCoach } from "../../api/coaches";
import { activityFeed } from "../../api/activities";
import LinksContext from "../../LinksContext";
import ToastContext from "../../ToastContext";
import useApiFetch from "../../hooks/useApiFetch";

const activitiesReducer = (state, action) => {
  switch (action.type) {
    case 'flagAsLoading':
      return { isLoading: true, items: null };
    case 'setActivities':
      return { isLoading: false, items: action.payload };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

const AdminNav = withRouter(({ linksContext }) => {
  const toast = useContext(ToastContext);
  return (
    <>
      <NavLink activeClassName="is-active" className="navbar-item" to="" onClick={() => { requestPasswordChange(linksContext); toast.push({ content: "Password change request sent. You should receive an email shortly." }); }}>Request Change Password</NavLink>
    </>
  );
});

const ParticipantDashboard = ({ profileState }) => {
  const linksContext = useContext(LinksContext);
  const [{ isLoading, items }, activitiesDispatch] = useReducer(activitiesReducer, {});
  const modules = useApiFetch(fetchModules);
  const coach = useApiFetch(fetchMyCoach);

  useEffect(() => {
    let cancel;
    activitiesDispatch({ type: 'flagAsLoading' });
    activityFeed().then((activityData) => {
      if (!cancel) {
        activitiesDispatch({ type: 'setActivities', payload: activityData });
      }
    });
    return () => {
      cancel = true;
    };
  }, []);
  const currentModules = profileState.result.moduleOrder.slice(0, profileState.result.moduleOrder.indexOf(profileState.result.currentModuleId) + 1);

  return (
    <DashboardLayout additionalNavbarItems={() => <AdminNav linksContext={linksContext} />}>
      <Container>
        <div>
          {(!isLoading && items && modules.items) && (<BasicActivityBoard activityData={items} moduleData={modules.items} currentModules={currentModules} profileState={profileState} coach={coach.result} />)}
          {isLoading && (<Loader />) }
        </div>
      </Container>

    </DashboardLayout>
  );
};
export default ParticipantDashboard;
