import React from 'react';
import styled from 'styled-components';
import { Label } from 'bloomer';
import { LabelText } from '../../../styled';
import QuestionPresenter from '../../../../contentful/presenters/QuestionPresenter';
import RatingResultField from './WheelResultField';
import WheelAdvicePresenter from '../../../../contentful/presenters/RatingAdvicePresenter';

const AnswerList = styled.ul`
  > li {
    border-bottom: 1px solid #DDD;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    &:last-child {
      border-bottom-width: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

const Answer = styled.div`
  white-space: pre-wrap
`;

function SimpleResultField({ presenter, submissionData, question }) {
  return (
    <div>
      <Label>
        <LabelText>
          {presenter.title(presenter, submissionData, question)}
        </LabelText>
      </Label>
      <div>{presenter.responseFor(submissionData[question.sys.id])}</div>
    </div>
  );
}

function DuplicatedResultField({ presenter, submissionData, question }) {
  return (
    <div>
      <Label>
        <LabelText>
          {presenter.title(presenter, submissionData, question)}
        </LabelText>
      </Label>
      <AnswerList>
        {presenter.responseFor(submissionData[question.sys.id]).map(answer => (
          <li key={answer.uuid}><Answer>{answer.value}</Answer></li>
        ))}
      </AnswerList>
    </div>
  );
}

export const ResultField = ({ submissionData, question, exerciseType, display = {} }) => {
  const presenter = new QuestionPresenter(question, submissionData);

  if (exerciseType === "scoringExercise") {
    return <RatingResultField presenter={presenter} submissionData={submissionData} question={question} display={display} />;
  }

  if (presenter.contentType() === "userDuplicatedGroup") {
    return <DuplicatedResultField presenter={presenter} submissionData={submissionData} question={question} display={display} />;
  }

  if (presenter.contentType() === "ratingQuestion") {
    const advice = new WheelAdvicePresenter(question.fields.advices);
    const fieldQuestion = { ...question, advice };
    return <RatingResultField presenter={presenter} submissionData={submissionData} question={fieldQuestion} display={display} />;
  }

  return (
    <SimpleResultField presenter={presenter} submissionData={submissionData} question={question} display={display} />
  );
};
