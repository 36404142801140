import React, {
  useContext, useCallback, useReducer, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route } from "react-router-dom";

import Introduction from './Introduction';
import BookingGrid from './BookingGrid';
import ToastContext from '../../ToastContext';
import LinksContext from '../../LinksContext';
import Loader from '../Loader';
import { fetchCoachCall, bookCoachCall } from '../../api/coachCalls';
import { cancelCoachCall, fetchModules, fetchProfile } from '../../api';
import BookingPrompt from './BookingPrompt';
import { openPopupWidget } from "react-calendly";
import useApiFetch from "../../hooks/useApiFetch";

const coachCallReducer = (state, action) => {
  switch (action.type) {
    case 'flagAsLoading':
      return { isLoading: true, coachCall: null };
    case 'setResponse':
      return { isLoading: false, coachCall: action.payload };
    case 'clearResponse':
      return { isLoading: false, coachCall: null };

    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

const CoachCallActivity = ({
  history, match,
}) => {
  const linksContext = useContext(LinksContext);
  const toast = useContext(ToastContext);
  const [coachCallState, dispatch] = useReducer(coachCallReducer, {});
  const { isLoading, coachCall } = coachCallState;
  const profile = useApiFetch(fetchProfile);
  const modules = useApiFetch(fetchModules);

  const fromDashboard = !(match.params.id === 'initial');
  useEffect(() => {
    if (linksContext.links && !coachCallState.coachCall && !coachCallState.isLoading) {
      dispatch({ type: "flagAsLoading" });
      fetchCoachCall(linksContext, match.params.id).then((innerCoachCall) => {
        dispatch({ type: "setResponse", payload: innerCoachCall });
      });
    }
  }, [linksContext, match.params.id, coachCallState]);

  const bookingMade = useCallback(async (bookingDetails) => {
    const hasBookingAlready = !!coachCallState.coachCall.bookingDetails;
    const innerCoachCall = await bookCoachCall(linksContext, coachCallState.coachCall, bookingDetails);
    dispatch({ type: "setResponse", payload: innerCoachCall });
    if (hasBookingAlready) {
      toast.push({ content: "Your appointment has been rescheduled" });
    } else {
      toast.push({ content: "Your appointment has been booked" });
    }
    history.push(`${match.url}`);
  }, [coachCallState, match.url, toast, history, linksContext]);

  const bookingCancelled = useCallback(async () => {
    await cancelCoachCall(coachCall);
    // TODO: Update booking details via reducer from post return
    dispatch({ type: "clearResponse" });
    toast.push({ content: "Your appointment has been cancelled" });
    history.push("/");
  }, [coachCall, history, toast]);

  if (!isLoading && coachCall && profile.result && modules.items) {
    let reschedule;
    let cancel;
    if (coachCall.coach.calendlyId) {
      if (coachCall.bookingDetails) {
        if (coachCall.bookingDetails.invitee && coachCall.bookingDetails.invitee.uuid) {
          reschedule = () => {
            window.location.replace(
              `https://calendly.com/reschedulings/${coachCall.bookingDetails.invitee.uuid}`
            );
          };
          cancel = () => {
            window.location.replace(
              `https://calendly.com/cancellations/${coachCall.bookingDetails.invitee.uuid}`
            );
          };
        }
      } else {
        if (coachCall.CoachCallContentId === 'adhoc') {
          reschedule = () => { openPopupWidget({ url: `https://calendly.com/${coachCall.coach.calendlyId}/15min?email=${profile.result.userProfile.emails[0].value}&name=${profile.result.userProfile.displayName}` }); };
        } else {
          const module = modules.items.find((module) => module.id === coachCall.ModuleId);
          const calendlyUuid = module.calendlyId;
          reschedule = () => { openPopupWidget({ url: `https://calendly.com/${coachCall.coach.calendlyId}/${calendlyUuid}?email=${profile.result.userProfile.emails[0].value}&name=${profile.result.userProfile.displayName}` }); };
        }
        cancel = () => { history.push(match.url); };
      }
    } else {
      reschedule = () => { history.push(`${match.url}/choose-a-time`); };
      cancel = () => { history.push(`${match.url}/cancel`); };
    }
    return (
      <>
        <Switch>
          <Route
            exact
            path={`${match.path}/choose-a-time`}
            render={() => (
              <BookingGrid
                coachCall={coachCall}
                bookingMade={bookingMade}
                fromDashboard={fromDashboard}
              />
            )}
          />

          <Route
            path={`${match.path}/`}
            render={() => (
              <Introduction
                coachCall={coachCall}
                fromDashboard={fromDashboard}
                bookNowClicked={() => reschedule() }
                cancelBookingClicked={() => cancel() }
              />
            )}
          />
        </Switch>
        <Route
          exact
          path={`${match.path}/cancel`}
          render={() => (
            <BookingPrompt.Cancel
              coachCall={coachCall}
              fromDashboard={fromDashboard}
              confirmCancelClicked={bookingCancelled}
              goBackClicked={() => { history.push(match.url); }}
            />
          )}
        />
      </>
    );
  }
  return (<Loader />);
};

CoachCallActivity.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
};

export default withRouter(CoachCallActivity);
