import { makeAbsoluteLinks, objToQueryString } from "../api";
import { formatActivities } from "./activities";

const formatParticipant = (linksContext, participant) => ({
  ...participant,
  activities: (participant.activities && formatActivities(participant.activities)),
  links: makeAbsoluteLinks(participant, linksContext.endpointUrl),
});

export const progressParticipant = (linksContext, participant) => fetch(participant.links.self.href, {
  method: "PUT",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({ currentModuleId: participant.moduleOrder[participant.moduleOrder.indexOf(participant.currentModuleId) + 1] }),
})
  .then(response => response.json())
  .then(ptpt => formatParticipant(linksContext, ptpt));

export const fetchParticipants = (linksContext, params = {}) => {
  const queryString = objToQueryString(params);

  let results = fetch(`${linksContext.links.participants.href}?${queryString}`, { credentials: "include" });
  return results.then(response => response.json()).then(response => ({
    links: makeAbsoluteLinks(response, linksContext.endpointUrl),
    items: response.participants.map(coach => formatParticipant(linksContext, coach)),
  }));
};

export const fetchSingleParticipant = (linksContext, id) => fetch(
  `${linksContext.links.participants.href}/${id}`, { credentials: "include" },
)
  .then(response => response.json())
  .then(response => ({
    links: makeAbsoluteLinks(response, linksContext.endpointUrl),
    ...formatParticipant(linksContext, response.participant),
  }));

export const createAndInviteParticipant = (linksContext, participantAttributes) => fetch(linksContext.links.participants.href, {
  method: 'POST',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: "include",
  body: JSON.stringify(participantAttributes),
}).then(response => response.json()).then(participant => formatParticipant(linksContext, participant));

export const updateParticipant = (linksContext, participant) => fetch(participant.links.self.href, {
  method: "PUT",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({
    organisationId: participant.organisationId,
    coachProfileId: participant.coachProfileId,
    currentModuleNumber: participant.currentModuleNumber,
    currentModuleId: participant.currentModuleId,
    enabledPrograms: participant.enabledPrograms,
    moduleOrder: participant.moduleOrder,
    archivedAt: participant.archivedAt,
    email: participant.emailAddress,
    name: participant.name,
    phone: participant.phone,
  }),
})
  .then(response => response.json())
  .then(innerParticipant => formatParticipant(linksContext, innerParticipant));
