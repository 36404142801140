import React from 'react';
import PropTypes from 'prop-types';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import {
  PrimaryTextButton,
} from '../styled';


const buildOWAEventUrl = ({
  startDatetime, endDatetime, title, description, location,
}) => `https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent&startdt=${startDatetime}&enddt=${endDatetime}&subject=${title}&location=${location}&body=${encodeURIComponent(description)}`; // eslint-disable-line max-len

const ToggleButton = props => (
  <div className="dropdown-trigger">
    <PrimaryTextButton {...props} paddingless noHover compact>save to your calendar</PrimaryTextButton>
  </div>
);

const CalendarProviderButtons = ({ children, event, onRequestClose }) => (
  <div className="dropdown-menu" onBlur={onRequestClose}>
    <div className="dropdown-content">
      {React.Children.map(children, child => (
        <div className="dropdown-item">
          {child}
        </div>
      ))
                                                          }
      <div className="dropdown-item">
        <a
          href={buildOWAEventUrl(event)}
          rel="noopener noreferrer"
          className="has-text-primary"
          target="_blank"
        >
Outlook (web)
        </a>
      </div>
    </div>
  </div>
);

CalendarProviderButtons.propTypes = {
  children: PropTypes.node.isRequired,
  event: PropTypes.shape({}).isRequired,
};

const AddToCalendar = AddToCalendarHOC(ToggleButton,
  CalendarProviderButtons);

const buildDescription = location => `View and manage details here: ${location}`;

const SaveToCalendar = ({ coachCall }) => {
  const { bookingDetails, contentFields } = coachCall;
  const { start, end } = bookingDetails;
  if (!start && !end) {
    return <></>;
  }
  const event = {
    description: buildDescription(window.location),
    endDatetime: end.format('YYYYMMDDTHHmmss'),
    startDatetime: start.format('YYYYMMDDTHHmmss'),
    duration: end.diff(start, "hours").toString(),
    title: contentFields.title,
    location: (bookingDetails.event ? bookingDetails.event.location : `https://zoom.us/j/${coachCall.coach.ChatId}`),
  };
  return (

    <AddToCalendar
      className="dropdown is-active is-up"
      event={event}
      items={["Google", "iCal", "Outlook"]}
      linkProps={{ className: "has-text-primary" }}
      dropdownProps={{ event }}
    />
  );
};

// TODO: Dedupe these proptype definitions
SaveToCalendar.propTypes = {
  coachCall: PropTypes.shape({
    contentFields: PropTypes.shape({
      title: PropTypes.string,
    }),
    bookingDetails: PropTypes.shape({
      actionLinks: PropTypes.shape({}),
      start: PropTypes.object,
      end: PropTypes.object,
    }),
  }).isRequired,
};


export default SaveToCalendar;
