/* eslint-disable react/prop-types */

import React from 'react';
import { Label } from 'bloomer';
import styled from 'styled-components';


const RatingButton = styled(Label)`
  &&& {
    cursor: pointer;
    font-size: 0.75rem;

    input {
      display: none;
    }

    div {
      line-height: 1.4rem;
      border: 0.5px solid rgba(0,0,0,0.1);
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      border-radius: 100%;
      font-size: 10pt;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    }

    input:checked + div {
      background-color: #A5D6A7;
      border: 0.5px solid #66BB6A;
      color: white;
      box-shadow: none;
    }
  }
`;

const RatingButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Rating = ({
  values, onRatingSelected, currentValue, id,
}) => (
  <RatingButtonGroup>
    {
      values.map(value => (
        <RatingButton key={`${id}_${value}`}>
          <input type="radio" name={id} defaultChecked={value === parseInt(currentValue) ? "checked" : ""} onChange={onRatingSelected} defaultValue={value} />
          <div>{value}</div>
        </RatingButton>
      ))
    }
  </RatingButtonGroup>
);

Rating.defaultProps = {
  currentValue: undefined,
};
export default Rating;
