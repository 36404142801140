import React from 'react';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import {
  fetchCoaches, createAndInviteCoach, updateCoach,
} from '../../../api/coaches';
import useAdminDataManager from '../../../hooks/useAdminDataManager';
import {
  Section,
} from "../../../components/styled";
import { CoachDialog, AddCoachButton, CoachList } from "../../../components/pages/coaches"


export default () => {
  const { manage, state } = useAdminDataManager(fetchCoaches, createAndInviteCoach, updateCoach, {archived: true});
  const {
    cancelEdit, startEdit, startCreate, saveData,
  } = manage;

  const { currentMode, items, targetItem } = state;
  const renderByMode = {
    loading: () => <Loader />,
    listing: () => (
      <>
        <Section>
          <CoachList coaches={_.orderBy(items, org => (org.name || "").toUpperCase())} onEdit={startEdit} isAdmin={true} />
        </Section>
      </>
    ),
  };
  return (
    <>
      <h2> Archived Coaches </h2>
      {renderByMode[currentMode]()}
    </>
  );
};
