import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import useScript from '../../hooks/useScript';
import {
  Container,
} from '../styled';
import { TimekitClientContext } from '../../timekit/client';
import FullscreenHero, { FullscreenHeroActivityNav } from '../FullscreenHero';
import BookingPrompt from "./BookingPrompt";


const TimekitBookingGrid = ({ onTimeslotClicked, resourceId, projectId }) => {
  const timekitClientContext = useContext(TimekitClientContext);
  const bookingRootElement = useRef();
  const [loadedJquery] = useScript("scripts/jquery-3.4.1.min.js");
  const [loadedBookingJs] = useScript("scripts/booking.js", { when: loadedJquery });

  useEffect(() => {
    if (loadedJquery && loadedBookingJs) {
      timekitClientContext.initBookingWidget({
        project_id: projectId,
        resources: [resourceId],
        availability: {
          from: "now",
          to: "26 weeks",
          buffer: "15 minutes",
        },
        el: bookingRootElement.current,
        disable_confirm_page: true,
        fullcalendar: {
          timeFormat: 'h(:mm)a',
          views: {
            listing: {
              listDayFormat: "dddd\nMMM Do",
              listDayAltFormat: false,
            },
          },
          contentHeight: 'auto',
        },
        ui: {
          show_credits: false,
          show_timezone_helper: false,
          display_name: false,
          time_date_format: false,
        },
        callbacks: {
          clickTimeslot: onTimeslotClicked,
        },
      });
    }
  }, [loadedJquery, loadedBookingJs, onTimeslotClicked, projectId, resourceId, timekitClientContext]);

  return (
    <div
      ref={bookingRootElement}
      data-testid="bookingGrid"
      className="coachcall-booking"
    />

  );
};

TimekitBookingGrid.propTypes = {
  onTimeslotClicked: PropTypes.func,
  resourceId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

TimekitBookingGrid.defaultProps = {
  onTimeslotClicked: () => {
  },
};

const BookingGrid = ({
                       coachCall, bookingMade, fromDashboard,
                     }) => {
  const [selectedSlot, setSelectedSlot] = useState(null);
  const hasExistingBooking = coachCall.bookingDetails != null;
  if (!selectedSlot) {
    return (

      <FullscreenHero
        className="has-background-background-grey"
        headerClassName="has-background-background-grey"
        showCloseButton={fromDashboard}
        stickyFooter
        headerRender={props => (
          <FullscreenHeroActivityNav
            className="has-background-background-grey"
            {...props}
          >
            <div className="navbar-item body-bold">
              {hasExistingBooking ? "Reschedule your appointment" : "Choose a date and time"}
            </div>

          </FullscreenHeroActivityNav>
        )}
      >
        <Container>

          <TimekitBookingGrid
            onTimeslotClicked={setSelectedSlot}
            projectId={coachCall.contentFields.timekitProjectId}
            resourceId={coachCall.coach.BookingResourceId}
          />
        </Container>
      </FullscreenHero>
    );
  }

  return (
    <BookingPrompt.Confirm
      coachCall={coachCall}
      selectedSlot={selectedSlot}
      fromDashboard={fromDashboard}
      goBackClicked={() => setSelectedSlot(undefined)}
      confirmClicked={() => bookingMade(selectedSlot)}
    />
  );
};

BookingGrid.propTypes = {
  coachCall: PropTypes.shape({ contentFields: PropTypes.shape({ timekitProjectId: PropTypes.string }) }).isRequired,
  bookingMade: PropTypes.func.isRequired,
  fromDashboard: PropTypes.bool,
};

BookingGrid.defaultProps = {
  fromDashboard: false,
};
export default BookingGrid;
