import { makeAbsoluteLinks, objToQueryString } from "../api";
import { API_URL } from "../env";

const formatCoach = (linksContext, coach) => ({
  ...coach,
  links: makeAbsoluteLinks(coach, linksContext.endpointUrl),
});

export const fetchCoaches = (linksContext, params={}) => {
  const queryString = objToQueryString(params);
  return fetch(`${linksContext.links.coaches.href}?${queryString}`, { credentials: "include" })
  .then(response => response.json())
  .then(response => ({
    links: makeAbsoluteLinks(response, linksContext.endpointUrl),
    items: response.coaches.map(coach => formatCoach(linksContext, coach)),
  }));
};

export const fetchMyCoach = (linksContext, params={}) => {
  return fetch(`${API_URL}/coaches/my-coach`, { credentials: "include" })
    .then(response => response.json())
};

export const createAndInviteCoach = (linksContext, coachAttributes) => fetch(linksContext.links.coaches.href, {
  method: 'POST',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: "include",
  body: JSON.stringify({ coach: coachAttributes }),
}).then(response => response.json()).then(({ coach }) => formatCoach(linksContext, coach));

export const updateCoach = (linksContext, coach) => fetch(coach.links.self.href, {
  method: "PUT",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({ coach }),
})
  .then(response => response.json())
  .then(json => formatCoach(linksContext, json.coach));

export const deleteCoach = (linksContext, coach) => fetch(coach.links.modify.href, {
  method: "DELETE",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
}).then(response => response.json()).then(result => formatCoach(linksContext, result.coach));
