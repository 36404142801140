// eslint-disable-next-line import/prefer-default-export
export const fetchByIds = async (client, ids, options) => (await client.getEntries(Object.assign({ 'sys.id[in]': ids.join(","), limit: ids.length }, (options || {})))).items;

// eslint-disable-next-line import/prefer-default-export
export const fetchById = async (client, id) => client.getEntry(id);

export const fetchExerciseById = async (client, id) => {
  const ids = await fetchByIds(client, [id], { include: 10 });
  return ids[0];
};
