import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Label } from 'bloomer';
import { newMultiCheckboxState } from './fieldHelpers';

const WhittlingButton = styled(Label)`
  &&& {
    cursor: pointer;
    background-color: white;
    border-radius: 0.5rem;
    height: 4.5rem;
    width: 10rem;
    font-size: 0.75rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1);

    input {
      display: none;
    }
    display: flex;
    color: black;

    input:checked + div {
      background-color: #64B864;
      color: white;
      box-shadow: none;
      border-radius: 0.5rem;
    }
  }
`;

const WhittlingText = styled.div`

  display: inline-table;
  height: max-content;
  align-self: center;
  width: 85%;
  text-align: center;
  margin: 0px auto;
`;

const WhittlingTextWrapper = styled.div`
  display: flex;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const WhittlingButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 32rem;
  margin: 0px auto;
`;

const WhittlingOption = ({
  contentfulData,
  clickItem,
  name,
  isChecked,
}) => (
  <WhittlingButton>
    <input type="checkbox" onClick={clickItem} name={name} id={contentfulData.sys.id} defaultChecked={isChecked} />
    <WhittlingTextWrapper>
      <WhittlingText>
        {contentfulData.fields.questionText}
      </WhittlingText>
    </WhittlingTextWrapper>
  </WhittlingButton>
);

const calculateNumberOfSelected = currentValue => Object.values(currentValue).filter(value => value === true).length;

const valuesForThisField = (currentValues, fieldId) => ((currentValues && currentValues[fieldId] && currentValues[fieldId].value) ? currentValues[fieldId].value : {});

const filterByAvailableOptions = (currentValue, options) => {
  const optionIds = options.map(option => option.sys.id);
  const result = {};
  _.forEach(currentValue, (value, key) => {
    if (optionIds.includes(key)) result[key] = value;
  });
  return result;
};

export const WhittlingField = ({ data, updateState, currentValues, submitted }) => {
  const { fields, sys } = data;
  const currentValue = filterByAvailableOptions(valuesForThisField(currentValues, sys.id), fields.options);
  const noOfSelected = calculateNumberOfSelected(currentValue);
  const limit = fields.noOfOptionsToBeSelected;

  const canSelectAnother = () => noOfSelected < limit;

  const clickItem = (event) => {
    if (canSelectAnother() || !event.target.checked) {
      currentValue[event.target.id] = event.target.checked;
      newMultiCheckboxState(data, updateState, currentValue);
    } else {
      event.preventDefault();
    }
  };

  return (
    <div>
      <WhittlingButtonContainer>
        <p>
          {fields.questionText}
          {fields.required ? '*' : ''}
        </p>
        <span className='help is-danger'>{(data.fields.required && submitted && currentValue === "") ? 'You must select an answer' : ''}</span>
        <h3>
          { noOfSelected }
          {' '}
          selected
        </h3>
      </WhittlingButtonContainer>
      <WhittlingButtonContainer>
        {fields.options.map(option => <WhittlingOption key={option.sys.id} contentfulData={option} clickItem={clickItem} name={sys.id} isChecked={currentValue[option.sys.id]} />)}
      </WhittlingButtonContainer>
    </div>
  );
};
