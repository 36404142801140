import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from './styled';


const ButtonBar = ({ children }) => (
  <Container>
    {React.Children.map(children, child => (<div key={child.key} className="buttons is-centered">{child}</div>))}
  </Container>
);

ButtonBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonBar;
