/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { withRouter } from "react-router-dom";
import Icons from '../assets/activityIcons';
import { API_URL } from '../env';
import {
  Box,
  Image,
  Level,
  LevelLeft,
  LevelRight,
  LevelItem,
  MainAvatarImage,
} from './styled';
import { openPopupWidget } from "react-calendly";

moment.updateLocale('en', {
  relativeTime: {
    h: "1 hour",
  },
});

const ActivityBox = styled(Box)`
  &&& {
    cursor: pointer;
  }
`;


// TODO: Due date and due time will show together if both set
const DatesDisplay = ({ dueDate, dueTime, duration }) => {
  const datesAsText = [dueDate && dueDate.format('Do MMMM Y'),
    dueTime && dueTime.format('Do MMMM Y @ LT'),
    duration && duration.humanize()].filter(value => value);
  return <h5 className="has-text-light">{_.join(datesAsText, " - ")}</h5>;
};

export const Activity = ({
  status,
  title,
  dueDate,
  duration,
  avatar,
  history,
  id,
  subtitles,
  type,
  dueTime,
  completedAt,
  viewerRole = "participant",
  coach,
  programId,
  moduleId,
  moduleCalendlyId,
  profile,
  match,
}) => {
  const coachViewingCoach = viewerRole === "coach" && type === "CoachCall";
  let selectActivity;

  if (!coachViewingCoach) {
    selectActivity = () => {
      let currentUrl = match.url;
      if (!currentUrl.endsWith("/")) {
        currentUrl += "/";
      }
      let calendlyUuid = moduleCalendlyId || 'coaching-session-45-min';

      if (type === 'CoachCall' && coach.calendlyId && !dueTime && status !== 'NeedsAttention') {
        openPopupWidget({ url: `https://calendly.com/${coach.calendlyId}/${calendlyUuid}?email=${profile.emails[0].value}&name=${profile.displayName}` });
      } else {
        history.push(`${currentUrl}${type.toLowerCase()}/${id}`);
      }
    };
  }

  const icon = Icons[status];
  return (
    <ActivityBox className="card-shadow" onClick={selectActivity} data-testid={`${id}-select`}>
      <Level className="is-mobile" tag="div">
        <LevelLeft>
          <LevelItem>
            <Image src={icon} alt={status} isSize="18x18" />
          </LevelItem>
          <LevelItem>
            <div>
              <div className="body-bold" data-testid={`${id}-title`}>
                {title}
              </div>
              {completedAt ? <h5 className="has-text-light">{moment(completedAt).format('DD/MM/YYYY HH:mm')}</h5> : ''}
              <DatesDisplay duration={duration} dueDate={dueDate} dueTime={dueTime} />
              {subtitles && subtitles.map((subtitle, index) => (<h5 className="has-text-light" key={index}>{subtitle}</h5>))}
            </div>
          </LevelItem>

        </LevelLeft>
        {avatar
        && (
        <LevelRight>
          <LevelItem>
            <MainAvatarImage src={avatar && (avatar.startsWith("http") ? avatar : API_URL + avatar)} alt="Avatar" size="49x49" />
          </LevelItem>


        </LevelRight>
        ) }
      </Level>
    </ActivityBox>
  );
};

export default withRouter(Activity);
