import React, { useContext, useReducer, useState } from 'react';
import { withRouter } from 'react-router';
import TwoColumn from '../TwoColumn';
import Loader from '../Loader';
import {
  Column,
  PrimaryButton,
  Box,
  PrimaryTextButton,
  Control,
  TextArea,
  Field,
  Modal,
  CloseIcon,
  Button,
} from '../styled';
import BasicActivityBoard from '../BasicActivityBoard';
import useApiFetch from '../../hooks/useApiFetch';
import LinksContext from '../../LinksContext';
import ToastContext from "../../ToastContext";
import {
  requestParticipantPasswordChange,
  fetchModules,
} from '../../api';
import { fetchNotesForParticipant, updateNote, createNote, deleteNote } from '../../api/notes';
import { fetchActivitiesForParticipant } from '../../api/activities';
import { fetchSingleParticipant, progressParticipant } from '../../api/participants';
import _ from "lodash";
import moment from 'moment';

const ParticipantDetails = ({ participant, updateModuleNumber, setState, history }) => {
  const linksContext = useContext(LinksContext);
  const toast = useContext(ToastContext);

  const navigateToEmails = () => {
    history.push(`/participants/${participant.id}/email_logs`);
  };

  const progressParticipantByOneModule = async () => {
    if (window.confirm('Are you sure you wish to progress this user to the next module? This cannot be reversed.')) {
      await progressParticipant(linksContext, participant);
      updateModuleNumber();
    }
  };

  const requestChangePassword = async () => {
    if (window.confirm('Are you sure you wish to send a change password email to this participant?')) {
      await requestParticipantPasswordChange(participant);

      toast.push({ content: "Password change request sent. The Participant should receive an email shortly." });
    }
  };

  return (
    <Column>
      {!participant && (<Loader/>)}
      {participant && (
        <div>
          <h4>{participant.name}</h4>
          <p><b>Invited At:</b> {moment(participant.createdAt).format('DD/MM/YYYY HH:mm')}</p>
          <p><b>Inducted At:</b> {moment(participant.inductedAt).format('DD/MM/YYYY HH:mm')}</p>
          {participant.archivedAt ? <p><b>Archived At:</b> {moment(participant.archivedAt).format('DD/MM/YYYY HH:mm')}</p> : ''}
          <p><b>Email Verified:</b> {participant.emailVerified}</p>
          <p><b>Logged in date:</b> {moment(participant.lastLogin).format('DD/MM/YYYY')}</p>
          <p><b>Phone:</b> {participant.phone}</p>
          <br/>

          <PrimaryButton onClick={requestChangePassword}>Request Change Password</PrimaryButton>

          <br/>
          <br/>

          <PrimaryButton data-testid="show-notes" onClick={async () => { setState({ showNotes: true }); }}>Show Notes</PrimaryButton>

          <br/>
          <br/>

          <PrimaryButton data-testid="show-emails" onClick={navigateToEmails}>Show Emails</PrimaryButton>

          <br/>
          <br/>

          <PrimaryButton onClick={progressParticipantByOneModule}>Start Next Module</PrimaryButton>
        </div>
      )
      }
    </Column>
  );
};

const ParticipantActivities = ({ participant, viewerRole = "participant" }) => {
  const { result } = useApiFetch(fetchActivitiesForParticipant, participant);
  const { items } = useApiFetch(fetchModules);
  const currentModules = participant.moduleOrder.slice(0, participant.moduleOrder.indexOf(participant.currentModuleId) + 1);

  return (
    <Column>
      {(!result || !items) && (<Loader />)}

      {(result && items) && (
        <div>
          <BasicActivityBoard
            activityData={result}
            moduleData={items}
            showCallBooking={false}
            currentModules={currentModules}
            viewerRole={viewerRole}
            profileState={{ result: { moduleOrder: participant.moduleOrder, currentModuleId: participant.currentModuleId, enabledPrograms: participant.enabledPrograms } }}
          />
        </div>
      )}

    </Column>
  );
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "updateFormState":
      return {
        ...state,
        formState: { ...state.formState, ...action.payload },
        modified: _.union(state.modified, Object.keys(action.payload)),
      };
    case "setTimer":
      return {
        ...state,
        timer: action.payload,
      };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

const EditNoteForm = ({ note, setState }) => {
  const [state, dispatch] = useReducer(formReducer, { formState: note || {}, modified: [] });
  const { formState } = state;
  const linksContext = useContext(LinksContext);

  const update = (args) => {
    dispatch(args);
    clearTimeout(state.timer);

    const timer = setTimeout(() => {
      const noteToUpdate = formState;
      noteToUpdate.note = args.payload.note;
      updateNote(linksContext, noteToUpdate);
    }, 500);
    dispatch({ type: "setTimer", payload: timer });
  };

  return (
    <>
      {note.links.modify && (
        <Column isSize="narrow">
          <PrimaryButton colour="light-grey" size="small" noHover onClick={() => {
            updateNote(linksContext, formState);
            note.editMode = false;
            note.note = formState.note;
            setState({ loading: false, showNotes: true });
          }} paddingless>
            Save
          </PrimaryButton>
        </Column>
      )}

      <Field>
        <Control>
          <TextArea placeholder="Note" value={formState.note || ""}
            onChange={e => update({ type: 'updateFormState', payload: { note: e.target.value } })}/>
        </Control>
      </Field>
    </>
  );
}

const NewNoteForm = ({ result, setState, participant }) => {
  const [state, dispatch] = useReducer(formReducer, {
    formState: { ParticipantId: participant.id },
    modified: []
  });
  const { formState } = state;
  const linksContext = useContext(LinksContext);

  return (
    <>
      {result.links.add && (
        <Column isSize="narrow">
          <PrimaryButton colour="light-grey" size="small" noHover onClick={async () => {
            const note = await createNote(linksContext, formState);
            formState.note = "";
            result.notes.unshift(note);
            setState({ loading: false, showNotes: true });
          }} paddingless>
            Save
          </PrimaryButton>
        </Column>
      )}

      <Field>
        <Control>
          <TextArea placeholder="Note" value={formState.note || ""}
            onChange={e => dispatch({ type: 'updateFormState', payload: { note: e.target.value } })}/>
        </Control>
      </Field>
    </>
  );
}

const Notes = ({ participant, showNotes, setState, state }) => {
  const { result } = useApiFetch(fetchNotesForParticipant, participant);
  const linksContext = useContext(LinksContext);

  if (result) {
    return (
      <>
        <Modal isActive={showNotes}>
          <Box key="note-modal" style={{ width: '100%', height: '100%', 'overflow-y': 'scroll' }}>
            <Button isOutlined onClick={() => {
              setState({ showNotes: false });
            }} noHover className="card-shadow">
              <CloseIcon/>
            </Button>

            <div>
              <div>
                <Box key="newNote">
                  <NewNoteForm result={result} setState={setState} participant={participant} currentState={state} />
                </Box>

                {result.notes.map(note => (
                  <Box key={note.id}>
                    <b>{moment(note.createdAt).format('DD/MM/YYYY HH:mm')}</b>

                    {note.editMode ?
                      (
                        <>
                          <EditNoteForm note={note} setState={setState}/>
                        </>
                      ) :
                      (
                        <>
                          {note.links.modify && (
                            <Column isSize="narrow" className='modify-button-row'>
                              <PrimaryTextButton data-testid={`${note.id}-edit`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => {
                                note.editMode = true;
                                setState({ loading: false, showNotes: true });
                              }} paddingless>
                                EDIT
                              </PrimaryTextButton>

                              <PrimaryTextButton data-testid={`${note.id}-delete`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => {
                                const confirm = window.confirm("Are you sure you want to delete this note?");
                                if (confirm) {
                                  deleteNote(linksContext, note);
                                  _.remove(result.notes, e => e.id === note.id);
                                  setState({ loading: false, showNotes: true });
                                }
                              }} paddingless>
                                DELETE
                              </PrimaryTextButton>
                            </Column>
                          )}

                          <p data-testid={`${note.id}-note`}>
                            {note.note}
                          </p>
                        </>
                      )}
                  </Box>
                ))}
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <div/>
      </>
    );
  }
};

const ParticipantPage = ({ id, history }) => {
  const { result } = useApiFetch(fetchSingleParticipant, id);
  const [state, setState] = useState({ showNotes: false, counter: 0 });

  const updateModuleNumber = () => {
    result.currentModuleId = result.moduleOrder[result.moduleOrder.indexOf(result.currentModuleId) + 1];
    setState({ counter: state.counter + 1 });
  };

  if (result) {
    return (
      <>
        <TwoColumn
          ColumnOneComponent={() => <ParticipantDetails setState={setState} participant={result}
            updateModuleNumber={updateModuleNumber} history={history} />}
          ColumnTwoComponent={() => <ParticipantActivities participant={result} viewerRole="coach"/>}
        />
        <Notes participant={result} showNotes={state.showNotes} setState={setState} state={state} />
      </>
    );
  }

  return <Loader/>;
};

export default withRouter(ParticipantPage);
