import React, { useEffect } from 'react';
import _, { map, cloneDeep } from 'lodash';
import styled from 'styled-components';
import { Label, Button } from 'bloomer';
// eslint-disable-next-line import/no-cycle
import { Field } from './field';
import { getArrayValues, buildEntry, valuesHashForSingleEntry, valuesHashFromArray, indexContentfulId } from './duplicatedFieldHelpers';
import { LabelText, PlusIcon } from '../../styled';

const duplicatedData = (data, entry) => {
  const returnData = cloneDeep(data);

  returnData.sys.id = entry.contentfulId;
  returnData.fields.questionText = "";

  return returnData;
};

const AddMore = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

const AddAnotherText = styled.div`
  height: max-content;
  margin-left: 0.75rem;
`;

const DuplicatedField = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  .duplicated-field {
    flex-basis: 85%;
  }
  .close-duplicated {
    flex-basis: 5%;
    align-items: center;
    cursor: pointer;
    margin-left: 1.5rem;
    display: flex;
  }
`;

export const UserDuplicatedGroupField = ({
  data, updateState, currentValues, updateEntireExerciseFormState, submitted,
}) => {
  const questionId = data.fields.question.sys.id;
  const entries = getArrayValues(currentValues, questionId);

  const addField = () => {
    const nextIndex = entries.length;
    const entry = buildEntry(questionId, nextIndex);
    updateState(entry);
  };

  useEffect(() => {
    if (entries.length === 0) addField();
  });

  const removeField = entry => (event) => {
    event.preventDefault();

    const remainingEntries = _.reject(entries, { contentfulId: entry.contentfulId });
    const existing = _.cloneDeep(currentValues);
    const hashedEntries = valuesHashFromArray(remainingEntries);

    const newArrangement = { ...existing, ...hashedEntries };
    newArrangement[indexContentfulId(questionId, remainingEntries.length)] = null;
    updateEntireExerciseFormState(newArrangement);
  };

  return (
    <div>
      <Label>
        <LabelText>
          {data.fields.question.fields.questionText}
          {data.fields.question.fields.required ? '*' : ''}
        </LabelText>
      </Label>
      <span className='help is-danger'>{(data.fields.required && submitted && entries) ? 'You must select an answer' : ''}</span>
      {
        map(entries, (entry, _index) => (
          entry.uuid && (
            <DuplicatedField key={entry.uuid}>
              <div className="duplicated-field">
                <Field
                  className="duplicated-field"
                  data={duplicatedData(data.fields.question, entry)}
                  updateState={updateState}
                  currentValues={valuesHashForSingleEntry(entry)}
                />
              </div>
              <div className="close-duplicated">
                <img src="images/trash.svg" alt="" onClick={removeField(entry)} />
              </div>
            </DuplicatedField>
          )
        ))
      }
      <AddMore>
        <Button className="button is-outlined is-small" onClick={addField}>
          <PlusIcon />
        </Button>
        <AddAnotherText>
          Add Another
        </AddAnotherText>
      </AddMore>
    </div>
  );
};
