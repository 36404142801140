import React, { useReducer } from 'react';
import _ from 'lodash';

import Select from 'react-select';
import Loader from '../Loader';
import { fetchParticipants } from '../../api/participants';
import useApiFetch from '../../hooks/useApiFetch';
import {
  Box, Field, Label, Control, Input, PrimaryButton, Columns, Column, SmallTextArea,
} from "../styled";
import DashboardButton from '../DashboardButton';
import moment from "moment";


export const EmailLogList = ({
  emailLogs, loading, onEdit, history, participantId,
}) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <h4 className="has-text-weight-bold">{`${emailLogs.length} Email Logs`}</h4>
      {emailLogs.map(({
        id, fromEmail, toEmail, subject, sendAt, createdAt, content, links,
      }) => (
        <Box
          key={id}
        >
          <Columns className="is-variable is-mobile is-0-mobile is-3-tablet is-3-desktop">
            <Column>
              <div className="body-bold" data-testid={`${id}-subject`}>{subject}</div>
            </Column>

            <Column isSize="narrow">
              <h5>{toEmail}</h5>
            </Column>
            <Column isSize="narrow">
              <h5>{fromEmail}</h5>
            </Column>
            <Column isSize="narrow">
              <h5>{moment(sendAt || createdAt).format('DD/MM/YYYY HH:mm')}</h5>
            </Column>
          </Columns>
        </Box>
      ))}

    </>
  );
};

export const AddEmailLogButton = ({ onClick }) => (
  <DashboardButton dataTestId="email-log-new" onClick={onClick} caption="Send a new email" />
);

const formReducer = (state, action) => {
  switch (action.type) {
    case "updateFormState":
      return { ...state, formState: { ...state.formState, ...action.payload }, modified: _.union(state.modified, Object.keys(action.payload)) };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

const isParticipantValid = state => (state.formState.ParticipantId && state.formState.ParticipantId.toString().length > 0);

export const EmailLogDialog = ({ onCancel, onSave, initialState, participantId }) => {
  if (participantId) {
    initialState.ParticipantId = participantId;
  }
  const participants = useApiFetch(fetchParticipants);
  const [state, dispatch] = useReducer(formReducer, { formState: initialState || {}, modified: [] });
  const { formState } = state;
  const isSubmitEnabled = !!isParticipantValid(state);

  return (
    <Box>
      <Field>
        <Label>Subject</Label>
        <Control>
          <Input data-testid="form-subject" placeholder="Subject" disabled={false} value={formState.subject || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { subject: e.target.value } })} />
        </Control>
      </Field>

      <Field>
        <Label>Content</Label>
        <Control>
          <SmallTextArea placeholder="Content" disabled={false} value={formState.content || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { content: e.target.value } })} />
        </Control>
      </Field>

      {participantId ?
        ''
        :
        (<Field>
          <Label>Participant</Label>
          <Control>
            {participants.items ? (<Select aria-label="Participants Select"
                                          options={participants.items.map(participant => ({
                                            value: participant.id,
                                            label: participant.name
                                          }))} onChange={e => dispatch({
                                            type: 'updateFormState',
                                            payload: { ParticipantId: e.value },
                                          })} value={formState.Participant} disabled={!participants.items}/>) :
              <span>Loading...</span>}
          </Control>
        </Field>)
      }

      <Field>
        <Label>Send At</Label>
        <Control>
          <Input type="datetime-local" data-testid="form-send-at" placeholder="Send At" disabled={false} value={formState.sendAt || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { sendAt: e.target.value } })} />
        </Control>
      </Field>

      <div className="buttons">
        <PrimaryButton isInverted onClick={onCancel}>Cancel</PrimaryButton>
        <PrimaryButton data-testid="form-submit" disabled={!isSubmitEnabled && "disabled"} onClick={() => onSave(formState)}>Save</PrimaryButton>
      </div>
    </Box>
  );
};
