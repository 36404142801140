/* eslint-disable arrow-parens */
import React from 'react';
import { Label } from 'bloomer';
import { LabelText, SmallSelect } from '../../styled';
import { newState } from './fieldHelpers';

export const MultipleChoiceField = ({ data, updateState, currentValues, submitted }) => {
  const change = (event) => newState(data, updateState)(event);
  const currentValue = (currentValues) ? currentValues.value : "";
  return (
    <div>
      <Label htmlFor={data.sys.id}>
        <LabelText>
          {data.fields.questionText}
          {data.fields.required ? '*' : ''}
        </LabelText>
        <span className='help is-danger'>{(data.fields.required && submitted && currentValue === "") ? 'You must select an answer' : ''}</span>
        <SmallSelect placeholder="type something" id={data.sys.id} onChange={change} tabIndex="-1" defaultValue={currentValue}>
          <option value=""></option>
          {
            data.fields.options.map(option => <option value={option.sys.id} key={option.sys.id}>{option.fields.questionText}</option>)
          }
        </SmallSelect>
      </Label>
    </div>
  );
};
