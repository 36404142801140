import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  Container, PrimaryButton, PrimaryTextButton, MainImage,
} from '../styled';
import FullscreenHero, { FullscreenHeroActivityNav } from '../FullscreenHero';

import CoachChatArtwork from '../../assets/coachChatArtwork.svg';
import BookingInformation from './BookingInformation';
import VideoCallInformation from './VideoCallInformation';

const BookNowButton = styled(PrimaryButton)`
  &&& {
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
`;

const PrepList = styled.ul`
  list-style: inside;
`;

const RegularBooked = () => (
  <>
    <p>We are looking forward to seeing you at your next coaching session.</p><br />
    <p>To get the most from this coaching session:</p><br />
    <PrepList>
      <li>Complete all the exercises in this module prior to the coaching session</li>
      <li>Come prepared with any questions you may have</li>
      <li>Find a quiet space where you can have a private conversation.</li>
      <li>Set up a few minutes early and make sure your technology is working</li>
    </PrepList>
  </>
);

const RegularUnbooked = () => (
  <>
    <p>It’s that time! Time to set up your coaching session. This will be a great time to discuss what you have learnt from the exercises in this module and how you are tracking with your goals and plans.</p>
  </>
);

const Introduction = ({
  coachCall, bookNowClicked, cancelBookingClicked, fromDashboard, history,
}) => {
  const isBooked = !!coachCall.bookingDetails;
  const { contentFields } = coachCall;

  return (
    <FullscreenHero
      showCloseButton={fromDashboard}
      headerRender={props => <FullscreenHeroActivityNav lightHeader {...props} showCloseButton />}
    >
      <MainImage src={CoachChatArtwork} />
      <Container>
        <h5>{contentFields.subtitle}</h5>
        <h3>
          {contentFields.title}
        </h3>
        <p>{contentFields.preamble}</p>
        <br />
        {
          contentFields.callType === 'regular' && (isBooked ? <RegularBooked /> : <RegularUnbooked />)
        }
        {
          isBooked ? (null) : null
        }
        {/* {isBooked && false && (
          <div>
            <p>Congratulations, your first session is now booked! To be prepared and ready to get the most out of this coaching session:</p>
            <br />
            <PrepList>
              <li>find a quiet space</li>
              <li>be set up a few minutes early</li>
              <li>make sure your technology is working</li>
            </PrepList>
            <br />
            <p>We are looking forward to getting started and hope you are too.</p>
          </div>
        )} */}
      </Container>
      <Container>
        {coachCall.bookingDetails && (
          <>
            <BookingInformation
              coachCall={coachCall}
              bookNowClicked={bookNowClicked}
              cancelBookingClicked={cancelBookingClicked}
            />
            <VideoCallInformation
              coachCall={coachCall}
            />
          </>
        )}
        { coachCall.bookingDetails ?
          (
            <div style={{ textAlign: 'center' }}>
              <PrimaryButton onClick={() => { history.push("/"); }}>Back to dashboard</PrimaryButton>
            </div>
          ) :
          (
            <div>
              <BookNowButton onClick={bookNowClicked}>Book Now</BookNowButton>
              <PrimaryTextButton onClick={() => { history.push("/"); }}>Do it later</PrimaryTextButton>
            </div>
          )
        }
      </Container>
    </FullscreenHero>
  );
};

Introduction.propTypes = {
  coachCall: PropTypes.shape({
    bookingDetails: PropTypes.shape({}),
    contentFields: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  }).isRequired,
  bookNowClicked: PropTypes.func.isRequired,
  cancelBookingClicked: PropTypes.func.isRequired,
  fromDashboard: PropTypes.bool,
};
Introduction.defaultProps = {
  fromDashboard: false,
};

export default withRouter(Introduction);
