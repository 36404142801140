import React, { useState, useReducer, useCallback } from 'react';
import ToastContext from '../ToastContext';
import {
  ToastContainer, ToastNotification, Delete,
} from "./styled";


const Toast = ({ content, onComplete }) => {
  const [animationState, setAnimationState] = useState("appearing");
  const onAnimationEnd = useCallback(() => {
    setAnimationState(null);
  }, []);

  if (animationState == null) {
    onComplete();
  }
  return (
    <ToastNotification role="alert" isColor="dark-grey" className="toast-animated" onAnimationEnd={onAnimationEnd}>


      <span>

        {content}

      </span>
      <Delete onClick={() => setAnimationState(null)} />
    </ToastNotification>
  );
};
const messagesReducer = ({ messages, count }, action) => {
  switch (action.type) {
    case 'push':

      return { count: count + 1, messages: [...messages, { ...(action.payload), id: count }] };
    case 'remove':
      return { messages: messages.filter(({ id }) => id !== action.payload), count };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};
const Toaster = ({ children, ToastComponent }) => {
  const [{ messages }, dispatch] = useReducer(messagesReducer, { messages: [], count: 0 });

  return (
    <ToastContext.Provider value={{ push: message => dispatch({ type: 'push', payload: message }) }}>
      <ToastContainer>
        {messages.map(message => <ToastComponent key={message.id} {...message} onComplete={() => dispatch({ type: 'remove', payload: message.id })} />)}
      </ToastContainer>

      {children}


    </ToastContext.Provider>
  );
};

Toaster.defaultProps = {
  ToastComponent: Toast,
};

export default Toaster;
