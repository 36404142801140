import React from 'react';
import Loader from '../Loader';
import useApiFetch from '../../hooks/useApiFetch';
import { fetchSingleParticipant } from '../../api/participants';
import CoachExerciseView from './CoachExerciseView';

export default ({ participantId, exerciseId }) => {
  const { result } = useApiFetch(fetchSingleParticipant, participantId);
  if (result) {
    return (
      <div>
        <CoachExerciseView exerciseId={exerciseId} participant={result} />
      </div>

    );
  }

  return <Loader />;
};
