
export const newState = (data, updateState, existingValue = {}) => (
  (event) => {
    updateState({
      contentfulId: data.sys.id,
      value: event.target.value,
      revision: data.sys.revision,
      questionText: data.fields.questionText,
      questionType: data.sys.contentType.sys.id,
      selectedText: (data.sys.contentType.sys.id === "multipleChoice") ? event.target.options[event.target.selectedIndex].text : "",
      uuid: (existingValue && existingValue.uuid),
    });
  }
);

export const newRatingState = (data, updateState) => (
  (event) => {
    updateState({
      contentfulId: data.sys.id,
      value: event.target.value,
      revision: data.sys.revision,
      questionText: data.fields.questionText,
      questionType: data.sys.contentType.sys.id,
      selectedText: event.target.value,
      minimumValueTitle: data.fields.minimumValueTitle,
      minimumValue: data.fields.minimumValue,
      maximumValue: data.fields.maximumValue,
    });
  }
);

export const newMultiCheckboxState = (data, updateState, values) => {
  updateState({
    contentfulId: data.sys.id,
    value: values,
    revision: data.sys.revision,
    questionText: data.fields.questionText,
    questionType: data.sys.contentType.sys.id,
  });
};

const pushToHash = (hash, name, val) => {
  const newHash = hash;
  newHash[name] = val;
  return hash;
};

export const newMatrixState = (data, updateState, currentValue) => (
  (event) => {
    updateState({
      contentfulId: data.sys.id,
      value: pushToHash(currentValue, event.target.name.split("-")[1], event.target.value),
      revision: data.sys.revision,
      questionText: data.fields.questionText,
      questionType: data.sys.contentType.sys.id,
    });
  }
);
