import React from 'react';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import Loader from '../../../components/Loader';
import { fetchOrganisations, createOrganisation, updateOrganisation } from '../../../api/organisations';
import useAdminDataManager from '../../../hooks/useAdminDataManager';
import {
  Section,
} from "../../../components/styled";
import {
  OrganisationList,
} from "../../../components/pages/organisations";

export default withRouter(({ history }) => {
  const { manage, state } = useAdminDataManager(fetchOrganisations, createOrganisation, updateOrganisation, {archived: true});

  const {
    cancelEdit, startEdit, startCreate, saveData,
  } = manage;

  const { currentMode, items, targetItem } = state;
  const renderByMode = {
    loading: () => <Loader />,
    listing: () => (
      <>
        <Section>
          <OrganisationList organisations={_.orderBy(items, org => (org.name || "").toUpperCase())} onEdit={startEdit} history={history} isAdmin={true} />
        </Section>
      </>
    ),
  };
  return (
    <>
      <h2> Archived Organisations </h2>
      {renderByMode[currentMode]()}
    </>

  );
});
