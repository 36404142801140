import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { createClient } from '../../contentful/client';
import SimpleExercise from './SimpleExercise';
import ReadOnlyExercise from './ReadOnlyExercise';
import ExerciseResults from './results/ExerciseResults';

const { fetchExerciseById } = require('../../contentful/activity');

const PopulatedExercise = ({
  exerciseId, activityId, exerciseSubmission, currentModuleNumber, history, isParticipant, isCoach,
}) => {
  const [contentfulData, setContentfulData] = useState();

  if (!contentfulData) {
    const client = createClient();
    fetchExerciseById(client, exerciseId).then((result) => {
      setContentfulData(result);
    }, (reason) => { console.log("Error fetching activity data", reason); });
  }

  const closeExercise = () => {
    history.push(`/`);
  };

  const [complete, updateComplete] = useState(exerciseSubmission.complete);

  if (contentfulData) {
    const exerciseTypeId = contentfulData.sys.contentType.sys.id;

    const restart = () => {
      updateComplete(false);
    };

    if (exerciseTypeId === "readOnlyExercise" && (isParticipant || isCoach)) {
      return (
        <ReadOnlyExercise
          exerciseConfig={contentfulData}
          activityId={activityId}
          exerciseSubmission={exerciseSubmission || {}}
        />
      );
    }

    if ((isParticipant || isCoach) && !complete) {
      if (exerciseTypeId === "regurgitationExercise"
            || exerciseTypeId === "scoringExercise"
            || exerciseTypeId === "whittlingExercise"
            || exerciseTypeId === "matrixExercise") {
        return (
          <SimpleExercise
            exerciseConfig={contentfulData}
            activityId={activityId}
            isParticipant={isParticipant}
            exerciseSubmission={exerciseSubmission}
          />
        );
      }
      return <div>No views for this exercise type yet:: </div>;
    }
    return (
      <ExerciseResults
        exerciseConfig={contentfulData}
        formState={exerciseSubmission || {}}
        closeExercise={closeExercise}
        restart={restart}
      />
    );
  }

  return <div>Loading....</div>;
};

const RoutedPopulatedExercise = withRouter(PopulatedExercise);
export default RoutedPopulatedExercise;
