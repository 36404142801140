import React, { useContext, useReducer, useState } from 'react';

import Loader from '../Loader';
import {
  Box, Field, Label, Control, Input, PrimaryButton, Columns, Column, PrimaryTextButton,
} from "../styled";

import DashboardButton from '../DashboardButton';
import ModalLayout from "../ModalLayout";
import LinksContext from "../../LinksContext";
import { deleteOrganisation, updateOrganisation } from '../../api/organisations';
import _ from "lodash";
import moment from "moment";

export const OrganisationList = ({ organisations, loading, onEdit, history, isAdmin }) => {
  const [state, setState] = useState({ showDeleteId: '', showArchiveId: '', userInput: '', organisations, filteredOrganisations: organisations, organisationSearch: '' });
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Field>
        <Label>Search by Name</Label>
        <Control>
          <Input placeholder="Organisation name" value={state.organisationSearch || ""} onChange={(e) => {
            const filteredOrganisations = _.filter(state.organisations, org => org.name.toLowerCase().includes(e.target.value.toLowerCase()));

            setState({ showDeleteId: '', userInput: '', organisations, filteredOrganisations, organisationSearch: e.target.value });
          }
          }
          />
        </Control>
      </Field>

      {_.sortBy(state.filteredOrganisations, org => org.name.toLowerCase()).map((organisation) => (
        <Box key={organisation.id}>
          <Columns>
            <Column data-testid={`${organisation.id}-link`} onClick={() => { history.push(`/organisations/${organisation.id}`); }}>
              <div className="body-bold" data-testid={`${organisation.id}-name`}>{organisation.name}</div>
              {organisation.archivedAt ? <h5>Archived At: {moment(organisation.archivedAt).format('DD/MM/YYYY HH:mm')}</h5> : ''}
            </Column>
            {organisation.links.modify && (
              <Column isSize="narrow" className='modify-button-row'>
                <PrimaryTextButton data-testid={`${organisation.id}-edit`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => onEdit(organisation.id)} paddingless>EDIT</PrimaryTextButton>
                {isAdmin ? <PrimaryTextButton data-testid={`${organisation.id}-delete`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => setState({ showArchiveId: '', showDeleteId: organisation.id, userInput: '', organisations, filteredOrganisations: state.filteredOrganisations, organisationSearch: state.organisationSearch })} paddingless>DELETE</PrimaryTextButton> : ''}
                {isAdmin ? <PrimaryTextButton data-testid={`${organisation.id}-archive`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => setState({ showArchiveId: organisation.id, showDeleteId: '', userInput: '', organisations, filteredOrganisations: state.filteredOrganisations, organisationSearch: state.organisationSearch })} paddingless>{organisation.archivedAt ? 'RESTORE' : 'ARCHIVE'}</PrimaryTextButton> : ''}
              </Column>
            )}
          </Columns>

          {isAdmin ? <OrganisationDeleteDialog showDeleteId={state.showDeleteId} filteredOrganisations={state.filteredOrganisations} organisationSearch={state.organisationSearch} userInput={state.userInput} setState={setState} organisation={organisation} organisations={state.organisations} /> : ''}
          {isAdmin ? <OrganisationArchiveDialog showArchiveId={state.showArchiveId} filteredOrganisations={state.filteredOrganisations} organisationSearch={state.organisationSearch} archiveValue={organisation.archivedAt ? null : new Date()} userInput={state.userInput} setState={setState} organisation={organisation} organisations={state.organisations}/> : ''}
        </Box>
      ))}

    </>
  );
};

export const AddOrganisationButton = ({ onClick }) => (
  <DashboardButton dataTestId="organisation-new" onClick={onClick} caption="Add a new organisation" />
);

const isNameValid = state => (state.formState.name && state.formState.name.length > 0);
const getNameInputColor = (state) => {
  if (!state.modified) {
    return undefined;
  }
  if (isNameValid(state)) {
    return "success";
  }
  return "danger";
};
const isSubmitEnabled = state => !!isNameValid(state);

const formReducer = (state, action) => {
  switch (action.type) {
    case "updateFormState":
      return { ...state, formState: { ...state.formState, ...action.payload }, modified: true };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

export const OrganisationDialog = ({ onCancel, onSave, initialState }) => {
  const [state, dispatch] = useReducer(formReducer, { formState: initialState || {}, modified: initialState.id });

  const { formState } = state;
  const nameInputColour = getNameInputColor(state);
  return (
    <Box>
      <Field>
        <Label>Name</Label>
        <Control>
          <Input data-testid="form-name" placeholder="Organisation name" isColor={nameInputColour} value={formState.name || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { name: e.target.value } })} />
        </Control>
      </Field>
      <div className="buttons">
        <PrimaryButton isInverted onClick={onCancel}>Cancel</PrimaryButton>
        <PrimaryButton data-testid="form-submit" disabled={!isSubmitEnabled(state) && "disabled"} onClick={() => onSave(formState)}>Save</PrimaryButton>
      </div>
    </Box>
  );
};

export const OrganisationDeleteDialog = ({ organisations, filteredOrganisations, organisationSearch, organisation, showDeleteId, userInput, setState }) => {
  const linksContext = useContext(LinksContext);

  if (showDeleteId === organisation.id) {
    return (
      <ModalLayout>
        <h4>Are you sure you want to delete {organisation.name}?</h4>
        <p>Enter the name below to confirm</p>

        <Field>
          <Control>
            <Input data-testid="form-name" placeholder="Organisation name" value={userInput || ""} onChange={e => (setState({ userInput: e.target.value, showDeleteId: organisation.id, showArchiveId: '', organisations, filteredOrganisations, organisationSearch }))} />
          </Control>
        </Field>

        <div className="buttons">
          <PrimaryButton isInverted onClick={() => setState({ showDeleteId: '', showArchiveId: '', userInput: '', organisations, filteredOrganisations, organisationSearch })}>Cancel</PrimaryButton>
          <PrimaryButton data-testid="form-submit" onClick={async () => {
            if (organisation.name.toLowerCase() === userInput.toLowerCase()) {
              _.remove(organisations, e => e.id === organisation.id);
              await deleteOrganisation(linksContext, organisation);
              setState({ showDeleteId: '', showArchiveId: '', userInput: '', organisations, filteredOrganisations, organisationSearch });
            }
          }}>
            Delete
          </PrimaryButton>
        </div>
      </ModalLayout>
    );
  } else {
    return "";
  }
};

export const OrganisationArchiveDialog = ({ organisations, organisation, filteredOrganisations, organisationSearch, archiveValue, showArchiveId, userInput, setState }) => {
  const linksContext = useContext(LinksContext);

  if (showArchiveId === organisation.id) {
    return (
      <ModalLayout>
        <h4>Are you sure you want to {archiveValue === null ? 'restore' : 'archive'} {organisation.name}?</h4>
        <p>Enter the name below to confirm</p>

        <Field>
          <Control>
            <Input data-testid="form-name" placeholder="Organisation name" value={userInput || ""} onChange={e => (setState({ userInput: e.target.value, showArchiveId: organisation.id, showDeleteId: '', organisations, filteredOrganisations, organisationSearch }))} />
          </Control>
        </Field>

        <div className="buttons">
          <PrimaryButton isInverted onClick={() => setState({ showArchiveId: '', showDeleteId: '', userInput: '', organisations, filteredOrganisations, organisationSearch })}>Cancel</PrimaryButton>
          <PrimaryButton data-testid="form-submit" onClick={async () => {
            if (organisation.name.toLowerCase() === userInput.toLowerCase()) {
              _.remove(organisations, e => e.id === organisation.id);
              organisation.archivedAt = archiveValue;
              await updateOrganisation(linksContext, organisation);
              setState({ showArchiveId: '', showDeleteId: '', userInput: '', organisations, filteredOrganisations, organisationSearch });
            }
          }}>
            {archiveValue === null ? 'Restore' : 'Archive'}
          </PrimaryButton>
        </div>
      </ModalLayout>
    );
  } else {
    return "";
  }
};
