import React, { useState } from 'react';
import moment from 'moment';
import Swiper from 'react-id-swiper';
import { withRouter } from "react-router-dom";
import { Introduction } from './Introduction';
import Question from './Question';
import ExerciseResults from './results/ExerciseResults';
import { PrimaryButton, Container } from '../styled';
import { sendExerciseData } from '../../api';
import { ExerciseHeader } from './ExerciseHeader';
import FullscreenHero, { FullscreenHeroActivityNav, FullscreenHeroActivityFooterControls } from '../FullscreenHero';
import { markdown } from 'markdown';


const ProgressBar = ({ currentIndex, length }) => (
  <progress
    className="progress is-primary swiper-pagination"
    value={currentIndex + 1}
    max={length + 1}
    style={{
      height: "0.4em",
      borderRadius: "0",
      marginBottom: 0,
      position: 'static',
    }}
  >
    {currentIndex}
  </progress>
);

const FooterControls = ({
  currentIndex, goNext, nextButtonText, goPrev,
}) => (
  <FullscreenHeroActivityFooterControls directional>
    <PrimaryButton onClick={goNext}>{nextButtonText()}</PrimaryButton>
    { ((currentIndex && currentIndex > 0) === true) && (
    <a onClick={goPrev} href="/" className="has-text-primary">Previous</a>
    )}

  </FullscreenHeroActivityFooterControls>
);
const ExerciseQuestions = ({
  exerciseConfig, formState, updateState, updateEntireExerciseFormState, submitted,
}) => exerciseConfig
  .fields
  .questions
  .map((questionData, index) => (
    <div data-history={`slide${index}`} key={questionData.sys.id}>
      <Container>
        <Question
          index={index}
          data={questionData}
          updateState={updateState}
          currentValues={formState}
          exerciseType={exerciseConfig.sys.contentType.sys.id}
          updateEntireExerciseFormState={updateEntireExerciseFormState}
          submitted={submitted}
        />
      </Container>
    </div>
  ));

export const SimpleExercise = ({
  exerciseConfig,
  activityId,
  exerciseSubmission,
  isParticipant,
  history,
}) => {
  const [swiper, updateSwiper] = useState(null);
  const [currentIndex, updateCurrentIndex] = useState(null);
  const [formState, updateFormState] = useState(exerciseSubmission || {});
  const [complete, updateComplete] = useState(false);
  const [submitted, updateSubmitted] = useState(false);
  const [lastSaveTime, updateLastSaveTime] = useState();

  const updateKeyedFormState = (data) => {
    const newFormState = { ...formState };
    newFormState[data.contentfulId] = data;
    updateFormState(newFormState);
  };

  const updateEntireExerciseFormState = (newState) => {
    updateFormState(newState);
  };

  if (typeof exerciseConfig.fields.questions === 'undefined') {
    return <div>Questions not defined for this simple exercise yet.</div>;
  }

  const moveToNextSlide = () => {
    if (swiper !== null) {
      swiper.slideNext();
      window.scrollTo(0, 0);
      updateCurrentIndex(swiper.activeIndex);
    }
  };

  const goPrev = (event) => {
    event.preventDefault();
    if (swiper !== null) {
      swiper.slidePrev();
      window.scrollTo(0, 0);
      updateCurrentIndex(swiper.activeIndex);
    }
  };

  const pagePosition = () => {
    if ((currentIndex && currentIndex > 0) && (currentIndex < exerciseConfig.fields.questions.length)) {
      return "middle";
    }

    if (currentIndex === exerciseConfig.fields.questions.length) {
      return "lastPage";
    }

    return "introduction";
  };

  const finish = ({ markComplete } = {}) => {
    const newFormState = formState;
    let finished = false;
    if (markComplete) {
      newFormState.complete = true;
      finished = true;
    }
    updateFormState(newFormState);

    sendExerciseData(activityId, formState, finished, isParticipant);

    updateComplete(true);
  };

  const closeExercise = () => {
    new Promise((resolve, _reject) => { finish(); resolve(true); })
      .then(() => { history.push(`/`); })
      .catch((error) => { console.log("ERROR", error); });
  };

  const goNext = (event) => {
    event.preventDefault();
    const currentQuestion = exerciseConfig.fields.questions[swiper.activeIndex - 1];

    if (swiper.activeIndex > 0 && currentQuestion.fields.required && (formState[currentQuestion.sys.id] === undefined || formState[currentQuestion.sys.id].value === '')) {
      updateSubmitted(true);
    } else {
      updateSubmitted(false);
      sendExerciseData(activityId, formState, false, isParticipant);
      updateLastSaveTime(moment());
      if (pagePosition() === "lastPage") {
        finish({ markComplete: true });
        return;
      }
      moveToNextSlide();
    }
  };

  const nextButtonText = () => {
    switch (pagePosition()) {
      case "introduction":
        return "Let's go";
      case "middle":
        return "Next";
      case "lastPage":
        return "Finish";
      default:
        return "Next";
    }
  };

  const restart = () => {
    updateComplete(false);
    updateCurrentIndex(0);
  };

  if (complete) {
    return (
      <ExerciseResults
        exerciseConfig={exerciseConfig}
        formState={formState}
        restart={restart}
        closeExercise={closeExercise}
      />
    );
  }

  return (
    <FullscreenHero
      showCloseButton
      headerRender={props => (
        <>
          <ProgressBar currentIndex={currentIndex} length={swiper ? swiper.slides.length : 0} />
          <FullscreenHeroActivityNav {...props} backToDashboardAction={closeExercise} lightHeader>
            <div className="navbar-item">
              <ExerciseHeader closeExercise={closeExercise} lastSaveTime={lastSaveTime} />
            </div>
          </FullscreenHeroActivityNav>
        </>
      )}
      footerRender={props => (
        <FooterControls
          currentIndex={currentIndex}
          goNext={goNext}
          nextButtonText={nextButtonText}
          goPrev={goPrev}
          {...props}

        />
      )}
    >
      <form>
        <Swiper
          initialSlide={0}
          autoHeight={false}
          calculateHeight={false}
          pagination={{ el: '.swiper-pagination', type: 'progressbar', progressbarFillClass: 'exercise-progress-bar' }}
          renderPagination={() => <p />}
          getSwiper={updateSwiper}
          simulateTouch={false}
          swipeHandler="invalid-handler"
          noSwiping
        >
          <div data-history="slideintro">
            <Introduction
              title={exerciseConfig.fields.title}
              subtitle={exerciseConfig.fields.subtitle}
              text={exerciseConfig.fields.introduction.split("\n").join("\n\n")}
              closeExercise={closeExercise}
              image={exerciseConfig.fields.bannerImage && exerciseConfig.fields.bannerImage.fields.file.url}
              downloads={exerciseConfig.fields.downloads}
            />
          </div>
          {ExerciseQuestions({
            updateState: updateKeyedFormState, formState, exerciseConfig, updateEntireExerciseFormState, submitted,
          })}
        </Swiper>
      </form>
    </FullscreenHero>
  );
};

export default withRouter(SimpleExercise);
