/* eslint-disable arrow-parens */
import React from 'react';
import { Label } from 'bloomer';
import { LabelText, SmallInput } from '../../styled';
import { newState } from './fieldHelpers';

export const SingleLineField = ({ data, updateState, currentValues, submitted }) => {
  const keyUp = (event) => newState(data, updateState, currentValues)(event);
  const currentValue = (currentValues) ? currentValues.value : "";

  return (
    <div>
      <Label>
        <LabelText>
          {data.fields.questionText}
          {data.fields.required ? '*' : ''}
        </LabelText>
        <span className='help is-danger'>{(data.fields.required && submitted && currentValue === "") ? 'You must select an answer' : ''}</span>
        {' '}
        <SmallInput onKeyUp={keyUp} tabIndex="-1" defaultValue={currentValue} />
      </Label>
    </div>
  );
};
