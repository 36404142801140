import { useReducer, useEffect, useContext } from 'react';
import LinksContext from '../LinksContext';


const getCurrentMode = (loading, targetItem) => {
  if (loading) {
    return "loading";
  }
  if (targetItem) {
    return "editing";
  }
  return "listing";
};

const manageDataReducer = (state, action) => {
  switch (action.type) {
    case "setItems":
      return {
        ...state, loading: false, items: action.payload.items, links: action.payload.links,
      };
    case "createNew":
      if (state.links.add) {
        return { ...state, targetItem: {} };
      }
      throw new Error("Add operation not allowed");

    case "edit":
      const newTargetItem = state.items.find(x => x.id === action.payload);
      if (newTargetItem.links.modify) {
        return { ...state, targetItem: newTargetItem };
      }

      throw new Error("Edit operation not allowed");


    case "saveInProgress":
      return { ...state, loading: true };
    case "createComplete":
      return {
        ...state, loading: false, targetItem: null, items: [...state.items, action.payload],
      };
    case "updateComplete":

      return {
        ...state, loading: false, targetItem: null, items: [...state.items.filter(o => o.id !== action.payload.id), action.payload],
      };


    case "cancelEdit":
      return { ...state, targetItem: null };

    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

export default (fetchItems, createItem, updateItem, params={}) => {
  const linksContext = useContext(LinksContext);
  const [{
    items, loading, targetItem, links,
  }, dispatch] = useReducer(manageDataReducer, { loading: true, items: [] });
  const currentMode = getCurrentMode(loading, targetItem);
  useEffect(() => {
    let cancel = false;
    if (linksContext.links) {
      fetchItems(linksContext, params).then((result) => {
        if (!cancel) {
          dispatch({ type: "setItems", payload: result });
        }
      });
    }
    return () => {
      cancel = true;
    };
  }, [fetchItems, linksContext]);

  const saveData = async (state) => {
    dispatch({ type: "saveInProgress" });
    if (!state.id) {
      const result = await createItem(linksContext, state);
      dispatch({ type: "createComplete", payload: result });
    } else {
      const result = await updateItem(linksContext, { ...targetItem, ...state });
      dispatch({ type: "updateComplete", payload: result });
    }
  };

  const canCreateItems = !!(links && links.add);
  const cancelEdit = () => dispatch({ type: "cancelEdit" });
  const startEdit = id => dispatch({ type: "edit", payload: id });
  const startCreate = canCreateItems && (() => dispatch({ type: "createNew" }));
  return {
    manage: {
      cancelEdit, startEdit, startCreate, saveData,
    },
    state: { currentMode, items, targetItem },
  };
};
