import React from 'react';
import fetch from 'fetch-hoc';
import { API_URL } from '../env';
import Loader from './Loader';

const PreLogin = ({ data }) => {
  if (data && data._links) {
    const url = API_URL + data._links.login.href;
    window.location = url;
  }
  return (<Loader />);
};

export default fetch(API_URL, { credentials: "include" })(PreLogin);
