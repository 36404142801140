import React from 'react';
import { Link } from 'react-router-dom';
import { openPopupWidget } from 'react-calendly';

import {
  PlusIcon, Level, LevelItem, LevelLeft,
} from "./styled";

export default ({ coach, profile }) => {
  if (coach && coach.calendlyId) {
    const onClick = () => openPopupWidget({ url: `https://calendly.com/${coach.calendlyId}/15min?email=${profile.emails[0].value}&name=${profile.displayName}` });

    return (
      <Level isMobile tag="div">
        <LevelLeft>
          <LevelItem><Link className="card-shadow button is-outlined"><PlusIcon alt="Book a quick chat"/></Link></LevelItem>
          <Link className="level-item has-text-grey-dark" onClick={onClick}>Book here for your 15 minute quick coaching call</Link>
        </LevelLeft>
      </Level>
    );
  } else {
    return (
      <Level isMobile tag="div">
        <LevelLeft>
          <LevelItem><Link className="card-shadow button is-outlined" to="/coachcall/adhoc/choose-a-time"><PlusIcon alt="Book a quick chat"/></Link></LevelItem>
          <Link to="/coachcall/adhoc/choose-a-time" className="level-item has-text-grey-dark">Book here for your 15 minute quick coaching call</Link>
        </LevelLeft>
      </Level>
    );
  }
};
