import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import {
  FullscreenBody,
  HeroNav, Hero, HeroFooter, HeroHeader,
  CloseButton, Container, Main,
  PrimaryTextButton,
  FixedHeaderBodyGlobal,
  FixedFooterBodyGlobal,
} from './styled';

import ButtonBar from './ButtonBar';
import DirectionalButtonBar from './DirectionalButtonBar';
import LinksContext from '../LinksContext';


export const FullscreenHeroActivityNav = ({
  showCloseButton, backToDashboardAction, logoutLink, children, lightHeader,
}) => (
  <Container verticalPadding={false}>
    <HeroNav>

      <div className={`navbar-menu ${lightHeader && "has-background-white"}`}>
        <div className="navbar-start">
          {children}
        </div>
        <div className="navbar-end">
          {showCloseButton && (
          <div className="navbar-item">
            <CloseButton onClick={backToDashboardAction} />
          </div>
          )}
          {!showCloseButton && logoutLink && (
          <div className="navbar-item">
            <PrimaryTextButton size="small" href={logoutLink}>Log out</PrimaryTextButton>
          </div>
          )}
        </div>
      </div>

    </HeroNav>
  </Container>
);
FullscreenHeroActivityNav.propTypes = {
  showCloseButton: PropTypes.bool,
  backToDashboardAction: PropTypes.func.isRequired,
  logoutLink: PropTypes.string,
  className: PropTypes.string,
};
FullscreenHeroActivityNav.defaultProps = {
  showCloseButton: true,
  logoutLink: undefined,
  className: undefined,
};

export const FullscreenHeroActivityFooterControls = withRouter((
  { children, directional },
) => {
  if (directional) {
    return (
      <DirectionalButtonBar>
        {children}
      </DirectionalButtonBar>
    );
  }

  return (
    <ButtonBar>
      {children}
    </ButtonBar>
  );
});

FullscreenHeroActivityFooterControls.defaultProps = {
  directional: false,
};

const FullscreenHero = ({
  history,
  children, headerRender, footerRender,
  showCloseButton, className, headerClassName,
}) => {
  const linksContext = useContext(LinksContext);
  const links = linksContext && linksContext.links;

  const headerAndFooterProps = {
    showCloseButton,
    logoutLink: links && links.logout && links.logout.href,
    backToDashboardAction: () => history.push("/"),
  };
  return (
    <Hero className={className}>
      <FixedFooterBodyGlobal />
      <FixedHeaderBodyGlobal />
      <HeroHeader className={`${headerClassName} is-fixed-top `}>
        {headerRender(headerAndFooterProps)}
      </HeroHeader>
      <FullscreenBody>
        <Main>
          {children}
        </Main>
      </FullscreenBody>
      {footerRender && (
      <HeroFooter className="is-fixed-bottom has-background-white">
        {footerRender(headerAndFooterProps)}
      </HeroFooter>
      )}
    </Hero>
  );
};

FullscreenHero.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  headerRender: PropTypes.func,
  footerRender: PropTypes.func,
  stickyFooter: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
};

FullscreenHero.defaultProps = {
  children: undefined,
  headerRender: () => (<></>),
  showCloseButton: true,
  stickyFooter: false,
  className: undefined,
};

export default withRouter(FullscreenHero);
