import React from 'react';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import { withRouter } from "react-router-dom";
import { fetchEmailLogs, createEmailLog } from '../../../api/emailLogs';
import useAdminDataManager from '../../../hooks/useAdminDataManager';
import {
  Section,
} from "../../../components/styled";
import {
  EmailLogDialog,
  AddEmailLogButton,
  EmailLogList,
} from "../../../components/pages/emailLogs";


export default withRouter(({ history, participantId }) => {
  const fakeUpdate = () => {};
  const { manage, state } = useAdminDataManager(fetchEmailLogs, createEmailLog, fakeUpdate, participantId ? { participantId } : {});
  const {
    cancelEdit, startEdit, startCreate, saveData,
  } = manage;

  const { currentMode, items, targetItem } = state;
  const renderByMode = {
    loading: () => <Loader />,
    editing: () => <EmailLogDialog onCancel={cancelEdit} onSave={saveData} initialState={targetItem} participantId={participantId} />,
    listing: () => (
      <>

        {startCreate && (
          <Section>
            <AddEmailLogButton onClick={startCreate} />
          </Section>
        )}

        <Section>
          <EmailLogList emailLogs={_.orderBy(items, org => (org.createdAt || "").toUpperCase()).reverse()} onEdit={startEdit} history={history} isAdmin={false} />
        </Section>
      </>
    ),
  };
  return (
    <>
      <h2> Email Logs </h2>
      {renderByMode[currentMode]()}
    </>

  );
});
