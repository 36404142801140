import Lottie from 'react-lottie';
import styled from 'styled-components';

import animationData from './BrainLoaderData.json';

const BrainLoader = styled(Lottie)
  .attrs({
    animationData,
    options: {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: { preserveAspectRatio: 'xMidYMid meet' },
    },
  })``;

export default BrainLoader;
