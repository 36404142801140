import React from 'react';
import fetch from 'fetch-hoc';
import { withRouter } from "react-router-dom";
import { API_URL } from '../../env';
import PopulatedExercise from "../exercises/PopulatedExercise";

const Exercise = ({
  loading, match, data, participant,
}) => {
  if (loading) {
    return <div>LOADING</div>;
  }

  return (
    <PopulatedExercise
      exerciseId={data.exerciseId}
      activityId={match.params.exerciseId}
      exerciseSubmission={data.exerciseSubmission || { complete: false }}
      currentModuleNumber={participant.currentModuleId}
      isParticipant={false}
      isCoach={true}
    />
  );
};

export default withRouter(fetch(props => `${API_URL}/exercises/${props.match.params.exerciseId}`, { credentials: "include" })(Exercise));
