import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CoachMatchingWaitPanel from "./CoachMatchingWaitPanel";

import { assignCoachToParticipant } from '../api';

const CoachMatchingPanel = ({ onCoachMatched }) => {
  const [timeoutFinished, setTimeoutFinished] = useState(false);
  const [coachAssigned, setCoachAssigned] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutFinished(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [setTimeoutFinished]);

  useEffect(() => {
    const work = async () => {
      await assignCoachToParticipant();
      setCoachAssigned(true);
    };
    work();
  }, [setCoachAssigned]);


  useEffect(() => {
    if (timeoutFinished) {
      onCoachMatched();
    }
  }, [onCoachMatched, timeoutFinished, coachAssigned]);

  return (
    <>
      <CoachMatchingWaitPanel />
    </>
  );
};

CoachMatchingPanel.propTypes = {
  onCoachMatched: PropTypes.func.isRequired,
};

export default CoachMatchingPanel;
