import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const AutoSave = styled.div`
    font-size: 0.8rem;
`;

export const ExerciseHeader = ({ lastSaveTime }) => {
  const [currentTime, updateCurrentTime] = useState(moment()); // eslint-disable-line no-unused-vars
  useEffect(() => {
    const interval = setInterval(() => updateCurrentTime(moment()), 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      { lastSaveTime && (
        <AutoSave className="has-text-light">
          Last saved
            { " " }
            { lastSaveTime.fromNow()}

        </AutoSave>
      )
      }
    </>
  );
};
