import _ from 'lodash';
import { API_URL } from './env';

export const makeAbsoluteLinks = (links, endpointUrl) => {
  if (links && links._links) {
    return _.mapValues(links._links, // eslint-disable-line no-underscore-dangle
      link => ({ ...link, href: endpointUrl + link.href }));
  }
  return undefined;
};

export const objToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[ key ]));
  }
  return keyValuePairs.join('&');
}

const setInducted = () => fetch(`${API_URL}/coaches/my-induction`, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: "include",
  body: JSON.stringify({}),
});

export const assignCoachToParticipant = () => setInducted();

export const sendExerciseData = async (activityId, submission, finished = false, participant = false) => {
  if (participant) {
    fetch(`${API_URL}/exercises/my/${activityId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify({ exerciseSubmission: submission, finished }),
      },
    );
  } else {
    fetch(`${API_URL}/coach/activities/${activityId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify({ exerciseSubmission: submission, finished }),
      },
    );
  }
}

export const cancelCoachCall = coachCall => fetch(coachCall.links.bookingDetails.href, {
  method: "DELETE",

  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({
    bookingDetails: {
      cancelled: true,
    },
  }),
});

export const siteRootLinks = endpoint => fetch(endpoint, { credentials: "include" })
  .then(response => response.json())
  .then(data => ({
    userType: data.userType,
    links: makeAbsoluteLinks(data, endpoint),
  }));

export const updateActivity = (linksContext, activity) => fetch(`${linksContext.links.activities.href}/${activity.id}`, {
  method: "PUT",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({ activity }),
})
  .then(response => response.json())
  .then(result => result.activity);

export const fetchPrograms = linksContext => fetch(linksContext.links.programs.href, { credentials: "include" })
  .then(response => response.json())
  .then(response => ({
    items: response.programs,
  }));

export const fetchModules = linksContext => fetch(linksContext.links.modules.href, { credentials: "include" })
  .then(response => response.json())
  .then(response => ({
    items: response.modules,
  }));

export const fetchProfile = linksContext => fetch(linksContext.links.profile.href, { credentials: "include" })
  .then(response => response.json());

export const requestPasswordChange = linksContext => fetch(`${linksContext.links.profile.href}/change_password`, {
  method: "POST",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
});

export const requestParticipantPasswordChange = participant => fetch(`${participant.links.modify.href}/change_password`, {
  method: "POST",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
});
