import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import WelcomePanel from '../WelcomePanel';
import CoachMatchingPanel from '../CoachMatchingPanel';
import MeetYourCoachPanel from '../MeetYourCoachPanel';
import ParticipantDashboard from "./ParticipantDashboard"; // eslint-disable-line import/no-named-as-default
import { requestPasswordChange } from '../../api';
import LinksContext from '../../LinksContext';
import ToastContext from "../../ToastContext";

export const ParticipantInductionUnwrapped = ({ history, profileState }) => {
  const linksContext = useContext(LinksContext);
  const [showCoachMatching, setShowCoachMatching] = useState(false);
  const [doneMatching, setDoneMatching] = useState(false);
  const toast = useContext(ToastContext);

  if (doneMatching) {
    if (profileState.result.currentModuleNumber > 0) {
      return <ParticipantDashboard profileState={profileState} />
    }
    return <MeetYourCoachPanel onMatchingProcessFinished={() => history.push("/coachcall/initial")} />;
  }
  if (showCoachMatching) {
    return (
      <CoachMatchingPanel onCoachMatched={() => {
        setDoneMatching(true);
      }}
      />
    );
  }

  return (<WelcomePanel onGetStarted={() => { setShowCoachMatching(true); }} changePassword={() => { requestPasswordChange(linksContext); toast.push({ content: "Password change request sent. You should receive an email shortly." }); }} />);
};

ParticipantInductionUnwrapped.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};
export default withRouter(ParticipantInductionUnwrapped);
