import { useState, useEffect } from 'react';
// TODO: Find library version of this?
//  from https://usehooks.com/useScript/

const cachedScripts = [];

const createScriptTag = (src) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  return script;
};

const addCachedScript = (src) => { cachedScripts.push(src); };

const removeCachedScript = (src) => {
  const index = cachedScripts.indexOf(src);
  if (index >= 0) cachedScripts.splice(index, 1);
};

const addEventListeners = (script, { onScriptLoad, onScriptError }) => {
  script.addEventListener('load', onScriptLoad);
  script.addEventListener('error', onScriptError);
};

const removeEventListeners = (script, { onScriptLoad, onScriptError }) => {
  script.removeEventListener('load', onScriptLoad);
  script.removeEventListener('error', onScriptError);
};


export default function (src, { when } = { when: true }) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(
    () => {
      if (!when) return undefined;

      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({ loaded: true, error: false });
      }
      addCachedScript(src);

      const script = createScriptTag(src);

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({ loaded: true, error: false });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        removeCachedScript(src);
        script.remove();

        setState({ loaded: true, error: true });
      };

      addEventListeners(script, { onScriptLoad, onScriptError });

      // Add script to document body
      document.body.appendChild(script);

      // Remove event listeners on cleanup
      return () => {
        removeEventListeners(script, { onScriptLoad, onScriptError });
      };
    },
    [src, when], // Only re-run effect if script src changes
  );
  return [state.loaded, state.error];
}
