/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { Container } from '../../styled';
import FullscreenHero, { FullscreenHeroActivityNav } from '../../FullscreenHero';
import ResultsFooter from './ResultsFooter';

const SimpleHeader = styled.h3`
  &&& {
    margin-bottom: 4rem
    text-align: center;
  }
`;

export const EmptyExerciseResults = ({
  exerciseConfig,
  closeExercise,
  description,
}) => (
  <FullscreenHero
    showCloseButton
    headerRender={props => (
      <FullscreenHeroActivityNav {...props} lightHeader backToDashboardAction={closeExercise} />
    )}

    footerRender={_props => <ResultsFooter restart={null} close={closeExercise} />}
  >

    <Container>
      <SimpleHeader className="has-text-primary">
        {exerciseConfig.fields.title}
      </SimpleHeader>
      { description }
    </Container>
  </FullscreenHero>
);
