import React from 'react';
import { VideoIcon } from '../styled';

export default ({ coachCall }) => {
  let chatLink;
  if (coachCall.bookingDetails.event) {
    chatLink = coachCall.bookingDetails.event.location;
  } else {
    chatLink = `https://zoom.us/j/${coachCall.coach.ChatId}`;
  }
  return (
    <div className="columns is-mobile is-gapless">
      <div className="column" style={{ flex: 0, marginRight: '0.75em' }}>
        <VideoIcon />
      </div>
      <div className="column">
        <div className="body-bold">
          Click this link to join the call <strong>at the time and date shown above</strong>:
        </div>
        <a href={chatLink} rel="noopener noreferrer" className="has-text-primary">
          {chatLink}
        </a>
      </div>
    </div>
  );
};
