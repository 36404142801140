import { useState, useEffect, useContext } from 'react';
import LinksContext from '../LinksContext';

export default (fetchItems, params) => {
  const linksContext = useContext(LinksContext);
  const [state, setState] = useState({ loading: true, items: undefined });

  useEffect(() => {
    let cancel = false;
    if (linksContext.links) {
      setState({ loading: true });

      fetchItems(linksContext, params).then((result) => {
        if (!cancel) {
          // TODO: Some of our fetch return links and items, others just return an item. We should arrive on one standard, and simplify below.
          if (result.items) {
            setState({ loading: false, items: result.items });
          } else {
            setState({ loading: false, items: result });
          }
        }
      });
    }
    return () => {
      cancel = true;
    };
  }, [fetchItems, linksContext, params, linksContext.links]);
  // TODO: return array - easier for naming on destructure
  // TODO: Also change items => result in consuming code
  return { items: state.items, result: state.items, loading: state.loading };
};
