import React, { useContext, useReducer, useState } from 'react';
import _ from 'lodash';
import CompletedIcon from '../styled/completed.svg';

import Loader from '../Loader';
import { fetchOrganisations } from '../../api/organisations';
import { deleteCoach, fetchCoaches } from '../../api/coaches';
import {
  updateActivity,
  fetchPrograms,
  fetchModules,
} from '../../api';
import { updateParticipant } from '../../api/participants';
import { fetchActivitiesForParticipant } from '../../api/activities';
import useApiFetch from '../../hooks/useApiFetch';
import {
  Box,
  Field,
  Label,
  Control,
  Input,
  PrimaryButton,
  Columns,
  Column,
  Select,
  Image,
  PrimaryTextButton,
  CloseIcon,
  DownArrowIcon,
  UpArrowIcon,
  PlusIcon,
  Button,
} from "../styled";
import { PhoneNumberInput } from '../exercises/fields/PhoneNumberInput';
import ReactSelect from 'react-select';
import DashboardButton from '../DashboardButton';
import LinksContext from "../../LinksContext";
import ModalLayout from "../ModalLayout";
import moment from "moment";


export const ParticipantList = ({
                                  participants, loading, onEdit, history, isAdmin,
                                }) => {
  const [state, setState] = useState({ showDeleteId: '', showArchiveId: '', userInput: '', participants, filteredParticipants: participants, participantSearch: '' });
  if (loading) {
    return <Loader/>;
  }
  return (
    <>
      <h4 className="has-text-weight-bold">{`${participants.length} Participants`}</h4>

      <Field>
        <Label>Search by Name</Label>
        <Control>
          <Input placeholder="Participant name" value={state.participantSearch || ""} onChange={(e) => {
            const filteredPtpts = _.filter(state.participants, ptpt => ptpt.name.toLowerCase().includes(e.target.value.toLowerCase()));

            setState({ showDeleteId: '', showArchiveId: '', userInput: '', participants, filteredParticipants: filteredPtpts, participantSearch: e.target.value });
          }
          }
          />
        </Control>
      </Field>

      {_.sortBy(state.filteredParticipants, ptpt => ptpt.name.toLowerCase()).map((participant) => (
        <Box
          key={participant.id}
        >
          <Columns className="is-variable is-mobile is-0-mobile is-3-tablet is-3-desktop">
            <Column
              onClick={() => {
                history.push(`/participants/${participant.id}`);
              }}
            >
              <div className="body-bold" data-testid={`${participant.id}-name`}>{participant.name}</div>
              <h5>{participant.emailAddress}</h5>
              {participant.archivedAt ? <h5>Archived At: {moment(participant.archivedAt).format('DD/MM/YYYY HH:mm')}</h5> : ''}
            </Column>

            <Column
              isSize="narrow"
              onClick={() => {
                history.push(`/participants/${participant.id}`);
              }}
            >
              <h5 data-testid={`${participant.id}-organisation`}>{participant.organisation && participant.organisation.name}</h5>
            </Column>

            {participant.links.modify && (
              <Column isSize="narrow" className='modify-button-row'>
                <PrimaryTextButton data-testid={`${participant.id}-edit`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => onEdit(participant.id)} paddingless>EDIT</PrimaryTextButton>
                <PrimaryTextButton data-testid={`${participant.id}-delete`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => setState({ showDeleteId: participant.id, showArchiveId: '', userInput: '', participants, filteredParticipants: participants })} paddingless>DELETE</PrimaryTextButton>
                <PrimaryTextButton data-testid={`${participant.id}-archive`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => setState({ showDeleteId: '', showArchiveId: participant.id, userInput: '', participants, filteredParticipants: participants })} paddingless>{participant.archivedAt ? 'RESTORE' : 'ARCHIVE'}</PrimaryTextButton>
              </Column>
            )}

            {(participant.inductedAt) && (
              <Column isSize="narrow">
                <Image src={CompletedIcon} alt="Complete" isSize="18x18"/>
              </Column>
            )}

          </Columns>

          <ParticipantDeleteDialog showDeleteId={state.showDeleteId} userInput={state.userInput}
            setState={setState} participant={participant} participants={state.participants}/>
          <ParticipantArchiveDialog showArchiveId={state.showArchiveId} archiveValue={participant.archivedAt ? null : new Date()} userInput={state.userInput} setState={setState} participant={participant} participants={state.participants}/>
        </Box>
      ))}

    </>
  );
};

export const InviteParticipantButton = ({ onClick }) => (
  <DashboardButton dataTestId="participant-new" onClick={onClick} caption="Add a new participant" />
);

const isNameValid = state => (state.formState.name && state.formState.name.length > 0);
const isEmailValid = state => (state.formState.emailAddress && state.formState.emailAddress.length > 0);

const getInputColor = (modified, valid) => {
  if (!modified) {
    return undefined;
  }
  if (valid) {
    return "success";
  }
  return "danger";
};


const formReducer = (state, action) => {
  switch (action.type) {
    case "updateFormState":
      return {
        ...state,
        formState: { ...state.formState, ...action.payload },
        modified: _.union(state.modified, Object.keys(action.payload))
      };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

export const ParticipantDialog = ({ onCancel, onSave, initialState, isAdmin }) => {
  const organisations = useApiFetch(fetchOrganisations);
  const coaches = useApiFetch(fetchCoaches);
  const programs = useApiFetch(fetchPrograms);
  const modules = useApiFetch(fetchModules);

  const [state, dispatch] = useReducer(formReducer, { formState: initialState || {}, modified: [] });
  const { formState } = state;
  const nameInputColour = getInputColor(state.modified.includes("name"), isNameValid(state));
  const emailInputColour = getInputColor(state.modified.includes("emailAddress"), isEmailValid(state));
  const editMode = !!initialState.id;
  const isSubmitEnabled = editMode || (!!isNameValid(state) && !!isEmailValid(state));

  return (
    <Box>
      {isAdmin ? (
        <>
          <Field>
            <Label>Name</Label>
            <Control>
              <Input data-testid="form-name" placeholder="Participant name" isColor={nameInputColour} value={formState.name || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { name: e.target.value } })} />
            </Control>
          </Field>

          <Field>
            <Label>Email</Label>
            <Control>
              <Input data-testid="form-email" placeholder="Participant email" isColor={emailInputColour} value={formState.emailAddress || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { emailAddress: e.target.value } })} />
            </Control>
          </Field>

          <Field>
            <Label>Phone</Label>
            <Control>
              <PhoneNumberInput
                id="phone-input-participants"
                value={formState.phone || ""}
                placeholder="Participant phone"
                classes={`phone-input ${editMode && "is-static"}`}
                callback={(phone) => dispatch({ type: 'updateFormState', payload: { phone } })}
              />
            </Control>
          </Field>

          <Field>
            <Label>Coach</Label>
            <Control>
              <ReactSelect data-testid="form-coach-profile-id"
                aria-label="Coaches Select"
                options={_.flatten([{ name: '(none)', id: '' }, _.sortBy(coaches.items, (coach) => coach.name.toLowerCase())]).map((coach) => ({ value: coach.id, label: coach.name }))}
                onChange={(e) => dispatch({ type: 'updateFormState', payload: { coachProfileId: e.value } })}
                defaultValue={{ value: (formState.coachProfile || {}).id, label: (formState.coachProfile || {}).Name }}
                disabled={!coaches.items}
              />
            </Control>
          </Field>

          <Field>
            <Label>Organisation</Label>
            <Control>
              <ReactSelect data-testid="form-organisation-id"
                aria-label="Organisation Select"
                options={_.flatten([{ name: '(none)', id: '' }, _.sortBy(organisations.items, (org) => org.name.toLowerCase())]).map((org) => ({ value: org.id, label: org.name }))}
                onChange={(e) => dispatch({ type: 'updateFormState', payload: { organisationId: e.value } })}
                defaultValue={{ value: (formState.organisation || {}).id, label: (formState.organisation || {}).name }}
                disabled={!organisations.items}
              />
            </Control>
          </Field>
        </>
      )
        :
        (
          <>
          </>
        )
      }

      <Field>
        <Label>Programs</Label>
        <Control className="is-multiple">
          <Select onChange={e => {
            dispatch({
              type: 'updateFormState',
              payload: { enabledPrograms: Array.apply(null, e.target.options).filter(o => o.selected).map(o => o.value) }
            });
            dispatch({
              type: 'updateFormState',
              payload: { currentModuleId: _.sortBy(modules.items.filter((i) => i.programId === e.target.options[e.target.selectedIndex].value), (i) => i.position)[0].id }
            });
          }
          } value={formState.enabledPrograms} disabled={!programs.items} multiple={true}>
            {programs.items ? programs.items.map(program => <option value={program.id}
              key={program.id}>{program.title}</option>) : ''}
          </Select>
        </Control>
      </Field>

      <Field>
        <Label>Current Module ID</Label>
        <Control>
          <Select data-testid="form-current-module-id" onChange={e => dispatch({ type: 'updateFormState', payload: { currentModuleId: e.target.value } })} value={formState.currentModuleId} disabled={!modules.items}>
            {modules.items ? _.sortBy(modules.items.filter((i) => i.programId === (formState.enabledPrograms || [])[0]), (i) => i.position).map(module => <option value={module.id} key={module.id}>{module.title}</option>) : ''}
          </Select>
        </Control>
      </Field>

      <div className="buttons">
        <PrimaryButton isInverted onClick={onCancel}>Cancel</PrimaryButton>
        <PrimaryButton data-testid="form-submit" disabled={!isSubmitEnabled && "disabled"} onClick={() => onSave(formState)}>Save</PrimaryButton>
      </div>

      {(formState.coachProfileId && formState.id) ?
        <ModuleForm editMode={editMode} participant={formState}/> : ''}
    </Box>
  );
};

export const ModuleForm = ({ editMode, participant }) => {
  const linksContext = useContext(LinksContext);
  const { result } = useApiFetch(fetchActivitiesForParticipant, participant);
  const modules = useApiFetch(fetchModules);
  const [state, setState] = useState({ loading: true, disabledExercises: [], activitiesByModule: {} });

  if (editMode && result && result.length > 0) {
    const moduleTitles = {};
    modules.items.forEach((module) => {
      moduleTitles[module.id] = module.title;
    });
    const filteredActivities = result.filter((activity) => {
      return activity.type === 'Exercise';
    });
    let disabledExercises = filteredActivities.filter((activity) => {
      return activity.disabled;
    });
    const enabledExercises = filteredActivities.filter((activity) => {
      return !activity.disabled;
    });
    let activitiesByModule = _.groupBy(enabledExercises, activity => activity.moduleId);
    if (state.disabledExercises.length !== disabledExercises.length || Object.keys(activitiesByModule).length !== Object.keys(state.activitiesByModule).length) {
      setState({ activitiesByModule, disabledExercises });
    } else {
      activitiesByModule = state.activitiesByModule;
      disabledExercises = state.disabledExercises;
    }

    return (
      <>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            {state.disabledExercises.map(activity => (
              <Box key={activity.id}>
                {activity.title}
                <Button isOutlined onClick={async () => {
                  activity.disabled = false;
                  updateActivity(linksContext, activity);
                  _.remove(disabledExercises, e => e.id === activity.id);
                  activitiesByModule[activity.moduleId].push(activity);
                  setState({ activitiesByModule, disabledExercises });
                }
                } noHover className="card-shadow">
                  <PlusIcon/>
                </Button>
              </Box>
            ))}
          </div>
          <div style={{ width: '50%' }}>
            {
              participant.moduleOrder.map((key) => {
                return (
                  <Box key={key}>
                    <h4>
                      {(participant.moduleOrder.indexOf(key) <= 0) ?
                        ''
                        :
                        (<Button isOutlined onClick={() => {
                          const newOrder = participant.moduleOrder.slice(0, participant.moduleOrder.indexOf(key) - 1);
                          newOrder.push(key);
                          newOrder.push(participant.moduleOrder[participant.moduleOrder.indexOf(key) - 1]);
                          newOrder.push(...participant.moduleOrder.slice(participant.moduleOrder.indexOf(key) + 1));
                          participant.moduleOrder = newOrder;

                          updateParticipant(linksContext, participant);

                          setState({ loading: false, disabledExercises, activitiesByModule });
                        }} noHover className="card-shadow">
                          <UpArrowIcon/>
                        </Button>)
                      }

                      {(participant.moduleOrder.indexOf(key) >= participant.moduleOrder.length - 1) ?
                        ''
                        :
                        (<Button isOutlined onClick={() => {
                          const newOrder = participant.moduleOrder.slice(0, participant.moduleOrder.indexOf(key));
                          newOrder.push(participant.moduleOrder[participant.moduleOrder.indexOf(key) + 1]);
                          newOrder.push(key);
                          newOrder.push(...participant.moduleOrder.slice(participant.moduleOrder.indexOf(key) + 2));

                          participant.moduleOrder = newOrder;


                          updateParticipant(linksContext, participant);

                          setState({ loading: false, disabledExercises, activitiesByModule });
                        }} noHover className="card-shadow">
                          <DownArrowIcon/>
                        </Button>)
                      }


                      {moduleTitles[(key || '').toString()]}
                    </h4>
                    {_.sortBy(state.activitiesByModule[key], activity => activity.sequenceInModule).map(activity => (
                      <Box key={activity.id}>
                        <Select onChange={e => { activity.moduleId = e.target.value; updateActivity(linksContext, activity); setState({ loading: false, disabledExercises: [], activitiesByModule: {} }); } } value={activity.moduleId}>
                          {modules.items.map((module) => <option value={module.id} key={module.id}>{module.title}</option>) }
                        </Select> {activity.title}

                        <Button isOutlined onClick={() => {
                          activity.disabled = true;
                          updateActivity(linksContext, activity);
                          disabledExercises.push(activity);
                          _.remove(activitiesByModule[activity.moduleId], e => e.id === activity.id);
                          setState({ loading: false, disabledExercises, activitiesByModule });
                        }
                        } noHover className="card-shadow">
                          <CloseIcon/>
                        </Button>

                        {(activity.sequenceInModule <= 1 || activitiesByModule[key].length === 1) ?
                          ''
                          :
                          (<Button isOutlined onClick={() => {
                            const moduleActivities = _.sortBy(activitiesByModule[activity.moduleId], activity => activity.sequenceInModule);
                            const oldIndex = moduleActivities.indexOf(activity);
                            const newIndex = oldIndex - 1;
                            const movedItem = moduleActivities.find((item, index) => index === oldIndex);
                            const remainingItems = moduleActivities.filter((item, index) => index !== oldIndex);

                            const reorderedItems = [
                              ...remainingItems.slice(0, newIndex),
                              movedItem,
                              ...remainingItems.slice(newIndex),
                            ];

                            reorderedItems.forEach((item, index) => {
                              item.sequenceInModule = index + 1;
                              updateActivity(linksContext, item);
                            });

                            setState({ loading: false, disabledExercises, activitiesByModule });
                          }} noHover className="card-shadow">
                            <UpArrowIcon/>
                          </Button>)
                        }

                        {(activity.sequenceInModule >= activitiesByModule[key].length || activitiesByModule[key].length === 1) ?
                          ''
                          :
                          (<Button isOutlined onClick={() => {
                            const moduleActivities = _.sortBy(activitiesByModule[activity.moduleId], activity => activity.sequenceInModule);
                            const oldIndex = moduleActivities.indexOf(activity);
                            const newIndex = oldIndex + 1;
                            const movedItem = moduleActivities.find((item, index) => index === oldIndex);
                            const remainingItems = moduleActivities.filter((item, index) => index !== oldIndex);

                            const reorderedItems = [
                              ...remainingItems.slice(0, newIndex),
                              movedItem,
                              ...remainingItems.slice(newIndex),
                            ];

                            reorderedItems.forEach((item, index) => {
                              item.sequenceInModule = index + 1;
                              updateActivity(linksContext, item);
                            });

                            setState({ loading: false, disabledExercises, activitiesByModule });
                          }} noHover className="card-shadow">
                            <DownArrowIcon/>
                          </Button>)
                        }
                      </Box>
                    ))}
                  </Box>
                );
              })
            }
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div/>
      </>
    );
  }
}

export const ParticipantDeleteDialog = ({ participants, participant, showDeleteId, userInput, setState }) => {
  const linksContext = useContext(LinksContext);

  if (showDeleteId === participant.id) {
    return (
      <ModalLayout>
        <h4>Are you sure you want to delete {participant.emailAddress}?</h4>
        <p>Enter the name below to confirm</p>

        <Field>
          <Control>
            <Input data-testid="form-email" placeholder="Participant email" value={userInput || ""} onChange={e => (setState({ userInput: e.target.value, showDeleteId: participant.id, showArchiveId: '', participants, filteredParticipants: participants }))} />
          </Control>
        </Field>

        <div className="buttons">
          <PrimaryButton isInverted onClick={() => setState({ showDeleteId: '', showArchiveId: '', userInput: '', participants, filteredParticipants: participants })}>Cancel</PrimaryButton>
          <PrimaryButton data-testid="form-submit" onClick={async () => {
            if (participant.emailAddress.toLowerCase() === userInput.toLowerCase()) {
              _.remove(participants, e => e.id === participant.id);
              await deleteCoach(linksContext, participant);
              setState({ showDeleteId: '', showArchiveId: '', userInput: '', participants, filteredParticipants: participants });
            }
          }}>
            Delete
          </PrimaryButton>
        </div>
      </ModalLayout>
    );
  } else {
    return "";
  }
};

export const ParticipantArchiveDialog = ({ participants, participant, archiveValue, showArchiveId, userInput, setState }) => {
  const linksContext = useContext(LinksContext);

  if (showArchiveId === participant.id) {
    return (
      <ModalLayout>
        <h4>Are you sure you want to {archiveValue === null ? 'restore' : 'archive'} {participant.emailAddress}?</h4>
        <p>Enter the name below to confirm</p>

        <Field>
          <Control>
            <Input data-testid="form-email" placeholder="Participant email" value={userInput || ""} onChange={e => (setState({ userInput: e.target.value, showArchiveId: participant.id, showDeleteId: '', participants, filteredParticipants: participants }))} />
          </Control>
        </Field>

        <div className="buttons">
          <PrimaryButton isInverted onClick={() => setState({ showArchiveId: '', showDeleteId: '', userInput: '', participants, filteredParticipants: participants })}>Cancel</PrimaryButton>
          <PrimaryButton data-testid="form-submit" onClick={async () => {
            if (participant.emailAddress.toLowerCase() === userInput.toLowerCase()) {
              _.remove(participants, e => e.id === participant.id);
              participant.archivedAt = archiveValue;
              await updateParticipant(linksContext, participant);
              setState({ showDeleteId: '', showArchiveId: '', userInput: '', participants, filteredParticipants: participants });
            }
          }}>
            {archiveValue === null ? 'Restore' : 'Archive'}
          </PrimaryButton>
        </div>
      </ModalLayout>
    );
  } else {
    return "";
  }
};
