import React, { useContext } from 'react';

import {
  Route, Switch, NavLink, withRouter, Redirect,
} from "react-router-dom";
import LinksContext from '../LinksContext';
import DashboardLayout from './DashboardLayout';
import NotFound from './NotFound';
import Participants from '../routes/coaches/participants/index';
import ParticipantView from './pages/participantView';
import OrganisationView from '../routes/coaches/organisations/show';
import ExerciseView from './pages/exerciseView';
import Organisations from '../routes/coaches/organisations/index';
import EmailLogs from '../routes/coaches/email_logs/index';
import { Container } from "./styled";
import { requestPasswordChange } from "../api";
import ToastContext from "../ToastContext";

const AdminNav = withRouter(({ match, linksContext }) => {
  const toast = useContext(ToastContext);

  return (
    <>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}participants`}>Your Participants</NavLink>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}organisations`}>Organisations</NavLink>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}email_logs`}>Email Logs</NavLink>
      <a className="navbar-item" href="https://calendly.com/app/scheduled_events/user/me" target="_blank" rel="noopener noreferrer">Calendar</a>
      <NavLink activeClassName="is-active" className="navbar-item" to="/" onClick={() => { requestPasswordChange(linksContext); toast.push({ content: "Password change request sent. You should receive an email shortly." }); }}>Request Change Password</NavLink>
    </>
  );
});

export default ({ match }) => {
  const linksContext = useContext(LinksContext);
  const { links } = useContext(LinksContext);
  if (!(links.participants || links.organisations || links.coaches)) {
    return (<NotFound />);
  }
  return (
    <DashboardLayout additionalNavbarItems={() => <AdminNav linksContext={linksContext} />}>
      <Container>
        <Switch>
          <Route exact path="/">
            <Redirect to="/organisations" />
          </Route>
          {links.emailLogs && <Route path={`${match.path}email_logs`} exact render={() => (<EmailLogs />)} />}
          {links.participants && <Route path={`${match.path}participants/:participantId/email_logs`} exact render={({ match: innerMatch }) => (<EmailLogs participantId={innerMatch.params.participantId} />)} />}
          {links.organisations && <Route path={`${match.path}organisations`} exact render={() => (<Organisations />)} />}
          {links.participants && <Route path={`${match.path}organisations/:id`} exact render={({ match: innerMatch }) => (<OrganisationView id={innerMatch.params.id} isAdmin={false} />)} />}
          {links.participants && <Route path={`${match.path}participants`} exact render={() => (<Participants participantsUrl={links.participants.href} />)} />}
          {links.participants && <Route path={`${match.path}participants/:id`} exact render={({ match: innerMatch }) => (<ParticipantView id={innerMatch.params.id} />)} />}
          {links.participants && <Route path={`${match.path}participants/:participantId/exercise/:exerciseId`} exact render={({ match: innerMatch }) => (<ExerciseView participantId={innerMatch.params.participantId} exerciseId={innerMatch.params.exerciseId} />)} />}
          <Route component={NotFound} />
        </Switch>
      </Container>
    </DashboardLayout>
  );
};
