import React from 'react';
import {
  Columns, Column, Container,
} from "./styled";


export default ({ ColumnOneComponent, ColumnTwoComponent }) => (
  <Container isWider>
    <Columns className="isMobile">
      <Column>
        {ColumnOneComponent && <ColumnOneComponent />}
      </Column>
      <Column>
        {ColumnTwoComponent && <ColumnTwoComponent />}
      </Column>
    </Columns>
  </Container>
);
