import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// NB: For some last-minute speed, this code is extracted from a design tool called sketch
const InductionPanel = styled.div`
.bp2-participantintrohometabletdesktop {
  width               : 100%;
  min-width           : 768px;
  height              : 100vh;
  min-height          : 2066px;
  position            : relative;
  overflow            : hidden;
  margin              : 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color    : rgba(250, 250, 250, 1.0);
}
div {
  -webkit-text-size-adjust: none;
}
.bp2-participantintrohometabletdesktop .bp2-rectangle {
  background-color    : rgba(159, 221, 159, 1.0);
  top                 : 1134px;
  height              : 932px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-howitworks {
  background-color    : rgba(255,255,255,0.0);
  width               : auto;
  height              : auto;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 600;
  font-style          : normal;
  font-size           : 31.99px;
  color               : rgba(43, 70, 219, 1.0);
  text-align          : left;
  line-height         : 48.0px;
}
.bp2-participantintrohometabletdesktop .bp2-howitworks-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 1177px;
  align-items         : center;
  height              : auto;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent {
  background-color    : rgba(255,255,255,0.0);
  top                 : 1266px;
  height              : 734px;
  width               : 685px;
  position            : absolute;
  margin              : 0;
  left                : calc(50.00% - 342px);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-buttonprimary {
  background-color    : rgba(255,255,255,0.0);
  top                 : 638px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 338px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-buttonprimary .bp2-paddinggroup2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-buttonprimary .bp2-paddinggroup2 .bp2-shadow {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-buttonprimary .bp2-paddinggroup2 .bp2-background {
  background-color    : rgba(255,255,255,0.0);
  top                 : 10px;
  height              : 36px;
  width               : 150px;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-buttonprimary .bp2-paddinggroup2 .bp2-getstarted {
  background-color    : rgba(255,255,255,0.0);
  top                 : 16px;
  height              : auto;
  width               : auto;
  position            : absolute;
  margin              : 0;
  left                : 42px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-buttonprimary .bp2-iconfill {
  background-color    : rgba(255,255,255,0.0);
  top                 : 22px;
  height              : 12px;
  width               : 20px;
  position            : absolute;
  margin              : 0;
  left                : 149px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-phone {
  background-color    : rgba(255,255,255,0.0);
  top                 : 30px;
  height              : 663px;
  width               : 330px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-phone .bp2-pcfront {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 663px;
  width               : 330px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-phone .bp2-updatedtext {
  background-color    : rgba(255,255,255,0.0);
  top                 : 297px;
  height              : 20px;
  width               : 214px;
  position            : absolute;
  margin              : 0;
  left                : 68px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-phone .bp2-updatedtext .bp2-rectangle1 {
  background-color    : rgba(248, 251, 253, 1.0);
  top                 : 0px;
  height              : 20px;
  width               : 214px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-phone .bp2-updatedtext .bp2-rateyourworkenvir {
  background-color    : rgba(255,255,255,0.0);
  top                 : 1px;
  height              : auto;
  width               : auto;
  position            : absolute;
  margin              : 0;
  left                : 3px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 700;
  font-style          : normal;
  font-size           : 13.0px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : left;
  line-height         : 20.0px;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a01 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 169px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 336px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a01 .bp2-rectangle1 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 169px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a01 .bp2-oneononevideocoa {
  background-color    : rgba(255,255,255,0.0);
  width               : 281px;
  height              : auto;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a01 .bp2-oneononevideocoa-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 26px;
  align-items         : center;
  height              : auto;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a02 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 121px;
  height              : 209px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 336px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a02 .bp2-rectanglecopy2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 209px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a02 .bp2-peeplcoachu2019sinterac {
  background-color    : rgba(255,255,255,0.0);
  top                 : 22px;
  height              : auto;
  width               : 273px;
  position            : absolute;
  margin              : 0;
  left                : 54px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a03 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 282px;
  height              : 160px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 336px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a03 .bp2-rectanglecopy2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 160px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a03 .bp2-onlinechatswithyo {
  background-color    : rgba(255,255,255,0.0);
  top                 : 26px;
  height              : auto;
  width               : 271px;
  position            : absolute;
  margin              : 0;
  left                : 54px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a04 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 394px;
  height              : 183px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 336px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a04 .bp2-rectanglecopy2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 183px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a04 .bp2-urgentcoachingsess {
  background-color    : rgba(255,255,255,0.0);
  top                 : 24px;
  height              : auto;
  width               : 284px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a05 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 529px;
  height              : 137px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 336px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a05 .bp2-rectanglecopy2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 137px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-bottomcontent .bp2-a05 .bp2-monthlycareerdevel {
  background-color    : rgba(255,255,255,0.0);
  top                 : 26px;
  height              : auto;
  width               : 284px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent {
  background-color    : rgba(255,255,255,0.0);
  top                 : 96px;
  height              : 978px;
  width               : 600px;
  position            : absolute;
  margin              : 0;
  left                : calc(50.00% - 300px);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 {
  background-color    : rgba(255,255,255,0.0);
  width               : 339px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 348px;
  align-items         : center;
  height              : 299px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group22copy3 {
  background-color    : rgba(255,255,255,0.0);
  width               : 256px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(10deg); /* IE 9 */
  -webkit-transform   : rotate(10deg); /* Chrome, Safari, Opera */
  transform           : rotate(10deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group22copy3-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 21px;
  align-items         : center;
  height              : 205px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group22copy3 .bp2-fill1 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 1px;
  height              : 77px;
  width               : 93px;
  position            : absolute;
  margin              : 0;
  left                : 46px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group22copy3 .bp2-fill7 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 139px;
  height              : 66px;
  width               : 108px;
  position            : absolute;
  margin              : 0;
  left                : 1px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  opacity             : 0.5;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group22copy3 .bp2-fill9 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 132px;
  height              : 51px;
  width               : 25px;
  position            : absolute;
  margin              : 0;
  left                : 231px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group22copy3 .bp2-fill11 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 83px;
  height              : 36px;
  width               : 38px;
  position            : absolute;
  margin              : 0;
  left                : 34px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group {
  background-color    : rgba(255,255,255,0.0);
  width               : 339px;
  margin-right        : -0px;
  height              : 100%;
  pointer-events      : auto;
  margin-left         : 0px;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 45px;
  align-items         : center;
  height              : 247px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-group9 .bp2-group .bp2-group3 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 247px;
  width               : 343px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-welcometothepeepl {
  background-color    : rgba(255,255,255,0.0);
  top                 : 164px;
  height              : auto;
  width               : 600px;
  position            : absolute;
  margin              : 0;
  left                : calc(50.00% - 300px);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 20.0px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : center;
  line-height         : 30.0px;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-researchshowsthat {
  background-color    : rgba(255,255,255,0.0);
  top                 : 660px;
  height              : auto;
  width               : 600px;
  position            : absolute;
  margin              : 0;
  left                : calc(50.00% - 300px);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-ondemandcareercoa {
  background-color    : rgba(255,255,255,0.0);
  pointer-events      : auto;
  height              : auto;
  width               : 600px;
  max-width           : 600px;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 600;
  font-style          : normal;
  font-size           : 31.25px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : center;
  line-height         : 47.0px;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-ondemandcareercoa-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 0px;
  align-items         : center;
  height              : auto;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary {
  background-color    : rgba(255,255,255,0.0);
  width               : 210px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 270px;
  align-items         : center;
  height              : 96px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary .bp2-paddinggroup2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary .bp2-paddinggroup2 .bp2-shadow {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary .bp2-paddinggroup2 .bp2-background {
  background-color    : rgba(255,255,255,0.0);
  top                 : 10px;
  height              : 36px;
  width               : 150px;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary .bp2-paddinggroup2 .bp2-getstarted {
  background-color    : rgba(255,255,255,0.0);
  top                 : 16px;
  height              : auto;
  width               : auto;
  position            : absolute;
  margin              : 0;
  left                : 42px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp2-participantintrohometabletdesktop .bp2-topcontent .bp2-buttonprimary .bp2-iconfill {
  background-color    : rgba(255,255,255,0.0);
  top                 : 22px;
  height              : 12px;
  width               : 20px;
  position            : absolute;
  margin              : 0;
  left                : 149px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
/* .anima-animate-appear prefix for appear animations */
.anima-animate-appear {
  opacity             : 0;
  display             : block;
  -webkit-animation   : anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation      : anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation        : anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation           : anima-reveal 0.3s ease-in-out 1 normal forwards;
}
.anima-animate-disappear {
  opacity             : 1;
  display             : block;
  -webkit-animation   : anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation      : anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation        : anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation           : anima-reveal 0.3s ease-in-out 1 reverse forwards;
}
.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay  : 0;
  animation-delay     : 0;
}
@-webkit-keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@-moz-keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@-o-keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
.bp1-participantintrohomemobile {
  width               : 100%;
  min-width           : 375px;
  height              : 100vh;
  min-height          : 2811px;
  position            : relative;
  overflow            : hidden;
  margin              : 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color    : rgba(250, 250, 250, 1.0);
}
a {
  text-decoration     : none;
}
div {
  -webkit-text-size-adjust: none;
}
.anima-full-width-a {
  width               : 100%;
}
.anima-full-height-a {
  height              : 100%;
}
div {
  -webkit-text-size-adjust: none;
}
.bp1-participantintrohomemobile .bp1-rectangle {
  background-color    : rgba(159, 221, 159, 1.0);
  top                 : 1609px;
  height              : 1314px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 {
  background-color    : rgba(255,255,255,0.0);
  width               : 379px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 1647px;
  align-items         : center;
  height              : 1534px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-pcfront {
  background-color    : rgba(255,255,255,0.0);
  position            : absolute;
  top                 : 921px;
  left                : 37px;
  margin              : 0;
  width               : 305px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-howitworks {
  background-color    : rgba(255,255,255,0.0);
  width               : auto;
  height              : auto;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 600;
  font-style          : normal;
  font-size           : 25.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : left;
  line-height         : 38.0px;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-howitworks-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 0px;
  align-items         : center;
  height              : auto;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-buttonprimarycopy {
  background-color    : rgba(255,255,255,0.0);
  top                 : 831px;
  height              : 96px;
  width               : 150px;
  position            : absolute;
  margin              : 0;
  left                : 85px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-buttonprimarycopy .bp1-paddinggroup2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-buttonprimarycopy .bp1-paddinggroup2 .bp1-shadow {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-buttonprimarycopy .bp1-paddinggroup2 .bp1-background {
  background-color    : rgba(255,255,255,0.0);
  top                 : 10px;
  height              : 36px;
  width               : 150px;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-buttonprimarycopy .bp1-paddinggroup2 .bp1-getstarted {
  background-color    : rgba(255,255,255,0.0);
  top                 : 16px;
  height              : auto;
  width               : 92px;
  position            : absolute;
  margin              : 0;
  left                : 43px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-buttonprimarycopy .bp1-iconfill {
  background-color    : rgba(255,255,255,0.0);
  top                 : 22px;
  height              : 12px;
  width               : 20px;
  position            : absolute;
  margin              : 0;
  left                : 149px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-coach {
  background-color    : rgba(255,255,255,0.0);
  top                 : 64px;
  height              : 256px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-coach .bp1-rectangle2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 23px;
  height              : 233px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-coach .bp1-oneononevideocoa {
  background-color    : rgba(255,255,255,0.0);
  top                 : 113px;
  height              : auto;
  width               : 286px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-coach .bp1-rectangle1 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 148px;
  width               : 148px;
  position            : absolute;
  margin              : 0;
  left                : 116px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group {
  background-color    : rgba(255,255,255,0.0);
  width               : 379px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 284px;
  align-items         : center;
  height              : 188px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group .bp1-rectanglecopy22 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 188px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group .bp1-peeplcoachu2019sinterac {
  background-color    : rgba(255,255,255,0.0);
  top                 : 26px;
  height              : auto;
  width               : 286px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-rectanglecopy2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 561px;
  height              : 184px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-urgentcoachingsess {
  background-color    : rgba(255,255,255,0.0);
  top                 : 587px;
  height              : auto;
  width               : 284px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group5 {
  background-color    : rgba(255,255,255,0.0);
  width               : 379px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group5-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 436px;
  align-items         : center;
  height              : 161px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group5 .bp1-rectanglecopy22 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 161px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-group5 .bp1-onlinechatswithyo {
  background-color    : rgba(255,255,255,0.0);
  top                 : 26px;
  height              : auto;
  width               : 284px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-rectanglecopy21 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 709px;
  height              : 138px;
  width               : 379px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group6 .bp1-monthlycareerdevel {
  background-color    : rgba(255,255,255,0.0);
  top                 : 735px;
  height              : auto;
  width               : 284px;
  position            : absolute;
  margin              : 0;
  left                : 48px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(43, 71, 219, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group4 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 96px;
  height              : 569px;
  width               : 85.07%;
  position            : absolute;
  margin              : 0;
  left                : 7.47%;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-welcometothepeepl {
  background-color    : rgba(255,255,255,0.0);
  top                 : 283px;
  height              : auto;
  width               : 319px;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 20.0px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : left;
  line-height         : 30.0px;
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-ondemandcareercoa {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : auto;
  width               : 319px;
  max-width           : 600px;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 600;
  font-style          : normal;
  font-size           : 31.25px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : left;
  line-height         : 47.0px;
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-buttonprimary {
  background-color    : rgba(255,255,255,0.0);
  top                 : 473px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-buttonprimary .bp1-paddinggroup2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-buttonprimary .bp1-paddinggroup2 .bp1-shadow {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 96px;
  width               : 210px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-buttonprimary .bp1-paddinggroup2 .bp1-background {
  background-color    : rgba(255,255,255,0.0);
  top                 : 10px;
  height              : 36px;
  width               : 150px;
  position            : absolute;
  margin              : 0;
  left                : 30px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-buttonprimary .bp1-paddinggroup2 .bp1-getstarted {
  background-color    : rgba(255,255,255,0.0);
  top                 : 16px;
  height              : auto;
  width               : auto;
  position            : absolute;
  margin              : 0;
  left                : 42px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(255, 255, 255, 1.0);
  text-align          : center;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group4 .bp1-buttonprimary .bp1-iconfill {
  background-color    : rgba(255,255,255,0.0);
  top                 : 22px;
  height              : 12px;
  width               : 20px;
  position            : absolute;
  margin              : 0;
  left                : 149px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-researchshowsthat {
  background-color    : rgba(255,255,255,0.0);
  top                 : 1022px;
  height              : auto;
  width               : 85.07%;
  position            : absolute;
  margin              : 0;
  left                : 7.47%;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
  font-family         : "Poppins", Helvetica, Arial, serif;
  font-weight         : 400;
  font-style          : normal;
  font-size           : 16.0px;
  color               : rgba(45, 49, 63, 1.0);
  text-align          : left;
  line-height         : 25.0px;
}
.bp1-participantintrohomemobile .bp1-group2 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 658px;
  height              : 326px;
  width               : 307px;
  position            : absolute;
  margin              : 0;
  left                : calc(50.00% - 154px);
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy3 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 21px;
  height              : 202px;
  width               : 259px;
  position            : absolute;
  margin              : 0;
  left                : 35px;
  -ms-transform       : rotate(-10deg); /* IE 9 */
  -webkit-transform   : rotate(-10deg); /* Chrome, Safari, Opera */
  transform           : rotate(-10deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy3 .bp1-fill1 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 77px;
  width               : 94px;
  position            : absolute;
  margin              : 0;
  left                : 80px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy3 .bp1-fill7 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 136px;
  height              : 66px;
  width               : 109px;
  position            : absolute;
  margin              : 0;
  left                : 1px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy3 .bp1-fill9 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 129px;
  height              : 51px;
  width               : 25px;
  position            : absolute;
  margin              : 0;
  left                : 233px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy3 .bp1-fill11 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 80px;
  height              : 36px;
  width               : 38px;
  position            : absolute;
  margin              : 0;
  left                : 34px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group3 {
  background-color    : rgba(255,255,255,0.0);
  width               : 346px;
  height              : 100%;
  pointer-events      : auto;
  position            : relative;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group3-layout-container {
  pointer-events      : none;
  flex-direction      : column;
  top                 : 71px;
  align-items         : center;
  height              : 247px;
  width               : 100%;
  position            : absolute;
  margin              : 0;
  display             : flex;
  left                : 0;
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy4 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 238px;
  height              : 37px;
  width               : 39px;
  position            : absolute;
  margin              : 0;
  left                : 175px;
  -ms-transform       : rotate(140deg); /* IE 9 */
  -webkit-transform   : rotate(140deg); /* Chrome, Safari, Opera */
  transform           : rotate(140deg);
}
.bp1-participantintrohomemobile .bp1-group2 .bp1-group22copy4 .bp1-fill11 {
  background-color    : rgba(255,255,255,0.0);
  top                 : 0px;
  height              : 36px;
  width               : 38px;
  position            : absolute;
  margin              : 0;
  left                : 0px;
  -ms-transform       : rotate(0deg); /* IE 9 */
  -webkit-transform   : rotate(0deg); /* Chrome, Safari, Opera */
  transform           : rotate(0deg);
}
/* .anima-animate-appear prefix for appear animations */
.anima-animate-appear {
  opacity             : 0;
  display             : block;
  -webkit-animation   : anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation      : anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation        : anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation           : anima-reveal 0.3s ease-in-out 1 normal forwards;
}
.anima-animate-disappear {
  opacity             : 1;
  display             : block;
  -webkit-animation   : anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation      : anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation        : anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation           : anima-reveal 0.3s ease-in-out 1 reverse forwards;
}
.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay  : 0;
  animation-delay     : 0;
}
@-webkit-keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@-moz-keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@-o-keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@keyframes anima-reveal {
  0%

  {
      opacity: 0;
    }


  100%

  {
      opacity: 1;
    }
}
@media screen and (max-width: 767px) {
  .bp1-participantintrohomemobile {
    display             : block;
  }
  .bp2-participantintrohometabletdesktop {
    display             : none;
  }
}
@media screen and (min-width: 768px) {
  .bp1-participantintrohomemobile {
    display             : none;
  }
  .bp2-participantintrohometabletdesktop {
    display             : block;
  }
}

.bp2-background, .bp2-getstarted, .bp2-iconfill, bp1-background, .bp1-getstarted, .bp1-iconfill {
  cursor: pointer;
}
`;

const WelcomePanel = ({ onGetStarted, changePassword }) => (
  <InductionPanel>
    <input id="anPageName" name="page" type="hidden" value="participantintrohomemobile"/>
    <div className="bp2-participantintrohometabletdesktop">
        <div className="bp2-rectangle">
        </div>
        <div className="bp2-howitworks-layout-container">
            <div className="bp2-howitworks">
                How it works
            </div>
        </div>
        <div className="bp2-bottomcontent">
            <div className="bp2-buttonprimary">
                <div className="bp2-paddinggroup2">
                    <img alt="" src="images/induction/participant-introhomemobile-shadow@2x.png" className="bp2-shadow"/>
                    <img alt="" src="images/induction/participant-introhometabletdesktop-background@2x.png" className="bp2-background"/>
                    <div onClick={onGetStarted} className="bp2-getstarted">
                        Get Started
                    </div>
                </div>
                <img alt="" src="images/induction/participant-introhometabletdesktop-icon-fill-1@2x.png" className="bp2-iconfill"/>
            </div>
            <div className="bp2-phone">
                <img alt="" src="images/induction/participant-introhometabletdesktop-pcfront@2x.png" className="bp2-pcfront"/>
                <div className="bp2-updatedtext">
                    <div className="bp2-rectangle1">
                    </div>
                    <div className="bp2-rateyourworkenvir">
                        Rate your work environment
                    </div>
                </div>
            </div>
            <div className="bp2-a01">
                <img alt="" src="images/induction/participant-introhometabletdesktop-rectangle.png" className="bp2-rectangle1"/>
                <div className="bp2-oneononevideocoa-layout-container">
                    <div className="bp2-oneononevideocoa">
                        One-on-one video coaching sessions every six weeks available anytime, anywhere
                    </div>
                </div>
            </div>
            <div className="bp2-a02">
                <img alt="" src="images/induction/participant-introhometabletdesktop-rectangle-copy-2.png" className="bp2-rectanglecopy2"/>
                <div className="bp2-peeplcoachu2019sinterac">
                    Peeplcoach’s interactive exercises that have been created to help you understand your career obstacles, strengths, opportunities and goals
                </div>
            </div>
            <div className="bp2-a03">
                <img alt="" src="images/induction/participant-introhometabletdesktop-rectangle-copy-2-1.png" className="bp2-rectanglecopy2"/>
                <div className="bp2-onlinechatswithyo">
                    Online chats with your Master Coach to get expert advice in the moment
                </div>
            </div>
            <div className="bp2-a04">
                <img alt="" src="images/induction/participant-introhometabletdesktop-rectangle-copy-2-2.png" className="bp2-rectanglecopy2"/>
                <div className="bp2-urgentcoachingsess">
                    Urgent coaching sessions with your Master Coach to deal with issues that can’t wait for your regularly scheduled session
                </div>
            </div>
            <div className="bp2-a05">
                <img alt="" src="images/induction/participant-introhometabletdesktop-rectangle-copy-2-3.png" className="bp2-rectanglecopy2"/>
                <div className="bp2-monthlycareerdevel">
                    Career development tips and tools
                </div>
            </div>
        </div>
        <div className="bp2-topcontent">
            <div className="bp2-group9-layout-container">
                <div className="bp2-group9">
                    <div className="bp2-group22copy3-layout-container">
                        <div className="bp2-group22copy3">
                            <img alt="" src="images/induction/participant-introhometabletdesktop-fill-1@2x.png" className="bp2-fill1"/>
                            <img alt="" src="images/induction/participant-introhometabletdesktop-fill-7@2x.png" className="bp2-fill7"/>
                            <img alt="" src="images/induction/participant-introhometabletdesktop-fill-9@2x.png" className="bp2-fill9"/>
                            <img alt="" src="images/induction/participant-introhometabletdesktop-fill-11@2x.png" className="bp2-fill11"/>
                        </div>
                    </div>
                    <div className="bp2-group-layout-container">
                        <div className="bp2-group">
                            <img alt="" src="images/induction/participant-introhometabletdesktop-group-3@2x.png" className="bp2-group3"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bp2-welcometothepeepl">
                Welcome to the Peeplcoach Program. Peeplcoach has been created to help you take control of and build a fulfilling and successful career that will make you happy.

                <h5>Please ensure you have updated your password to something secure. <span style={{color: 'blue', cursor: 'pointer'}} onClick={changePassword}>Click here</span> to request a password change email.</h5>
            </div>
            <div className="bp2-researchshowsthat">
                Research shows that individuals who utilise bespoke coaching programs feel more confident, have greater clarity around their career goals, are happier and generally, more successful.<br /><br />Peeplcoach Master Coaches are experienced coaches and industry experts who have been chosen for their skills and ability to listen, to challenge and to support YOU over the course of our program. <br /><br />Your success is completely reliant on the effort you make.  The more you put in, the more you will get out. To achieve the best results for YOU, we recommend a minimum commitment of hour a month to complete the exercises, openness, honesty and the willingness to make changes.<br /><br />We are excited to work with you and to help you create a bolder future!
            </div>
            <div className="bp2-ondemandcareercoa-layout-container">
                <div className="bp2-ondemandcareercoa">
                    On-demand Career Coaching to help you find fulfilment in your work and success in your career.
                </div>
            </div>
            <div className="bp2-buttonprimary-layout-container">
                <div className="bp2-buttonprimary">
                    <div className="bp2-paddinggroup2">
                        <img alt="" src="images/induction/participant-introhomemobile-shadow@2x.png" className="bp2-shadow"/>
                        <img alt="" src="images/induction/participant-introhomemobile-background-1@2x.png" className="bp2-background"/>
                        <div onClick={onGetStarted} className="bp2-getstarted">
                            Get Started
                        </div>
                    </div>
                    <img alt="" src="images/induction/participant-introhometabletdesktop-icon-fill-1@2x.png" className="bp2-iconfill"/>
                </div>
            </div>
        </div>
    </div>
    <div className="bp1-participantintrohomemobile">
        <div className="bp1-rectangle">
        </div>
        <div className="bp1-group6-layout-container">
            <div className="bp1-group6">
                <img alt="" src="images/induction/participant-introhomemobile-pcfront@2x.png" className="bp1-pcfront"/>
                <div className="bp1-howitworks-layout-container">
                    <div className="bp1-howitworks">
                        How it works
                    </div>
                </div>
                <div className="bp1-buttonprimarycopy">
                    <div className="bp1-paddinggroup2">
                        <img alt="" src="images/induction/participant-introhomemobile-shadow@2x.png" className="bp1-shadow"/>
                        <img alt="" src="images/induction/participant-introhometabletdesktop-background@2x.png" className="bp1-background"/>
                        <div onClick={onGetStarted} className="bp1-getstarted">
                            Get Started
                        </div>
                    </div>
                    <img alt="" src="images/induction/participant-introhometabletdesktop-icon-fill-1@2x.png" className="bp1-iconfill"/>
                </div>
                <div className="bp1-coach">
                    <img alt="" src="images/induction/participant-introhomemobile-rectangle.png" className="bp1-rectangle2"/>
                    <div className="bp1-oneononevideocoa">
                        One-on-one video coaching sessions every six weeks available anytime, anywhere
                    </div>
                    <img alt="" src="images/induction/participant-introhomemobile-rectangle-1@2x.png" className="bp1-rectangle1"/>
                </div>
                <div className="bp1-group-layout-container">
                    <div className="bp1-group">
                        <img alt="" src="images/induction/participant-introhomemobile-rectangle-copy-2.png" className="bp1-rectanglecopy22"/>
                        <div className="bp1-peeplcoachu2019sinterac">
                            Peeplcoach’s interactive exercises that have been created to help you understand your career obstacles, strengths, opportunities and goals
                        </div>
                    </div>
                </div>
                <img alt="" src="images/induction/participant-introhomemobile-rectangle-copy-2-1.png" className="bp1-rectanglecopy2"/>
                <div className="bp1-urgentcoachingsess">
                    Urgent coaching sessions with your Master Coach to deal with issues that can’t wait for your regularly scheduled session
                </div>
                <div className="bp1-group5-layout-container">
                    <div className="bp1-group5">
                        <img alt="" src="images/induction/participant-introhomemobile-rectangle-copy-2-2.png" className="bp1-rectanglecopy22"/>
                        <div className="bp1-onlinechatswithyo">
                            Online chats with your Master Coach to get expert advice in the moment
                        </div>
                    </div>
                </div>
                <img alt="" src="images/induction/participant-introhomemobile-rectangle-copy-2-3.png" className="bp1-rectanglecopy21"/>
                <div className="bp1-monthlycareerdevel">
                    Career development tips and tools
                </div>
            </div>
        </div>
        <div className="bp1-group4">
            <div className="bp1-welcometothepeepl">
                Welcome to the Peeplcoach Program. Peeplcoach has been created to help you take control of and build a fulfilling and successful career that will make you happy.
            </div>
            <div className="bp1-ondemandcareercoa">
                On-demand Career Coaching to help you find fulfilment in your work and success in your career.
            </div>
            <div className="bp1-buttonprimary">
                <div className="bp1-paddinggroup2">
                    <img alt="" src="images/induction/participant-introhomemobile-shadow@2x.png" className="bp1-shadow"/>
                    <img alt="" src="images/induction/participant-introhomemobile-background-1@2x.png" className="bp1-background"/>
                    <div onClick={onGetStarted} className="bp1-getstarted">
                        Get Started
                    </div>
                </div>
                <img alt="" src="images/induction/participant-introhometabletdesktop-icon-fill-1@2x.png" className="bp1-iconfill"/>
            </div>
        </div>
        <div className="bp1-researchshowsthat">
            Research shows that individuals who utilise bespoke coaching programs feel more confident, have greater clarity around their career goals, are happier and generally, more successful.<br /><br />Peeplcoach Master Coaches are experienced coaches and industry experts who have been chosen for their skills and ability to listen, to challenge and to support YOU over the course of our program. <br /><br />Your success is completely reliant on the effort you make.  The more you put in, the more you will get out. To achieve the best results for YOU, we recommend a minimum commitment of hour a month to complete the exercises, openness, honesty and the willingness to make changes.<br /><br />We are excited to work with you and to help you create a bolder future!
        </div>
        <div className="bp1-group2">
            <div className="bp1-group22copy3">
                <img alt="" src="images/induction/participant-introhomemobile-fill-1@2x.png" className="bp1-fill1"/>
                <img alt="" src="images/induction/participant-introhomemobile-fill-7@2x.png" className="bp1-fill7"/>
                <img alt="" src="images/induction/participant-introhomemobile-fill-9@2x.png" className="bp1-fill9"/>
                <img alt="" src="images/induction/participant-introhomemobile-fill-11-1@2x.png" className="bp1-fill11"/>
            </div>
            <div className="bp1-group3-layout-container">
                <img alt="" src="images/induction/participant-introhomemobile-group-3@2x.png" className="bp1-group3"/>
            </div>
            <div className="bp1-group22copy4">
                <img alt="" src="images/induction/participant-introhomemobile-fill-11-1@2x.png" className="bp1-fill11"/>
            </div>
        </div>
    </div>
  </InductionPanel>
);

WelcomePanel.propTypes = {
  onGetStarted: PropTypes.func.isRequired,
};
export default WelcomePanel;
