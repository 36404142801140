/* eslint-disable arrow-parens */
/* eslint-disable react/no-array-index-key */
// TODO: Above rule was disabled as was not sure best property 'field'
// to use as a key - is there an ID field that is unique ?!

import React from 'react';
import styled from 'styled-components';
import { Field } from './field'; // eslint-disable-line import/no-cycle

const IndividualGroupedField = styled.div`
  margin-bottom: 3rem;
`;

export const GroupedField = ({
  data, updateState, currentValues, color,
}) => {
  const questionsWithRef = data.fields.questions.map((q) => ({ ...q, ref: React.createRef() }));

  const handleClick = (index) => {
    questionsWithRef[index].ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const questionsLength = questionsWithRef.length;

  return (
    <div className="group-field">
      <div className="sidebar">
        { questionsWithRef.map((field, index) => (
          <button
            type="button"
            className="has-text-primary"
            onClick={() => handleClick(index)}
          >
            Part {index + 1}
          </button>
        )) }
      </div>
      <div className="questions">
        { questionsWithRef.map((field, index) => (
          <IndividualGroupedField key={index}>
            <p ref={field.ref}>
              ({`${index + 1}/${questionsLength}`})
            </p>
            <Field data={field} updateState={updateState} currentValues={currentValues} color={color} />
          </IndividualGroupedField>
        )) }
      </div>
    </div>
  );
};
