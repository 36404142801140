/* eslint-disable arrow-parens */
import React from 'react';
import { Label } from 'bloomer';
import styled from 'styled-components';
import { LabelText } from '../../styled';
import { newMatrixState } from './fieldHelpers';
import { RadioOption, RadioOptions } from './RadioField';

const MatrixDimension = styled.div`
  &&& {
    margin-bottom: 2rem;
  }
`;

export const MatrixField = ({ data, updateState, currentValues, submitted }) => {
  const currentValue = (currentValues) ? currentValues.value : {};
  const keyUp = (event) => newMatrixState(data, updateState, currentValue)(event);

  return (
    <div>
      <MatrixDimension>
        <Label>
          <LabelText>
            How skilled are you at
            {' '}
            {data.fields.questionText}
            {'?'}
            {data.fields.required ? '*' : ''}
          </LabelText>
          <span className='help is-danger'>{(data.fields.required && submitted && currentValue === "") ? 'You must select an answer' : ''}</span>
          {' '}
        </Label>
        <RadioOptions>
          <RadioOption id={data.sys.id} name="skilled" value="great" text="Great" callback={keyUp} current={currentValue.skilled} />
          <RadioOption id={data.sys.id} name="skilled" value="good" text="Good" callback={keyUp} current={currentValue.skilled} />
          <RadioOption id={data.sys.id} name="skilled" value="get-by" text="I get by" callback={keyUp} current={currentValue.skilled} />
        </RadioOptions>
      </MatrixDimension>

      <MatrixDimension>
        <Label>
          <LabelText>
            How much do you enjoy
            {' '}
            {data.fields.questionText}
            {'?'}
          </LabelText>
          {' '}
        </Label>
        <RadioOptions>
          <RadioOption id={data.sys.id} name="enjoy" value="love-it" text="I love it" callback={keyUp} current={currentValue.enjoy} />
          <RadioOption id={data.sys.id} name="enjoy" value="like-it" text="I like it" callback={keyUp} current={currentValue.enjoy} />
          <RadioOption id={data.sys.id} name="enjoy" value="live-with-it" text="I live with it" callback={keyUp} current={currentValue.enjoy} />
        </RadioOptions>
      </MatrixDimension>
    </div>
  );
};
