import React from 'react';
import { SimpleExerciseResults } from './SimpleExerciseResults';
import { WheelExerciseResults } from './WheelExerciseResults';
import { MatrixExerciseResults } from './MatrixExerciseResults';
import { EmptyExerciseResults } from './EmptyExerciseResults';

export default ({
  exerciseConfig,
  formState,
  restart,
  closeExercise,
}) => {
  const contentType = exerciseConfig.sys.contentType.sys.id;

  if (contentType === "regurgitationExercise"
        || contentType === "whittlingExercise") {
    return (
      <SimpleExerciseResults
        exerciseData={formState || {}}
        restart={restart}
        closeExercise={closeExercise}
        exerciseConfig={exerciseConfig}
      />
    );
  }

  if (contentType === "scoringExercise") {
    return (
      <WheelExerciseResults
        exerciseData={formState || {}}
        restart={restart}
        closeExercise={closeExercise}
        exerciseConfig={exerciseConfig}
      />
    );
  }

  if (contentType === "matrixExercise") {
    return (
      <MatrixExerciseResults
        exerciseData={formState || {}}
        restart={restart}
        closeExercise={closeExercise}
        exerciseConfig={exerciseConfig}
      />
    );
  }

  if (contentType === "readOnlyExercise") {
    return (
      <EmptyExerciseResults
        exerciseConfig={exerciseConfig}
        description="This is a read-only exercise and has no results."
        closeExercise={closeExercise}
      />
    );
  }

  return null;
};
