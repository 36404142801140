export default class WheelAdvicePresenter {
  constructor(contentfulAdviceData) {
    this.contentfulAdviceData = contentfulAdviceData;
  }

  adviceForValue(value) {
    if (!this.contentfulAdviceData) {
      return undefined;
    }
    const applicableAdvice = this.contentfulAdviceData.find(
      advice => advice.fields.minimumNumber <= value
                && value <= advice.fields.maximiumNumber,
    );

    if (typeof applicableAdvice !== "undefined") {
      return applicableAdvice.fields.advice;
    }
    return undefined;
  }
}
