import React from 'react';
import { getArrayValues } from '../../components/exercises/fields/duplicatedFieldHelpers';

export default class QuestionPresenter {
  constructor(contentfulData, submissionData) {
    this.contentfulData = contentfulData;
    this.submissionData = submissionData;
  }

  contentType() {
    return this.contentfulData.sys.contentType.sys.id;
  }

  responseFor(responseData) {
    if (this.contentType() === "multipleChoice") {
      return (responseData || {}).selectedText;
    }
    if (this.contentType() === "ratingQuestion") {
      return responseData[this.contentfulData.sys.id];
    }

    if (this.contentType() === "whittlingQuestion") {
      if (responseData) {
        const idTextMapping = this.contentfulData.fields.options
          .map(option => [option.sys.id, option.fields]);
        const selectedOptions = idTextMapping.filter(mapping => responseData.value[mapping[0]]);
        return (
          <ul className="bulletted-list">
            {
              selectedOptions.map(option => <li key={option[0]}>{option[1].questionText}</li>)
            }
          </ul>
        );
      }
      return "None selected";
    }

    if (this.contentType() === "userDuplicatedGroup") {
      const exerciseId = this.contentfulData.fields.question.sys.id;
      const entries = getArrayValues(this.submissionData, exerciseId);

      return entries;
    }

    return responseData && responseData.value;
  }

  title() {
    if (this.contentType() === "userDuplicatedGroup") {
      return this.contentfulData.fields.question.fields.questionText;
    }
    return this.contentfulData.fields.questionText;
  }

  color() {
    return this.contentfulData.colorHexCode;
  }
}
