import React from 'react';
import { fetchProfile } from '../api';
import useApiFetch from '../hooks/useApiFetch';
import Loader from './Loader';
import ParticipantDashboard from './pages/ParticipantDashboard';
import ParticipantInduction from './pages/ParticipantInduction';

export default () => {
  const profileState = useApiFetch(fetchProfile);

  if (!profileState.result) {
    return (<Loader />);
  }

  if (profileState.result.isInducted) {
    return (<ParticipantDashboard profileState={profileState} />);
  }
  return (<ParticipantInduction profileState={profileState} />
  );
};
