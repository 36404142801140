import React from 'react';
import fetch from 'fetch-hoc';
import { withRouter } from "react-router-dom";
import useApiFetch from '../../hooks/useApiFetch';
import { fetchProfile } from '../../api';
import { API_URL } from '../../env';
import PopulatedExercise from './PopulatedExercise';

const Exercise = ({ loading, match, data }) => {
  let currentModuleNumber;
  let isParticipant;

  const profile = useApiFetch(fetchProfile);

  if (loading) {
    return <div>LOADING</div>;
  }

  if (profile && profile.items) {
    currentModuleNumber = profile.items.currentModuleNumber;
    isParticipant = profile.items.isParticipant;
  }

  return (
    <div>
      <PopulatedExercise
        exerciseId={data.exerciseId}
        activityId={match.params.id}
        exerciseSubmission={data.exerciseSubmission || { complete: false }}
        currentModuleNumber={currentModuleNumber}
        isParticipant={isParticipant}
      />
    </div>
  );
};

export default withRouter(fetch(props => `${API_URL}/exercises/my/${props.match.params.id}`, { credentials: "include" })(Exercise));
