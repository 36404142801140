import React, { useContext } from 'react';

import {
  Route, Switch, NavLink, withRouter, Redirect,
} from "react-router-dom";
import LinksContext from '../LinksContext';
import DashboardLayout from './DashboardLayout';
import NotFound from './NotFound';
import Participants from '../routes/admin/participants/index';
import ArchivedParticipants from '../routes/admin/participants/archived';
import Coaches from '../routes/admin/coaches/index';
import ArchivedCoaches from '../routes/admin/coaches/archived';
import Organisations from '../routes/admin/organisations/index';
import ArchivedOrganisations from '../routes/admin/organisations/archived';
import ParticipantView from './pages/participantView';
import ExerciseView from './pages/exerciseView';

import { Container } from "./styled";
import OrganisationView from "../routes/coaches/organisations/show";
import CoachView from "../routes/admin/coaches/show";
import { requestPasswordChange } from "../api";
import ToastContext from "../ToastContext";

const AdminNav = withRouter(({ match, linksContext }) => {
  const toast = useContext(ToastContext);

  return (
    <>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}participants`}>Participants</NavLink>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}participants/archived`}>Archived Participants</NavLink>

      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}coaches`}>Coaches</NavLink>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}coaches/archived`}>Archived Coaches</NavLink>

      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}organisations`}>Organisations</NavLink>
      <NavLink activeClassName="is-active" className="navbar-item" to={`${match.path}organisations/archived`}>Archived Organisations</NavLink>

      <NavLink activeClassName="is-active" className="navbar-item" to="/" onClick={() => { requestPasswordChange(linksContext); toast.push({ content: "Password change request sent. You should receive an email shortly." }); }}>Request Change Password</NavLink>
    </>
  );
});

export default ({ match }) => {
  const linksContext = useContext(LinksContext);
  const { links } = useContext(LinksContext);
  if (!(links.participants || links.organisations || links.coaches)) {
    return (<NotFound />);
  }
  return (
    <DashboardLayout additionalNavbarItems={() => <AdminNav linksContext={linksContext} />}>
      <Container>
        <Switch>
          <Route exact path="/">
            <Redirect to="/participants" />
          </Route>
          {links.participants && <Route path={`${match.path}participants`} exact render={() => (<Participants isAdmin="true" />)} />}
          {links.participants && <Route path={`${match.path}participants/archived`} exact render={() => (<ArchivedParticipants isAdmin="true" />)} />}
          {links.organisations && <Route path={`${match.path}organisations`} exact render={() => (<Organisations />)} />}
          {links.organisations && <Route path={`${match.path}organisations/archived`} exact render={() => (<ArchivedOrganisations />)} />}
          {links.participants && <Route path={`${match.path}organisations/:id`} exact render={({ match: innerMatch }) => (<OrganisationView id={innerMatch.params.id} isAdmin={true} />)} />}
          {links.coaches && <Route path={`${match.path}coaches`} exact render={() => (<Coaches />)} />}
          {links.coaches && <Route path={`${match.path}coaches/archived`} exact render={() => (<ArchivedCoaches />)} />}
          {links.participants && <Route path={`${match.path}coaches/:id`} exact render={({ match: innerMatch }) => (<CoachView id={innerMatch.params.id} isAdmin={true} />)} />}
          {links.participants && <Route path={`${match.path}participants/:id`} exact render={({ match: innerMatch }) => (<ParticipantView id={innerMatch.params.id} />)} />}
          {links.participants && <Route path={`${match.path}participants/:participantId/exercise/:exerciseId`} exact render={({ match: innerMatch }) => (<ExerciseView participantId={innerMatch.params.participantId} exerciseId={innerMatch.params.exerciseId} />)} />}
          <Route component={NotFound} />
        </Switch>
      </Container>
    </DashboardLayout>
  );
};
