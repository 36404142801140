import React from 'react';
import styled from 'styled-components';

export const RadioOptions = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
  }
`;

const StyledRadioOption = styled.div`
  &&& {
    input {
      margin-right: 4px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
    margin-bottom: 0.4rem;
  }
`;

export const RadioOption = ({
  id,
  name,
  value,
  text,
  callback,
  current,
}) => (
  <StyledRadioOption>
    <label htmlFor={value}>
      <input type="radio" name={`${id}-${name}`} value={value} onClick={callback} defaultChecked={current === value} />
      { text }
    </label>
  </StyledRadioOption>
);

export const RadioField = () => {
};
