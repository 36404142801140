import React from 'react';
import { ParagraphField } from './ParagraphField';
import { SingleLineField } from './SingleLineField';
import { MultipleChoiceField } from './MultipleChoiceField';
import { RatingField } from './RatingField';
import { GroupedField } from './GroupedField'; // eslint-disable-line import/no-cycle
import { MatrixField } from './MatrixField';
import { WhittlingField } from './WhittlingField';
import { UserDuplicatedGroupField } from './UserDuplicatedGroupField'; // eslint-disable-line import/no-cycle

const fieldForData = (data, updateState, currentValues, exerciseType, updateEntireExerciseFormState, submitted) => {
  switch (data.sys.contentType.sys.id) {
    case "paragraphQuestion":
      return <ParagraphField data={data} currentValues={currentValues[data.sys.id]} updateState={updateState} submitted={submitted} />;
    case "singleLineQuestion":
      if (exerciseType === "matrixExercise") {
        return <MatrixField data={data} currentValues={currentValues[data.sys.id]} updateState={updateState} submitted={submitted} />;
      }
      return <SingleLineField data={data} currentValues={currentValues[data.sys.id]} updateState={updateState} submitted={submitted} />;
    case "multipleChoice":
      return <MultipleChoiceField data={data} currentValues={currentValues[data.sys.id]} updateState={updateState} submitted={submitted} />;
    case "ratingQuestion":
      return <RatingField data={data} currentValues={currentValues[data.sys.id]} updateState={updateState} submitted={submitted} />;
    case "questionGroup":
      return <GroupedField data={data} currentValues={currentValues} updateState={updateState} />;
    case "wheelQuestion":
      return data.fields.questions ? <GroupedField data={data.fields.questions} currentValues={currentValues} updateState={updateState} /> : "";
    case "whittlingQuestion":
      return <WhittlingField data={data} currentValues={currentValues} updateState={updateState} submitted={submitted} />;
    case "userDuplicatedGroup":
      return <UserDuplicatedGroupField data={data} currentValues={currentValues} updateState={updateState} updateEntireExerciseFormState={updateEntireExerciseFormState} submitted={submitted} />;
    default:
      return `unknown question type: ${data.sys.contentType.sys.id}`;
  }
};

export const Field = ({
  data, updateState, currentValues, exerciseType, updateEntireExerciseFormState, submitted,
}) => fieldForData(data, updateState, currentValues, exerciseType, updateEntireExerciseFormState, submitted);
