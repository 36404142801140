import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import useAdminDataManager from "../../../hooks/useAdminDataManager";
import { createAndInviteParticipant, fetchParticipants, updateParticipant } from "../../../api/participants";
import { Loader, Section } from "../../../components/styled";
import { InviteParticipantButton, ParticipantDialog, ParticipantList } from "../../../components/pages/participants";

export default withRouter(({ history }) => {
  const { manage, state } = useAdminDataManager(fetchParticipants, createAndInviteParticipant, updateParticipant);
  const {
    cancelEdit, startEdit, startCreate, saveData,
  } = manage;

  const { currentMode, items, targetItem } = state;
  const renderByMode = {
    loading: () => <Loader />,
    editing: () => <ParticipantDialog onCancel={cancelEdit} onSave={saveData} initialState={targetItem} isAdmin={false} />,
    listing: () => (
      <>
        {startCreate && (
          <Section>
            <InviteParticipantButton onClick={startCreate} />
          </Section>
        )}

        <Section>
          <ParticipantList participants={_.orderBy(items, org => (org.name || "").toUpperCase())} onEdit={startEdit} history={history} isAdmin={false} />
        </Section>
      </>
    ),
  };
  return (
    <>
      <h2> Participants </h2>
      {renderByMode[currentMode]()}
    </>
  );
});
