/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Content } from 'bloomer';
import { markdown } from 'markdown';
import { MainImage, Container, DownloadIcon } from '../styled';

const IntroText = styled(Content)`

`;

const InfoPanel = styled.div`
  &&& {
    background-color: rgb(186, 159, 221, 0.5);
    color: #2D313F;
    padding-top: 1.25rem;
    padding-bottom:1.25rem;
    padding-right:1.25rem;
    padding-left: 0.75rem;
    display:flex;
    margin-top: 2rem;
    margin-bottom: 3.5rem;
  }
`;

const InfoIcon = styled.div`
  margin-right: 0.75em;
  display: flex;
`;

const TimeRepresentation = styled.span`
  font-weight: bold;
`;

const TitlesContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.5rem;
`;

const Title = styled.h3`
  padding-bottom: 0rem;
`;

const Download = styled.div`
  display: flex;
  align-items: center;
  color: black;
  .button {
    margin-right: 1rem;
  }

`;

const DownloadButton = styled.div`
  &&& {
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
    background-color: white;
  }
`;

export const Introduction = (
  {
    subtitle,
    title,
    text,
    image,
    completionMinutes,
    downloads,
  },
) => (
  <div>

    {image && <MainImage src={image} /> }
    <Container>
      <TitlesContainer>
        <h5 className="has-text-light">{subtitle}</h5>
        <Title>{title}</Title>
      </TitlesContainer>
      <IntroText dangerouslySetInnerHTML={{ __html: markdown.toHTML(text) }} />
      { completionMinutes > 0
      && (
      <InfoPanel>
        <InfoIcon>
          <img src="images/time.svg" alt="" />
        </InfoIcon>

        <p>
        This should take approximately
          <TimeRepresentation>
            <br />
            {completionMinutes}
            &nbsp;minutes
          </TimeRepresentation>
        </p>

      </InfoPanel>
      )
      }
      { downloads
        && (
        <div>
          {downloads.map(download => (
            <a href={download.fields.file.url} key={download.fields.file.url} target="_blank" rel="noopener noreferrer">
              <Download>
                <DownloadButton className="button is-outlned">
                  <DownloadIcon alt="Download file" />
                </DownloadButton>

                {download.fields.title}
              </Download>
            </a>


          ))}
        </div>
        )
      }
    </Container>
  </div>
);
