import React from 'react';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import Loader from '../../../components/Loader';
import { fetchOrganisations, createOrganisation, updateOrganisation } from '../../../api/organisations';
import useAdminDataManager from '../../../hooks/useAdminDataManager';
import {
  Section,
} from "../../../components/styled";
import {
  OrganisationDialog,
  AddOrganisationButton,
  OrganisationList,
} from "../../../components/pages/organisations";

export default withRouter(({ history }) => {
  const { manage, state } = useAdminDataManager(fetchOrganisations, createOrganisation, updateOrganisation, {archived: false});

  const {
    cancelEdit, startEdit, startCreate, saveData,
  } = manage;

  const { currentMode, items, targetItem } = state;
  const renderByMode = {
    loading: () => <Loader />,
    editing: () => <OrganisationDialog onCancel={cancelEdit} onSave={saveData} initialState={targetItem} />,
    listing: () => (
      <>

        {startCreate && (
          <Section>
            <AddOrganisationButton onClick={startCreate} />
          </Section>
        )}

        <Section>
          <OrganisationList organisations={_.orderBy(items, org => (org.name || "").toUpperCase())} onEdit={startEdit} history={history} isAdmin={true} />
        </Section>
      </>
    ),
  };
  return (
    <>
      <h2> Organisations </h2>
      {renderByMode[currentMode]()}
    </>

  );
});
