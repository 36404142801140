import React from 'react';
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  InviteParticipantButton,
  ParticipantDialog,
  ParticipantList,
} from '../../../components/pages/participants';
import Loader from '../../../components/Loader';
import { createAndInviteParticipant, fetchParticipants, updateParticipant, } from '../../../api/participants';
import {
  Section,
} from "../../../components/styled";
import useAdminDataManager from "../../../hooks/useAdminDataManager";

export default withRouter(({ id, history, isAdmin }) => {
  const { manage, state } = useAdminDataManager(fetchParticipants, createAndInviteParticipant, updateParticipant, { organisationId: id });
  const {
    cancelEdit, startEdit, startCreate, saveData,
  } = manage;

  const { currentMode, items, targetItem } = state;
  const renderByMode = {
    loading: () => <Loader />,
    editing: () => <ParticipantDialog onCancel={cancelEdit} onSave={saveData} initialState={targetItem} isAdmin={isAdmin} />,
    listing: () => (
      <>
        {startCreate && (
          <Section>
            <InviteParticipantButton onClick={startCreate} />
          </Section>
        )}

        <Section>
          <ParticipantList participants={_.orderBy(items, ptpt => (ptpt.name || "").toUpperCase())} onEdit={startEdit} history={history} isAdmin={isAdmin} />
        </Section>
      </>
    ),
  };
  return (
    <>
      <h2> Participants </h2>
      {renderByMode[currentMode]()}
    </>
  );
});
