const createClient = () => {
  const contentful = require('contentful'); // eslint-disable-line global-require
  return contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    environment: 'master',
    accessToken: process.env.REACT_APP_CONTENTFUL_DELIVERY_API_TOKEN,
  });
};

module.exports.createClient = createClient;
