import React, { useContext, useReducer, useState } from 'react';
import _ from 'lodash';

import Loader from '../Loader';
import {
  Box, Field, Label, Control, Input, PrimaryButton, Columns, Column, TextArea, PrimaryTextButton,
} from "../styled";
import DashboardButton from '../DashboardButton';
import LinksContext from "../../LinksContext";
import ModalLayout from "../ModalLayout";
import { deleteCoach, updateCoach } from "../../api/coaches";
import moment from "moment";


export const CoachList = ({ coaches, loading, onEdit, history, isAdmin }) => {
  const [state, setState] = useState({ showDeleteId: '', showArchiveId: '', userInput: '', filteredCoaches: coaches, coaches, coachSearch: '' });
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Field>
        <Label>Search by Name</Label>
        <Control>
          <Input placeholder="Coach name" value={state.coachSearch || ""} onChange={(e) => {
            const filteredCoaches = _.filter(state.coaches, coach => coach.name.toLowerCase().includes(e.target.value.toLowerCase()));

            setState({ showDeleteId: '', userInput: '', coaches, filteredCoaches, coachSearch: e.target.value });
          }
          }
          />
        </Control>
      </Field>

      {_.sortBy(state.filteredCoaches, coach => coach.name.toLowerCase()).map((coach) => (
        <Box key={coach.id}>
          <Columns>
            <Column data-testid={`${coach.id}-link`} onClick={() => { history.push(`/coaches/${coach.id}`); }}>
              <div className="body-bold" data-testid={`${coach.id}-name`}>{coach.name}</div>
              <h5>{coach.emailAddress}</h5>
              {coach.archivedAt ? <h5>Archived At: {moment(coach.archivedAt).format('DD/MM/YYYY HH:mm')}</h5> : ''}
            </Column>
            {coach.links.modify && (
              <Column isSize="narrow" className='modify-button-row'>
                <PrimaryTextButton data-testid={`${coach.id}-edit`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => onEdit(coach.id)} paddingless>EDIT</PrimaryTextButton>
                {isAdmin ? <PrimaryTextButton data-testid={`${coach.id}-delete`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => setState({ showDeleteId: coach.id, showArchiveId: '', userInput: '', coaches, filteredCoaches: state.filteredCoaches, coachSearch: state.coachSearch })} paddingless>DELETE</PrimaryTextButton> : ''}
                {isAdmin ? <PrimaryTextButton data-testid={`${coach.id}-archive`} colour="light-grey" isOutlined isSize="small" noHover onClick={() => setState({ showDeleteId: '', showArchiveId: coach.id, userInput: '', coaches, filteredCoaches: state.filteredCoaches, coachSearch: state.coachSearch })} paddingless>{coach.archivedAt ? 'RESTORE' : 'ARCHIVE'}</PrimaryTextButton> : ''}
              </Column>
            )}

          </Columns>

          {isAdmin ? <CoachDeleteDialog showDeleteId={state.showDeleteId} filteredCoaches={state.filteredCoaches} coachSearch={state.coachSearch} userInput={state.userInput} setState={setState} coach={coach} coaches={state.coaches} /> : '' }
          {isAdmin ? <CoachArchiveDialog showArchiveId={state.showArchiveId} filteredCoaches={state.filteredCoaches} coachSearch={state.coachSearch} archiveValue={coach.archivedAt ? null : new Date()} userInput={state.userInput} setState={setState} coach={coach} coaches={state.coaches} /> : ''}
        </Box>
      ))}

    </>
  );
};

export const AddCoachButton = ({ onClick }) => (
  <DashboardButton dataTestId="coach-new" onClick={onClick} caption="Add a new coach" />
);

const isNameValid = state => (state.formState.name && state.formState.name.length > 0);
const isEmailValid = state => (state.formState.emailAddress && state.formState.emailAddress.length > 0);

const getInputColor = (modified, valid) => {
  if (!modified) {
    return undefined;
  }
  if (valid) {
    return "success";
  }
  return "danger";
};


const formReducer = (state, action) => {
  switch (action.type) {
    case "updateFormState":
      return { ...state, formState: { ...state.formState, ...action.payload }, modified: _.union(state.modified, Object.keys(action.payload)) };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

export const CoachDialog = ({ onCancel, onSave, initialState }) => {
  const [state, dispatch] = useReducer(formReducer, { formState: initialState || {}, modified: [] });
  const { formState } = state;
  const nameInputColour = getInputColor(state.modified.includes("name"), isNameValid(state));
  const emailInputColour = getInputColor(state.modified.includes("emailAddress"), isEmailValid(state));
  const editMode = !!initialState.id;
  const isSubmitEnabled = editMode || (!!isNameValid(state) && !!isEmailValid(state));


  return (
    <Box>
      <Field>
        <Label>Name</Label>
        <Control>
          <Input data-testid="form-name" placeholder="Coach name" isColor={nameInputColour} value={formState.name || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { name: e.target.value } })} />
        </Control>
      </Field>
      <Field>
        <Label>Email</Label>
        <Control>
          <Input data-testid="form-email" placeholder="Coach email" readOnly={editMode && "readonly"} className={editMode && "is-static"} isColor={emailInputColour} value={formState.emailAddress || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { emailAddress: e.target.value } })} />
        </Control>
      </Field>

      <Field>
        <Label>Biography</Label>
        <Control>
          <TextArea placeholder="Coach biography" value={formState.biography || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { biography: e.target.value } })} />
        </Control>
      </Field>
      <Field>
        <Label>Calendly ID</Label>
        <Control>
          <Input placeholder="Calendly ID" value={formState.calendlyId || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { calendlyId: e.target.value } })} />
        </Control>
      </Field>
      <Field>
        <Label>Booking resource id</Label>
        <Control>
          <Input placeholder="Booking resource id" value={formState.bookingResourceId || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { bookingResourceId: e.target.value } })} />
        </Control>
      </Field>
      <Field>
        <Label>Zoom ID</Label>
        <Control>
          <Input placeholder="Zoom ID" value={formState.chatId || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { chatId: e.target.value } })} />
        </Control>
      </Field>
      <Field>
        <Label>Image url</Label>
        <Control>
          <Input placeholder="Image url" value={formState.imageUrl || ""} onChange={e => dispatch({ type: 'updateFormState', payload: { imageUrl: e.target.value } })} />
        </Control>
      </Field>


      <div className="buttons">
        <PrimaryButton isInverted onClick={onCancel}>Cancel</PrimaryButton>
        <PrimaryButton data-testid="form-submit" disabled={!isSubmitEnabled && "disabled"} onClick={() => onSave(formState)}>Save</PrimaryButton>
      </div>
    </Box>
  );
};

export const CoachDeleteDialog = ({ coaches, coach, showDeleteId, userInput, filteredCoaches, coachSearch, setState }) => {
  const linksContext = useContext(LinksContext);

  if (showDeleteId === coach.id) {
    return (
      <ModalLayout>
        <h4>Are you sure you want to delete {coach.emailAddress}?</h4>
        <p>Enter the name below to confirm</p>

        <Field>
          <Control>
            <Input data-testid="form-email" placeholder="Coach email" value={userInput || ""} onChange={e => (setState({ userInput: e.target.value, showDeleteId: coach.id, showArchiveId: '', coaches, filteredCoaches, coachSearch }))} />
          </Control>
        </Field>

        <div className="buttons">
          <PrimaryButton isInverted onClick={() => setState({ showDeleteId: '', showArchiveId: '', userInput: '', coaches, filteredCoaches, coachSearch })}>Cancel</PrimaryButton>
          <PrimaryButton data-testid="form-submit" onClick={async () => {
            if (coach.emailAddress.toLowerCase() === userInput.toLowerCase()) {
              _.remove(coaches, e => e.id === coach.id);
              await deleteCoach(linksContext, coach);
              setState({ showDeleteId: '', showArchiveId: '', userInput: '', coaches, filteredCoaches, coachSearch });
            }
          }}>
            Delete
          </PrimaryButton>
        </div>
      </ModalLayout>
    );
  } else {
    return "";
  }
};

export const CoachArchiveDialog = ({ coaches, coach, archiveValue, showArchiveId, filteredCoaches, coachSearch, userInput, setState }) => {
  const linksContext = useContext(LinksContext);

  if (showArchiveId === coach.id) {
    return (
      <ModalLayout>
        <h4>Are you sure you want to {archiveValue === null ? 'restore' : 'archive'} {coach.emailAddress}?</h4>
        <p>Enter the name below to confirm</p>

        <Field>
          <Control>
            <Input data-testid="form-email" placeholder="Coach email" value={userInput || ""} onChange={e => (setState({ userInput: e.target.value, showDeleteId: '', showArchiveId: coach.id, coaches, filteredCoaches, coachSearch }))} />
          </Control>
        </Field>

        <div className="buttons">
          <PrimaryButton isInverted onClick={() => setState({ showDeleteId: '', showArchiveId: '', userInput: '', coaches, filteredCoaches, coachSearch })}>Cancel</PrimaryButton>
          <PrimaryButton data-testid="form-submit" onClick={async () => {
            if (coach.emailAddress.toLowerCase() === userInput.toLowerCase()) {
              _.remove(coaches, e => e.id === coach.id);
              coach.archivedAt = archiveValue;
              await updateCoach(linksContext, coach);
              setState({ showDeleteId: '', showArchiveId: '', userInput: '', coaches, filteredCoaches, coachSearch });
            }
          }}>
            {archiveValue === null ? 'Restore' : 'Archive'}
          </PrimaryButton>
        </div>
      </ModalLayout>
    );
  } else {
    return "";
  }
};
