import React from 'react';
import styled from 'styled-components';
import { FullscreenHeroActivityFooterControls } from '../../FullscreenHero';
import { PrimaryButton, PrimaryTextButton } from '../../styled';

const NotOnMobile = styled.span`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const ResultsFooter = ({ restart, close }) => (
  <FullscreenHeroActivityFooterControls directional>
    <PrimaryButton onClick={close}>Done</PrimaryButton>
    {
      restart && (
        <PrimaryTextButton onClick={restart}>
          Re-do
          <NotOnMobile>&nbsp;this exercise</NotOnMobile>
        </PrimaryTextButton>
      )
    }
  </FullscreenHeroActivityFooterControls>
);

export default ResultsFooter;
