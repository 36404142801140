import moment from "moment";
import { API_URL } from "../env";

export const formatActivities = activities => activities.map(activity => ({
  ...activity,
  subtitles: activity.feedSubtitles,
  avatar: (activity.relatedPerson && activity.relatedPerson.ImageUrl),
  duration: activity.duration && moment.duration(activity.duration, "minutes"),
  dueDate: activity.dueDate && moment(activity.dueDate),
  dueTime: activity.dueTime && moment(activity.dueTime),
}));

export const activityFeed = () => fetch(`${API_URL}/activities/my-activities`, { credentials: "include" }).then(response => response.json()).then((response) => {
  if (!response && !response.activities) {
    return [];
  }
  return formatActivities(response.activities);
});

export const fetchActivitiesForParticipant = (linksContext, participant) => fetch(participant.links.activities.href, { credentials: "include" })
  .then(response => response.json())
  .then((response) => {
    if (!response && !response.activities) {
      return [];
    }
    return formatActivities(response.activities);
  });
