import { makeAbsoluteLinks } from "../api";

const formatNote = (linksContext, note) => ({
  ...note,
  links: makeAbsoluteLinks(note, linksContext.endpointUrl),
});

export const fetchNotesForParticipant = (linksContext, participant) => fetch(participant.links.notes.href, { credentials: "include" })
  .then(response => response.json())
  .then((response) => {
    if (!response && !response.notes) {
      return [];
    }
    return {notes: response.notes.map(note => formatNote(linksContext, note)), links: makeAbsoluteLinks(response, linksContext.endpointUrl) }
  });

export const createNote = (linksContext, attributes) => fetch(linksContext.links.notes.href, {
  method: "POST", headers: { 'Content-Type': 'application/json' }, credentials: "include", body: JSON.stringify({ note: attributes }),
}).then(response => response.json()).then(result => formatNote(linksContext, result.note));

export const updateNote = (linksContext, note) => fetch(note.links.modify.href, {
  method: "PUT",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({ note: { note: note.note } }),
})
  .then(response => response.json())
  .then(nt => formatNote(linksContext, nt));

export const deleteNote = (linksContext, note) => fetch(note.links.modify.href, {
  method: "DELETE",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
}).then(response => response.json()).then(result => formatNote(linksContext, result.note));
