import React, { useState } from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  transition: max-height 0.2s ease;
  overflow: hidden;
`;

const Expandable = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleButton = <button type="button" className="link secondary" onClick={() => setExpanded(!expanded)}>{ expanded ? 'Collapse' : 'Read more' }</button>;

  return (
    <div key="container">
      <ContentContainer style={{ maxHeight: (expanded ? '50em' : '0em') }}>
        { children }
      </ContentContainer>
      { toggleButton }
    </div>
  );
};

export default Expandable;
