import React from 'react';

import { Main, WaitScreen } from './styled';

const CoachMatchingWaitPanel = () => (
  <Main className="">
    <WaitScreen>
      <p className="has-text-centered body-bold">We’re matching you with a coach to help you achieve your goals.</p>
    </WaitScreen>
  </Main>

);
export default CoachMatchingWaitPanel;
