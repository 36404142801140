import _, { forEach } from 'lodash';
import { v4 as uuid } from 'uuid';

const matcher = /^([a-zA-Z0-9]*)\[([0-9])+\]/;

const parseIndexedKey = (key) => {
  const parts = key.match(matcher);
  if (!parts) return null;

  const id = parts[1];
  const index = parseInt(parts[2]);

  return { id, index };
};

export const indexContentfulId = (contentfulId, index) => `${contentfulId}[${index}]`;

const reIndexKey = (key, newIndex) => {
  const { id } = parseIndexedKey(key);
  return indexContentfulId(id, newIndex);
};

const ensureHasUuid = entry => (entry.uuid ? entry : { ...entry, uuid: uuid() });

export const getArrayValues = (data, questionId) => {
  const result = [];

  forEach(data, (value, key) => {
    const parsedKey = parseIndexedKey(key);
    if (parsedKey) {
      const { id, index } = parsedKey;
      if (id === questionId && value != null) {
        result.push({ index, value: ensureHasUuid(value) });
      }
    }
  });

  return _.map(_.sortBy(result, 'index'), 'value');
};

export const valuesHashFromArray = (entries) => {
  const result = {};

  forEach(entries, (entry, index) => {
    const newKey = reIndexKey(entry.contentfulId, index);
    result[newKey] = { ...entry, contentfulId: newKey };
  });

  return result;
};

export const valuesHashForSingleEntry = entry => ({
  [entry.contentfulId]: entry,
});

export const buildEntry = (questionId, index) => ({
  contentfulId: indexContentfulId(questionId, index),
  value: null,
  uuid: uuid(),
});
