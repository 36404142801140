/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { ResultField } from './fields/ResultField';
import { Container } from '../../styled';
import FullscreenHero, { FullscreenHeroActivityNav } from '../../FullscreenHero';
import ResultsFooter from './ResultsFooter';

const SimpleResult = styled.div`
  &&& {
    margin-bottom: 3rem
  }
`;

const SimpleHeader = styled.h3`
  &&& {
    margin-bottom: 4rem
    text-align: center;
  }
`;

export const SimpleExerciseResults = ({
  exerciseData, restart, closeExercise, exerciseConfig,
}) => {
  const exercisesInOrder = exerciseConfig.fields.questions;
  const validExercises = exercisesInOrder.filter(question => question && question.fields);
  const exerciseType = exerciseConfig.sys.contentType.sys.id;

  return (
    <FullscreenHero
      showCloseButton
      headerRender={props => (
        <FullscreenHeroActivityNav {...props} lightHeader backToDashboardAction={closeExercise} />
      )}

      footerRender={_props => <ResultsFooter restart={restart} close={closeExercise} />}
    >

      <Container>
        <SimpleHeader className="has-text-primary">
          {exerciseConfig.fields.title}
          : Results
        </SimpleHeader>
        {
          validExercises.map((question, index) => (
            <SimpleResult key={question.sys.id}>
              <h5 className="has-text-primary">
                Question {index + 1}
              </h5>
              <ResultField question={question} submissionData={exerciseData} exerciseType={exerciseType} />
            </SimpleResult>
          ))
        }
        {
          validExercises.length === 0 && (
            <center>You have not answered any questions.</center>
          )
        }
      </Container>
    </FullscreenHero>
  );
};
