/* eslint-disable arrow-parens */
import React from 'react';
import { Label } from 'bloomer';
import { LabelText, SmallTextArea } from '../../styled';
import { newState } from './fieldHelpers';

export const ParagraphField = ({ data, updateState, currentValues, submitted }) => {
  const change = (event) => newState(data, updateState, currentValues)(event);
  const currentValue = (currentValues) ? currentValues.value : "";

  return (
    <div>
      <Label>
        <LabelText>
          {data.fields.questionText}
          {data.fields.required ? '*' : ''}
        </LabelText>
        <span className='help is-danger'>{(data.fields.required && submitted && currentValue === "") ? 'You must select an answer' : ''}</span>
        <SmallTextArea placeholder="type something" rows="10" onChange={change} tabIndex="-1" defaultValue={currentValue} />
      </Label>
    </div>
  );
};
