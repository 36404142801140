import { makeAbsoluteLinks, objToQueryString } from "../api";

const formatOrganisation = (linksContext, organisation) => ({
  ...organisation,
  links: makeAbsoluteLinks(organisation, linksContext.endpointUrl),
});

export const fetchOrganisations = (linksContext, params) => {
  const queryString = objToQueryString(params);
  return fetch(`${linksContext.links.organisations.href}?${queryString}`, { credentials: "include" })
  .then(response => response.json())
  .then(response => ({
    links: makeAbsoluteLinks(response, linksContext.endpointUrl),
    items: response.organisations.map(coach => formatOrganisation(linksContext, coach)),
  }));
};

export const createOrganisation = (linksContext, attributes) => fetch(linksContext.links.organisations.href, {
  method: "POST", headers: { 'Content-Type': 'application/json' }, credentials: "include", body: JSON.stringify({ organisation: attributes }),
}).then(response => response.json()).then(result => formatOrganisation(linksContext, result.organisation));

export const updateOrganisation = (linksContext, organisation) => fetch(organisation.links.self.href, {
  method: "PUT",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
  body: JSON.stringify({ organisation }),
})
  .then(response => response.json())
  .then(result => formatOrganisation(linksContext, result.organisation));

export const deleteOrganisation = (linksContext, organisation) => fetch(organisation.links.modify.href, {
  method: "DELETE",
  headers: { 'Content-Type': 'application/json' },
  credentials: "include",
}).then(response => response.json()).then(result => formatOrganisation(linksContext, result.organisation));
