import React from 'react';
import { Field } from './fields/field';

export default ({
  data,
  index,
  updateState,
  currentValues,
  exerciseType,
  updateEntireExerciseFormState,
  submitted
}) => (
  <div style={{ marginTop: "32px" }}>
    <h5 className="has-text-primary">
      Question
      { " " }
      { index + 1 }
    </h5>

    <Field data={data} updateState={updateState} currentValues={currentValues} exerciseType={exerciseType} updateEntireExerciseFormState={updateEntireExerciseFormState} submitted={submitted} />
  </div>
);
