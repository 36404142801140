import React from 'react';
import styled from 'styled-components';
import {
  PrimaryButton,
  PrimaryTextButton,
  MainAvatarImage,
  Level,
  LevelLeft,
  LevelItem,
  Section,
  Card,
} from '../styled';
import FullscreenHero, { FullscreenHeroActivityNav } from '../FullscreenHero';
import ModalLayout from '../ModalLayout';
import { API_URL } from '../../env';

const CenteredCard = styled(Card)`
  &&& {
    margin: 0px auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
  }
`;

const CenteredButtons = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    margin: 0px auto;
    margin-top: 4rem;
    > * {
      margin: 0.5rem;
    }
  }
`;

const UnpaddedSection = styled(Section)`
  &&& {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
`;

const TopPaddedSection = styled(UnpaddedSection)`
  &&& {
    padding-top: 1.5rem;
  }
`;

const ConfirmBookingPrompt = ({
  selectedSlot, fromDashboard, coachCall, goBackClicked, confirmClicked,
}) => {
  const { coach } = coachCall;
  const apiUrl = API_URL;
  return (
    <FullscreenHero
      showCloseButton={fromDashboard}
      headerRender={props => <FullscreenHeroActivityNav {...props} showCloseButton />}
    >
      <CenteredCard>
        <UnpaddedSection>
          <h3 className="has-text-weight-semibold is-paddingless">
            {selectedSlot.start.format("ddd MMMM Do")}
            {' '}
          </h3>
          <h3 className="is-paddingless has-text-weight-semibold">
            {`${selectedSlot.start.format("h:mma")} - ${selectedSlot.end.format("h:mma")}`}
          </h3>

        </UnpaddedSection>
        <TopPaddedSection>
          <Level isMobile tag="div">
            <LevelLeft>
              <LevelItem>
                <MainAvatarImage
                  src={coach.ImageUrl && (coach.ImageUrl.startsWith("http") ? coach.ImageUrl : apiUrl + coach.ImageUrl)}
                  alt={`Avatar for coach ${coach.Name}`}
                />
              </LevelItem>
              <LevelItem>
                <div>
                  <p>{coachCall.contentFields.titleAsPrefix}</p>
                  {' '}
                  <h4 className="has-text-weight-semibold is-paddingless">{coach.Nickname || coach.Name}</h4>
                </div>
              </LevelItem>
            </LevelLeft>
          </Level>
        </TopPaddedSection>
        <CenteredButtons>
          <PrimaryButton onClick={confirmClicked}>Confirm Booking</PrimaryButton>
          <PrimaryTextButton onClick={goBackClicked}>Go Back</PrimaryTextButton>
        </CenteredButtons>
      </CenteredCard>
    </FullscreenHero>
  );
};

ConfirmBookingPrompt.defaultProps = {
  fromDashboard: false,
};

const CancelModal = ({ confirmCancelClicked, goBackClicked }) => (
  <ModalLayout
    footerRender={(
      <>
        <PrimaryTextButton onClick={confirmCancelClicked}>Yes, cancel</PrimaryTextButton>
        <PrimaryButton onClick={goBackClicked}>No</PrimaryButton>
      </>
)}
    title="Are you sure you want to cancel your appointment?"
  >
    <p>
      p.s. you'll have to remove it from your calendar
    </p>
  </ModalLayout>
);


export default {
  Confirm: ConfirmBookingPrompt,
  Cancel: CancelModal,
};
