import React from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon, PrimaryTextButton } from '../styled';

import SaveToCalendar from './SaveToCalendar';


const BookingInformation = ({ coachCall, bookNowClicked, cancelBookingClicked }) => {
  const { start, end, completed } = coachCall.bookingDetails;
  return (
    <div className="columns is-mobile is-gapless">
      <div className="column is-narrow" style={{ marginRight: '0.75em' }}>
        <CalendarIcon />
      </div>
      <div className="column">
        <div className="columns is-mobile is-gapless is-multiline" style={{ marginBottom: 0 }}>
          {start && (
            <div className="column ">
              <div className="columns columns is-multiline is-gapless is-mobile">
                <div className="body-bold is-full column" style={{ whiteSpace: "nowrap" }}>
                  {start.format("MMMM Do ")}
                  <time dateTime={start.format()}>
                    {start.format("LT")}
                  </time>
                  {!completed && end && (
                  <time dateTime={end.format()}>
                    {end.format(" - LT")}
                  </time>
                  )}
                </div>

                <div className="column">
                  {!completed && <SaveToCalendar coachCall={coachCall} />}
                </div>
              </div>
            </div>

          ) }
          {!completed
      && (
        <div className="column is-narrow">
          <div className="columns is-mobile is-variable is-1-mobile">
            <div className="column is-narrow">
              <PrimaryTextButton colour="light-grey" size="small" noHover onClick={cancelBookingClicked} paddingless>cancel</PrimaryTextButton>
            </div>
            <div className="column">
              <PrimaryTextButton colour="light-grey" onClick={bookNowClicked} size="small" noHover paddingless>edit</PrimaryTextButton>
            </div>
          </div>
        </div>
      )
    }
        </div>


      </div>

    </div>

  );
};

BookingInformation.propTypes = {
  coachCall: PropTypes.shape({
    bookingDetails: PropTypes.shape({
      start: PropTypes.object,
      end: PropTypes.object,
    }),
  }).isRequired,
  bookNowClicked: PropTypes.func.isRequired,
  cancelBookingClicked: PropTypes.func.isRequired,
};

export default BookingInformation;
