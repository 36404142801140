import React from 'react';
import bulmaCollapsible from '@creativebulma/bulma-collapsible';

class Accordion extends React.Component {
  componentDidMount() {
    bulmaCollapsible.attach(".is-collapsible", {
      container: this.refs.accordions,
    });
  }

  render() {
    const { title, active, children } = this.props;

    return (
      <div ref="accordions" id="accordion_first">
        <article className="message">
          <div className="message-header">
            <a href="#collapsible-message-accordion-1" data-action="collapse">
              {title}
            </a>
          </div>
          <div
            id="collapsible-message-accordion-1"
            className={active ? "message-body is-collapsible is-active" : "message-body is-collapsible"}
            data-parent="accordion_first"
          >
            {children}
          </div>
        </article>
      </div>
    );
  }
}

export default Accordion;
