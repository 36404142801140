import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { newRatingState } from './fieldHelpers';
import Rating from '../../Rating';
import { LabelText } from '../../styled';

const Captions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
`;

const Caption = styled.div`
  max-width: 48%;
  font-size: 0.75rem;

  &:last-child {
    text-align: right;
  }
`;

export const RatingField = ({ data, updateState, currentValues, submitted }) => {
  const ratingSelected = event => newRatingState(data, updateState)(event);
  const currentValue = (currentValues) ? currentValues.value : "";

  return (
    <div>
      <LabelText>
        {data.fields.questionText}
        {data.fields.required ? '*' : ''}
      </LabelText>
      <span className='help is-danger'>{(data.fields.required && submitted && currentValue === "") ? 'You must select an answer' : ''}</span>
      <Rating values={_.range(data.fields.minimumValue, data.fields.maximumValue + 1)} onRatingSelected={ratingSelected} currentValue={currentValue} id={data.sys.id} />
      <Captions>
        <Caption className="has-text-light">
          {data.fields.minimumValueTitle}
        </Caption>
        <Caption className="has-text-light">
          {data.fields.maximumValueTitle}
        </Caption>
      </Captions>
    </div>
  );
};
