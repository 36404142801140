import _ from 'lodash';

export class MatrixResult {
  constructor(matrixSubmission) {
    this.matrixSubmission = matrixSubmission;
  }

  skillsFitting(skilled, enjoyment) {
    return _.values(_.pickBy(this.matrixSubmission, value => _.get(value, 'value.enjoy') === enjoyment && _.get(value, 'value.skilled') === skilled));
  }
}
