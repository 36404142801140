import React, { useReducer, useEffect } from 'react';

import PropTypes from 'prop-types';
import { siteRootLinks } from "./api";

// SiteRootContext or ApiContext a better name ?
const linksReducer = (state, action) => {
  switch (action.type) {
    case 'flagAsLoading':
      return { isLoading: true, links: null };
    case 'loaded':
      return { isLoading: false, links: action.payload.links, userType: action.payload.userType };
    default:
      throw new Error(`Unrecognised action ${action.type}`);
  }
};

const LinksContext = React.createContext();

export const Provider = ({ linksEndpointUrl, children }) => {
  const [{ userType, links }, linksDispatch] = useReducer(linksReducer, {});

  useEffect(() => {
    let cancel;
    linksDispatch({ type: 'flagAsLoading' });
    siteRootLinks(linksEndpointUrl).then((payload) => {
      if (!cancel) {
        linksDispatch({ type: 'loaded', payload });
      }
    });
    return () => {
      cancel = true;
    };
  }, [linksEndpointUrl]);

  // TODO: This 'data' prop should probably be called 'links'
  return (
    <LinksContext.Provider
      value={{
        data: links,
        links,
        userType,
        endpointUrl: linksEndpointUrl,
      }}
    >
      {children}
    </LinksContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  linksEndpointUrl: PropTypes.string.isRequired,
};

Provider.defaultProps = {
  children: [],
};

export default LinksContext;
