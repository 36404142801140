import React, { useContext } from 'react';
import {
  DashboardMain,
  Button,
  FixedHeaderBodyGlobal,
  FixedHeaderNav,
  DashboardGlobal,
  Container,
  UpgradeNotification,
} from './styled';


import LinksContext from '../LinksContext';
import Logo from '../logo.png';

export const DashboardHeaderNavUserDisplay = ({ navLinks }) => (
  <div className="navbar-item is-paddingless">
    <Button className="is-borderless" isSize="small" href={navLinks.logoutLink}>Log out</Button>
  </div>
);
export const DashboardHeaderNav = ({ additionalNavbarItems, widerLayout }) => {
  const { links } = useContext(LinksContext);
  const navLinks = {
    logoutLink: (links && links.logout && links.logout.href),
  };
  return (
    <header className="dashboard-header" data-version={2}>
      <FixedHeaderBodyGlobal />
      <UpgradeNotification widerLayout={widerLayout} />
      <FixedHeaderNav>
        <Container isWider={widerLayout}>
          <div className="navbar-brand">
            <a className="navbar-item is-paddingless" href="/">
              <img src={Logo} alt="Peeplcoach Logo" />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">
              {additionalNavbarItems && additionalNavbarItems()}
              <DashboardHeaderNavUserDisplay navLinks={navLinks} />
            </div>

          </div>
        </Container>
      </FixedHeaderNav>
    </header>
  );
};
// TODO: src/components/layouts for stuff like this?
const DashboardLayout = ({
  children,
  additionalNavbarItems,
  widerLayout,
}) => (
  <>
    <DashboardGlobal />
    <DashboardHeaderNav widerLayout={widerLayout} additionalNavbarItems={additionalNavbarItems} />
    <DashboardMain>
      {children}
    </DashboardMain>

  </>
);

DashboardLayout.defaults = {
  additionalNavbarItems: undefined,
  widerLayout: false,
};

export default DashboardLayout;
