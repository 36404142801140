import React from 'react';
import styled from 'styled-components';
import { Progress } from 'bloomer';
import { Card, CardContent } from '../../../styled';
import Expandable from './Expandable';
import { snakeCase } from 'lodash';

const RatingResultTitle = styled.div`
  font-weight: bold;
  margin-bottom: 1.2rem;
`;

const ValueRepresentation = styled.div`
  display: flex;
`;

const ExpandableContainer = styled.div`
  margin-top: 1em;
`;

const ValueFraction = styled.div`
  flex-grow: 1;
  font-weight: bold;
  color: #717071;
  font-size: 13px;
`;

const ValueBar = styled.div`
  flex-grow: 9;
  display: flex;
  align-items: center;
`;

const CustomColorProgress = styled(Progress)`
  height: 0.5rem !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;

  &&& {
    &::-moz-progress-bar {
      background-color: ${props => props.color};
    }
  }
`;

const SimpleResultField = styled.div.attrs({ className: "has-text-body-grey" })`
  margin-top: 1.5rem;
`;

function RatingResultDisplay({ questionText, value, maximumValue, color, text }) {
  return (
    <div>
      <RatingResultTitle data-testid={`${snakeCase(questionText)}-name`}>{questionText}</RatingResultTitle>
      <ValueRepresentation>
        <ValueFraction data-testid={`${snakeCase(questionText)}-value`}>{value} / {maximumValue}</ValueFraction>
        <ValueBar>
          <CustomColorProgress className="progress" color={color} value={value} max={maximumValue} />
        </ValueBar>
      </ValueRepresentation>
      <SimpleResultField>{ text }</SimpleResultField>
    </div>
  );
}

const ResultCard = ({ children, more }) => (
  <Card style={{ width: '100%' }}>
    <CardContent style={{ paddingBottom: '1rem' }}>
      { children }
      { more && <ExpandableContainer><Expandable><div className="has-text-body-grey">{ more }</div></Expandable></ExpandableContainer> }
    </CardContent>
  </Card>
);

function RatingResultField({ presenter, submissionData, question, display }) {
  if (!presenter) throw Error("You must supply a presenter to this RatingResultField");

  const presentationResponse = presenter.responseFor(submissionData);
  if (!presentationResponse) return null;

  const colorCode = `#${presenter.color()}`;
  const adviceText = question.advice ? question.advice.adviceForValue(presentationResponse.value) : "";
  const { card } = display;
  const fieldContents = <RatingResultDisplay {...presentationResponse} color={colorCode} text={adviceText} />;

  if (card) return <ResultCard more={display.more}>{ fieldContents }</ResultCard>;
  return fieldContents;
}

export default ({ presenter, submissionData, question, display = {} }) => {
  if (presenter.contentType() === "ratingQuestion") {
    return <RatingResultField presenter={presenter} submissionData={submissionData} question={question} display={display} />;
  }
  return <div />;
};
